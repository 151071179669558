.section-posts {
  padding-block: calc($spacer-xl * 1.5);
  @media screen and (min-width: 1200px) {
    padding-block: calc($spacer-xxl * 2);
  }
  .content {
    .grid {
      .posts {
        &__search {
          grid-column: 1 / -1;
          margin-bottom: $spacer;
          @media screen and (min-width: 1200px) {
            margin-bottom: $spacer-xl;
          }
          .search {
            position: relative;
            input {
              width: 100%;
              border: 0.063rem solid $gray;
              border-radius: $radius;
              padding: $spacer calc($spacer * 5) $spacer calc($spacer * 1.5);
              font-size: 18px;
              transition: $transition;
              @media screen and (min-width: 1200px) {
                font-size: 20px;
              }
              &:focus {
                border-color: $blue-light-hover;
                border-width: 0.063rem;
              }
              &:focus-visible {
                outline: none;
              }
              &::placeholder {
                color: $text;
                opacity: 0.4;
                font-size: 18px;
                @media screen and (min-width: 1200px) {
                  font-size: 24px;
                }
              }
            }
            button {
              position: absolute;
              top: 0.063rem;
              right: 0.063rem;
              height: calc(100% - 0.126rem);
              aspect-ratio: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              border: none;
              border-radius: 0 calc($radius * 0.85) calc($radius * 0.85) 0;
              background: $blue-light-hover;
              transition: $transition;
              svg {
                fill: $blue-darker;
              }
            }
          }
        }
        &__filters {
          grid-column: 1 / -1;
          margin-bottom: $spacer;
          @media screen and (min-width: 1200px) {
            grid-column: span 3;
            margin-bottom: 0;
          }
          &__inner {
            .accordion {
              &-item {
                background-color: $white;
                border: 0.063rem solid $gray;
                border-radius: $radius;
              }
              &-header {
                //@media screen and (min-width: 1200px) {
                //    display: none;
                //}
                &--title {
                  font-size: 18px;
                  font-weight: $fw-700;
                  &::after {
                    width: 1.875rem;
                    height: 1.875rem;
                  }
                }
              }
              &-content {
                @media screen and (min-width: 1200px) {
                  //height: auto!important;
                  padding: $spacer-xl;
                }
                &__inner {
                  padding: 0 $spacer $spacer;
                  @media screen and (min-width: 1200px) {
                    padding: 0;
                  }
                  .cat-content {
                    &:not(:last-child) {
                      margin-bottom: $spacer;
                    }
                    .cat-title {
                      font-size: 14px;
                      font-weight: $fw-700;
                      margin-bottom: $spacer;
                      @media screen and (min-width: 1200px) {
                        font-size: 18px;
                      }
                    }
                    @media screen and (min-width: 1200px) {
                      padding: $spacer-xl;
                      border-radius: $radius;
                    }
                  }
                }
              }
            }
            .form-check {
              &:not(:last-child) {
                margin-bottom: $spacer;
              }
            }
            .cat-content {
              &.tags {
                .wrap-taxonomy {
                  display: flex;
                  flex-wrap: wrap;
                  gap: calc($spacer * 0.5);
                }
              }
            }
          }
        }
        &__content {
          grid-column: 1 / -1;
          @media screen and (min-width: 1200px) {
            grid-column: 4 / span 9;
          }
          .list-posts {
            @media screen and (min-width: 1200px) {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: $spacer;
            }
            .post-item {
              grid-column: span 1;
              display: flex;
              flex-direction: column;
              margin-bottom: $spacer;
              &:last-child {
                margin-bottom: 0;
              }
              @media screen and (min-width: 1200px) {
                margin-bottom: 0;
              }
              .thumb {
                position: relative;
                a {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  z-index: 1;
                }
              }
            }
            .highlight-post {
              margin-bottom: $spacer;
              @media screen and (min-width: 1200px) {
                grid-column: 1 / -1;
                margin-bottom: 0;
              }
              .post-item {
                @media screen and (min-width: 1200px) {
                  display: grid;
                  grid-template-columns: repeat(9, 1fr);
                }
                .thumb {
                  position: relative;
                  @media screen and (min-width: 1200px) {
                    grid-column: 1 / span 7;
                  }
                  a {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                  }
                }
                &__content {
                  @media screen and (min-width: 1200px) {
                    grid-column: 5 / span 5;
                  }
                  &--target {
                    @media screen and (min-width: 1200px) {
                      right: auto;
                      left: 0;
                      transform: translate(-50%, -50%);
                    }
                  }
                  &__inner {
                    @media screen and (min-width: 1200px) {
                      border-radius: 0 $radius $radius $radius;
                      padding: $spacer-xl;
                    }
                  }
                }
              }
            }

            .loader {
              grid-column: 1 / -1;
              &-container {
                display: flex;
                justify-content: center;
                margin-top: $spacer-xl;
              }
              &-spinner {
                width: 48px;
                height: 48px;
                border-radius: 50%;
                display: inline-block;
                position: relative;
                border: 3px solid;
                border-color: $blue-darker $blue-darker transparent transparent;
                box-sizing: border-box;
                animation: rotation 1s linear infinite;
                &::after,
                &::before {
                  content: "";
                  box-sizing: border-box;
                  position: absolute;
                  left: 0;
                  right: 0;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                  border: 3px solid;
                  border-color: transparent transparent $red $red;
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  animation: rotationBack 0.5s linear infinite;
                  transform-origin: center center;
                }
                &::before {
                  width: 32px;
                  height: 32px;
                  border-color: $blue-darker $blue-darker transparent
                    transparent;
                  animation: rotation 1.5s linear infinite;
                }
              }
            }
          }
        }
      }
    }
  }

  &.press-posts {
    .content {
      .grid {
        .posts {
          &__filters {
            &__inner {
              .accordion {
                &-header {
                  @media screen and (min-width: 1200px) {
                    display: none;
                  }
                  &--title {
                    font-size: 18px;
                    font-weight: $fw-700;
                    &::after {
                      width: 1.875rem;
                      height: 1.875rem;
                    }
                  }
                }
                &-content {
                  @media screen and (min-width: 1200px) {
                    height: auto !important;
                    padding: $spacer-xl;
                  }
                  &__inner {
                    padding: 0 $spacer $spacer;
                    @media screen and (min-width: 1200px) {
                      padding: 0;
                    }
                    .cat-content {
                      &:not(:last-child) {
                        margin-bottom: $spacer;
                      }
                      .cat-title {
                        font-size: 14px;
                        font-weight: $fw-700;
                        margin-bottom: $spacer;
                        @media screen and (min-width: 1200px) {
                          font-size: 18px;
                        }
                      }
                      @media screen and (min-width: 1200px) {
                        padding: $spacer-xl;
                        border-radius: $radius;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.lab-posts {
    background-color: $blue-darker;
    .content {
      .grid {
        .posts {
          &__search {
            .search {
              input {
                background-color: $text-hover;
                color: $white;
                border-color: $text-hover;
                &:focus {
                  border-color: $gw-light-blue;
                }
              }
              button {
                background-color: $gw-light-blue;
                svg {
                  fill: $white;
                }
              }
            }
          }
          &__filters {
            &__inner {
              .accordion {
                &-item {
                  background-color: $text-hover;
                  border: none;
                  @media screen and (min-width: 1200px) {
                    background-color: transparent;
                    border-radius: 0;
                  }
                }
                &-header {
                  @media screen and (min-width: 1200px) {
                    display: none;
                  }
                  &--title {
                    color: $white;
                    @media screen and (min-width: 1200px) {
                      display: none;
                    }
                    &::after {
                      background-image: url("../img/icons/icon-accordion-white.svg");
                    }
                  }
                }
                &-content {
                  @media screen and (min-width: 1200px) {
                    height: auto !important;
                    padding: 0;
                  }
                  .cat-content {
                    &:not(:last-child) {
                      margin-bottom: $spacer;
                    }
                    .cat-title {
                      font-size: 14px;
                      font-weight: $fw-700;
                      color: $white;
                      margin-bottom: $spacer;
                      @media screen and (min-width: 1200px) {
                        font-size: 18px;
                      }
                    }
                    @media screen and (min-width: 1200px) {
                      padding: $spacer-xl;
                      background-color: $text-hover;
                      border-radius: $radius;
                    }
                  }
                }
              }
            }
          }
          &__content {
            .list-posts {
              .loader {
                &-spinner {
                  border-color: $white $white transparent transparent;
                  &::before {
                    border-color: $white $white transparent transparent;
                  }
                }
              }
            }
            .tool-pagination {
              .prev,
              .next {
                svg {
                  .circle {
                    stroke: $white;
                  }
                  .arrow {
                    fill: $white;
                  }
                }
                &:hover {
                  svg {
                    .circle {
                      fill: none;
                      stroke: $gw-light-blue;
                    }
                    .arrow {
                      fill: $gw-light-blue;
                    }
                  }
                }
                &.disabled {
                  opacity: 0.5;
                  pointer-events: none;
                }
              }
              .pages {
                .current {
                  color: $gw-light-blue;
                }
                a {
                  color: $gray-hover;
                  &:hover {
                    color: $gw-light-blue;
                  }
                }
              }
            }
          }
        }
        .post-item {
          &.featured-post {
            @media screen and (min-width: 1200px) {
              overflow: visible;
              gap: $spacer;
              align-items: center;
            }
            .thumb {
              grid-row: 1/-1;
              border-radius: $radius;
              overflow: hidden;
              aspect-ratio: 16/11;
              z-index: 10;
            }
            .post-item__content {
              display: block;
              padding: 0;
              background-color: transparent;
              height: auto;
              @media screen and (min-width: 1200px) {
                grid-row: 1/-1;
                position: relative;
                z-index: 20;
              }
              &--target {
                @media screen and (min-width: 1200px) {
                  display: block;
                  position: absolute;
                  top: 0;
                  right: auto;
                  left: 0;
                  transform: translate(-50%, -50%);
                  aspect-ratio: 1;
                  width: 80%;
                  background-image: url("../img/decos/deco-post-item-featured-target-white.svg");
                  background-size: contain;
                  background-repeat: no-repeat;
                  z-index: 10;
                }
              }
              &__inner {
                padding: calc($spacer * 1.5);
                background-color: $text-hover;
                border: 0.063rem solid $text;
                position: relative;
                z-index: 20;
                @media screen and (min-width: 1200px) {
                  padding: $spacer-xl;
                }
              }
              &--header {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: calc($spacer * 1.5);
                margin-bottom: $spacer;
                .cat {
                  color: $white;
                }
              }
              &--inner {
                display: flex;
                flex-direction: column;
                height: 100%;
                .title {
                  color: $white;
                }
                .excerpt {
                  color: $white;
                }
                .action {
                  margin-top: auto;
                  .link {
                    color: $white;
                    &-deco {
                      &::before {
                        background-color: $white;
                      }
                      &::after {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath d='M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z' fill='rgba(255,255,255,1)'%3E%3C/path%3E%3C/svg%3E");
                      }
                    }
                  }
                }
              }
            }
          }
          &:not(.featured-post) {
            display: flex;
            flex-direction: column;
            border-color: $text;
            .thumb {
              display: none;
              @media screen and (min-width: 1200px) {
                display: block;
                aspect-ratio: 16/10;
              }
              img {
                @media screen and (min-width: 1200px) {
                  aspect-ratio: 16/10;
                }
              }
            }
            .post-item {
              &__content {
                background-color: $text-hover;
                display: flex;
                flex-direction: column;
                &__inner {
                  display: flex;
                  flex-direction: column;
                  height: 100%;
                  background-color: $text-hover;
                  border: 0.063rem solid $text;
                }
                &--header {
                  margin-bottom: $spacer;
                  display: flex;
                  flex-direction: column-reverse;
                  justify-content: flex-start;
                  align-items: flex-start;
                  gap: calc($spacer * 0.5);
                  .cat {
                    color: $white;
                  }
                  .tag {
                    width: max-content;
                    font-size: 14px;
                    padding: calc($spacer * 0.25) calc($spacer * 0.75);
                    border: 0.125rem solid $gw-light-blue;
                    border-radius: $radius;
                    color: $gw-light-blue;
                  }
                }
                &--inner {
                  display: flex;
                  flex-direction: column;
                  height: 100%;
                  .title {
                    color: $white;
                  }
                  .excerpt {
                    color: $white;
                  }
                  .action {
                    margin-top: auto;
                    .link {
                      color: $white;
                      &-deco {
                        &::before {
                          background-color: $white;
                        }
                        &::after {
                          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath d='M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z' fill='rgba(255,255,255,1)'%3E%3C/path%3E%3C/svg%3E");
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .form-check {
      &-label {
        color: $white;
        &::before {
          border-color: $white;
          background-color: $text-hover;
        }
        &::after {
          background-color: $white;
        }
      }
    }
  }

  &.ressource-posts {
    .content {
      .grid {
        .posts {
          &__filters {
            &__inner {
              position: initial;
              .accordion {
                &-item {
                  @media screen and (min-width: 1200px) {
                    border: none;
                    background-color: transparent;
                    border-radius: 0;
                  }
                }
                &-header {
                  @media screen and (min-width: 1200px) {
                    display: none;
                  }
                  &--title {
                    @media screen and (min-width: 1200px) {
                      display: none;
                    }
                  }
                }
                &-content {
                  @media screen and (min-width: 1200px) {
                    height: auto !important;
                    padding: 0;
                  }
                  .cat-content {
                    &:not(:last-child) {
                      margin-bottom: $spacer;
                    }
                    @media screen and (min-width: 1200px) {
                      padding: $spacer-xl;
                      border-radius: $radius;
                      background-color: $white;
                      border: 0.063rem solid $gray;
                    }
                    .cat-title {
                      font-size: 14px;
                      font-weight: $fw-700;
                      margin-bottom: $spacer;
                      @media screen and (min-width: 1200px) {
                        font-size: 18px;
                      }
                    }
                    &.tags {
                      .btn-check {
                        &-input {
                          + label {
                            border-color: $blue;
                            color: $blue;
                            font-size: 16px;
                            &:hover {
                              background-color: $blue;
                              color: $white;
                            }
                          }
                          &:checked {
                            + label {
                              background-color: $blue;
                              color: $white;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .post-item {
          &.featured-post {
            .post-item__content {
            }
          }
          &__content {
            &--header {
              display: flex;
              flex-direction: column-reverse;
              justify-content: flex-start;
              align-items: flex-start;
              gap: calc($spacer * 0.5);

              .tags-list {
                display: flex;
                align-items: center;
                gap: calc($spacer * 0.25);
                flex-wrap: wrap;
                margin-bottom: 0.5rem;
              }
              .tag {
                width: max-content;
                font-size: 14px;
                padding: calc($spacer * 0.25) calc($spacer * 0.75);
                border: 0.125rem solid $blue;
                border-radius: $radius;
                color: $blue;
              }
            }
          }
        }
      }
    }
  }

  &.use-case-posts {
    .content {
      .grid {
        .posts {
          &__filters {
            &__inner {
              position: initial;
              .accordion {
                &-item {
                  @media screen and (min-width: 1200px) {
                    border: none;
                    background-color: transparent;
                    border-radius: 0;
                  }
                }
                &-header {
                  @media screen and (min-width: 1200px) {
                    display: none;
                  }
                  &--title {
                    @media screen and (min-width: 1200px) {
                      display: none;
                    }
                  }
                }
                &-content {
                  @media screen and (min-width: 1200px) {
                    height: auto !important;
                    padding: 0;
                  }
                  .cat-content {
                    &:not(:last-child) {
                      margin-bottom: $spacer;
                    }
                    @media screen and (min-width: 1200px) {
                      padding: $spacer-xl;
                      border-radius: $radius;
                      background-color: $white;
                      border: 0.063rem solid $gray;
                    }
                    .cat-title {
                      font-size: 14px;
                      font-weight: $fw-700;
                      margin-bottom: $spacer;
                      @media screen and (min-width: 1200px) {
                        font-size: 18px;
                      }
                    }
                    &.tags {
                      .btn-check {
                        &-input {
                          + label {
                            border-color: $blue;
                            color: $blue;
                            font-size: 16px;
                            &:hover {
                              background-color: $blue;
                              color: $white;
                            }
                          }
                          &:checked {
                            + label {
                              background-color: $blue;
                              color: $white;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .post-item {
          &.featured-post {
            .post-item__content {
            }
          }
          &__content {
            &--header {
              display: flex;
              flex-direction: column-reverse;
              justify-content: flex-start;
              align-items: flex-start;
              gap: calc($spacer * 0.5);

              .tags-list {
                display: flex;
                align-items: center;
                gap: calc($spacer * 0.25);
                flex-wrap: wrap;
              }
              .tag {
                width: max-content;
                font-size: 14px;
                padding: calc($spacer * 0.25) calc($spacer * 0.75);
                border: 0.125rem solid $blue;
                border-radius: $radius;
                color: $blue;
              }
            }
          }
        }
      }
    }
  }

  &.partner-posts {
    background-color: $blue-light;
    .content {
      .grid {
        .posts {
          &__filters {
            &__inner {
              @media screen and (min-width: 1200px) {
                position: sticky;
                top: calc($spacer-xxl * 1.75);
              }
              .accordion {
                &-item {
                  &:not(:last-child) {
                    margin-bottom: $spacer;
                  }
                }
                &-header {
                }
                &-content {
                  @media screen and (min-width: 1200px) {
                    padding: 0;
                  }
                  &__inner {
                    @media screen and (min-width: 1200px) {
                      padding: 0 $spacer-xl $spacer-xl;
                    }
                  }
                }
              }
              .form-check {
                &:not(:last-child) {
                  margin-bottom: $spacer;
                }
              }
              .cat-content {
                &.tags {
                  .wrap-taxonomy {
                    display: flex;
                    flex-wrap: wrap;
                    gap: calc($spacer * 0.5);
                  }
                }
              }
            }
          }
          &__content {
            .list-posts {
              @media screen and (min-width: 1200px) {
                grid-template-columns: repeat(3, 1fr);
              }
              .post-item {
                &.partner {
                  border-radius: $radius;
                  border: 0.063rem solid $gray;
                  .thumb {
                    background-color: $light;
                    aspect-ratio: initial;
                    position: relative;
                    img {
                      aspect-ratio: 16/9;
                      object-fit: contain;
                      width: 80%;
                      margin: auto;
                      mix-blend-mode: multiply;
                    }
                    a {
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      z-index: 10;
                    }
                  }
                  .post-item {
                    &__content {
                      background-color: $white;
                      display: flex;
                      flex-direction: column;
                      gap: calc($spacer * 0.5);
                      .title {
                        font-size: 18px;
                        font-weight: $fw-700;
                      }
                      .action {
                        margin-top: auto;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.alliances-posts {
    .content {
      .grid {
        .posts {
          &__content {
            grid-column: 1 / -1;
            .list-posts {
              @media screen and (min-width: 768px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                column-gap: $spacer;
              }
              @media screen and (min-width: 1200px) {
                grid-template-columns: repeat(4, 1fr);
              }
              .post-item {
                &.partner {
                  border-radius: $radius;
                  border: 0.063rem solid $gray;
                  .thumb {
                    background-color: $light;
                    aspect-ratio: initial;
                    img {
                      aspect-ratio: 16/9;
                      object-fit: contain;
                      width: 80%;
                      margin: auto;
                      mix-blend-mode: multiply;
                    }
                  }
                  .post-item {
                    &__content {
                      background-color: $white;
                      display: flex;
                      flex-direction: column;
                      gap: calc($spacer * 0.5);
                      .title {
                        font-size: 18px;
                        font-weight: $fw-700;
                      }
                      .action {
                        margin-top: auto;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.information-posts {
    .posts {
      &__filters {
        &__inner {
          @media screen and (min-width: 1200px) {
            position: sticky;
            top: calc($spacer-xxl * 1.75);
          }
          .accordion {
            &-item {
              &:not(:last-child) {
                margin-bottom: $spacer;
              }
            }
            &-header {
            }
            &-content {
              @media screen and (min-width: 1200px) {
                padding: 0 !important;
              }
              &__inner {
                @media screen and (min-width: 1200px) {
                  padding: 0 $spacer-xl $spacer-xl !important;
                }
              }
            }
          }
          .form-check {
            &:not(:last-child) {
              margin-bottom: $spacer;
            }
          }
          .cat-content {
            &.tags {
              .wrap-taxonomy {
                display: flex;
                flex-wrap: wrap;
                gap: calc($spacer * 0.5);
              }
            }
          }
        }
      }
      &__content {
        &__inner {
          .list-posts {
            .post-item {
              &.information {
                .post-item {
                  &__content {
                    &--header {
                      justify-content: start;
                      gap: calc($spacer * 0.5);
                      margin-bottom: calc($spacer * 0.5);
                      .cat {
                      }
                    }
                    .title {
                      font-size: 18px;
                      font-weight: $fw-700;
                      margin-bottom: $spacer;
                    }
                    .excerpt {
                      font-size: 14px;
                      font-weight: $fw-400;
                      line-height: 1.3;
                      margin-block: $spacer;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
