.uses-block {
    padding-block: calc($spacer-xl * 1.5);
    background-color: $white;
    position: relative;
    @media screen and (min-width: 1200px) {
        padding-block: calc($spacer-xxl * 2);
    }
    .bg-container {
        position: absolute;
        top: 45%;
        left: 0;
        width: 100%;
        height: 55%;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    .content {
        position: relative;
        z-index: 10;
        .grid {
            @media screen and (min-width: 1200px) {
                grid-auto-flow: column;
            }
            &__title {
                grid-column: 1 / -1;
                .title {
                    font-size: 20px;
                    font-weight: 400;
                    text-transform: uppercase;
                    position: relative;
                    margin-block: 0 calc($spacer * 1.5);
                    @media screen and (min-width: 1200px) {
                        font-size: 40px;
                    }
                    &::after {
                        content: "_";
                        display: inline-block;
                        color: $red;
                    }
                }
            }
            &__item {
                grid-column: 1 / -1;
                padding: $spacer;
                border-radius: $radius;
                border: .063rem solid $gray;
                background-color: $white;
                @media screen and (min-width: 576px) {
                    padding: calc($spacer * 1.5);
                }
                @media screen and (min-width: 1200px) {
                    padding: calc($spacer-xl * 1.25);
                    &:not(:last-child) {
                        grid-column: span 8;
                    }
                    &:last-child {
                        grid-column: 9 / span 4;
                        grid-row: span 2;
                        @media screen and (min-width: 1200px) {
                            background-color: $light;
                        }
                    }
                }
                .icon {
                    width: 35px;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: calc($spacer * 1.5);
                    img {
                        object-fit: contain;
                        width: 100%;
                        height: 100%;
                    }
                }
                .item {
                    &--title {
                        margin-block: 0 $spacer-xl;
                        font-size: 14px;
                        font-weight: $fw-700;
                        position: relative;
                        @media screen and (min-width: 1200px) {
                            font-size: 18px;
                        }
                        &::after {
                            content: '_';
                            display: inline-block;
                            color: $red;
                        }
                    }
                }
                .editor {
                    margin-top: $spacer-xl;
                }
                .action {
                    margin-top: $spacer-xl;
                    .btn {
                        max-width: 100%;
                        @media screen and (min-width: 768px) {
                            max-width: 50%;
                        }
                        @media screen and (min-width: 1200px) {
                            max-width: max-content;
                        }
                    }
                }
                &.multi-links {
                    position: relative;
                    .grid__item {
                        &--content {
                            @media screen and (min-width: 1200px) {
                                max-width: 75%;
                            }
                        }
                    }
                    .action {
                        .btn-bordered {
                            .btn-icon {
                                display: none;
                                @media screen and (min-width: 1200px) {
                                    display: block;
                                }
                            }
                        }
                        + .action {
                            margin-top: $spacer;
                        }
                        &:last-child {
                            @media screen and (min-width: 1200px) {
                                margin-top: 0;
                                position: absolute;
                                top: -.063rem;
                                right: -.063rem;
                                width: 25%;
                                height: calc(100% + .125rem);
                                background-color: $blue-light-secondary;
                                border-radius: 0 $radius $radius 0;
                            }
                            .btn-bordered {
                                @media screen and (min-width: 1200px) {
                                    border: none;
                                    border-radius: 0 $radius $radius 0;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    max-width: 100%;
                                    height: 100%;
                                    flex-direction: column;
                                    align-items: flex-start;
                                    justify-content: flex-end;
                                    font-weight: $fw-700;
                                }
                                .btn-icon {
                                    @media screen and (min-width: 1200px) {
                                        transform: rotate(-90deg);
                                    }
                                    svg {
                                        path {
                                            transition: $transition;
                                        }
                                    }
                                }
                                &:hover {
                                    .btn-icon {
                                        svg {
                                            path {
                                                fill: $white;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}