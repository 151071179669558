/* === SECTION-CTA-DEMO === */
.section {
    &--cta-demo {
        padding-block: calc($spacer-xl * 1.5);

        position: relative;
        @media screen and (min-width: 1200px) {
            padding-block: calc($spacer-xxl * 2);
        }
        &.gray-bg {
            background-color: $light;
        }
        &.white-bg {
            background-color: $white;
        }
        &.blue_light-bg {
            background-color: $blue-light;
        }
        &.blue_dark-bg {
            background-color: $blue-darker;
        }

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 50%;
            background-color: $blue-darker;
        }
        .content {
            position: relative;
            z-index: 10;
            .grid {
                position: relative;
                .background {
                    display: none;
                    @media screen and (min-width: 1200px) {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: .5rem;
                        width: calc(100% - calc($spacer-xl * 3));
                        height: 100%;
                        border-radius: $radius;
                        &.blue {
                            background-color: $gw-light-blue;
                        }
                        &.red {
                            background-color: $red;
                        }
                    }

                }
                .inner-content {
                    grid-column: span 4;
                    padding: $spacer;
                    border-radius: $radius;
                    position: relative;
                    z-index: 10;
                    @media screen and (min-width: 576px) {
                        grid-column: 2 / span 8;
                        padding: $spacer-xxl;
                    }
                    @media screen and (min-width: 768px) {
                        grid-column: 3 / span 8;
                    }
                    @media screen and (min-width: 1200px) {
                        grid-column: 1 / span 7;
                        grid-row: 1 / -1;
                        padding: $spacer-xxl 0 $spacer-xxl $spacer-xxl;
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                        align-self: center;
                    }
                    .icon {
                        display: none;
                        @media screen and (min-width: 1200px) {
                            display: block;
                            width: 60px;
                            height: 60px;
                            margin-bottom: $spacer;
                        }
                        img {
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .title {
                        font-size: 30px;
                        font-weight: $fw-400;
                        text-transform: uppercase;
                        margin-block: 0 $spacer;
                        position: relative;
                        @media screen and (min-width: 1200px) {
                            font-size: 40px;
                        }
                        &::after {
                            content: '_';
                            display: inline-block;
                            color: $red;
                        }
                    }
                    .editor {
                        margin-block: $spacer;
                        @media screen and (min-width: 1200px) {
                            max-width: calc(50% + $spacer);
                        }
                    }
                    .action {
                        margin-top: calc($spacer-xl * 1.25);
                        display: flex;
                        flex-direction: column;
                        gap: $spacer;
                        @media screen and (min-width: 1200px) {
                            flex-direction: row;
                        }
                        .btn {
                            max-width: 100%;
                            @media screen and (min-width: 1200px) {
                                max-width: max-content;
                            }
                        }
                    }
                    &.bg {
                        &-blue {
                            background-color: $gw-light-blue;
                            @media screen and (min-width: 1200px) {
                                background-color: transparent;
                            }
                        }
                        &-red {
                            background-color: $red;
                            color: $white;
                            @media screen and (min-width: 1200px) {
                                background-color: transparent;
                            }
                            .title {
                                &::after {
                                    color: $white;
                                }
                            }
                        }
                    }
                }
                .deco {
                    display: none;
                    @media screen and (min-width: 1200px) {
                        display: block;
                        grid-column: 5 / span 8;
                        grid-row: 1 / -1;
                        position: relative;
                        margin-left: calc($spacer * -1.125);
                        border-radius: 0 $radius $radius 0;
                    }
                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        border-radius: 0 $radius $radius 0;
                    }
                }
            }
        }
    }
}