.full-width-editor {
  padding-block: calc($spacer-xl * 1.5);
  @media screen and (min-width: 1200px) {
    padding-block: calc($spacer-xxl * 2);
  }
  &.blue-dark-bg {
    background-color: $text;
    color: white;
  }
  &.blue-gray-bg {
    background-color: $blue-gray;
    color: white;
  }
  .grid {
    &--content {
      grid-column: 1 / -1;
      .title {
        font-size: 30px;
        font-weight: $fw-400;
        text-transform: uppercase;
        margin-block: 0 calc($spacer-xl * 1.25);
        position: relative;
        @media screen and (min-width: 1200px) {
          font-size: 40px;
          margin-bottom: calc($spacer-xl * 1.375);
        }
        &::after {
          content: "_";
          display: inline-block;
          color: $red;
        }
      }
    }
  }
}
