.our-values {
  padding-block: calc($spacer-xl * 1.5);
  background-color: $white;
  @media screen and (min-width: 1200px) {
    padding-block: calc($spacer-xxl * 2);
  }
  .content {
    .grid {
      row-gap: calc($spacer * 1.5);
      @media screen and (min-width: 1200px) {
        row-gap: $spacer-xl;
      }
      &__title {
        grid-column: 1 / -1;
        .title {
          font-size: 20px;
          font-weight: $fw-400;
          text-transform: uppercase;
          margin-block: 0;
          position: relative;
          @media screen and (min-width: 1200px) {
            font-size: 40px;
          }
          &::after {
            content: "_";
            display: inline-block;
            color: $red;
          }
        }
      }
      &__intro {
        grid-column: 1 / -1;
        .editor {
          font-size: 14px;
          @media screen and (min-width: 1200px) {
            font-size: 18px;
          }
        }
      }
      &__informations {
        grid-column: 1 / -1;
        .item {
          padding-inline: 0;
          position: relative;
          &:not(:last-child) {
            margin-bottom: 45px;
            &::after {
              content: "";
              display: block;
              width: 100%;
              height: 1px;
              background-color: $blue-darker;
              position: absolute;
              bottom: -21px;
              left: 0;

              @media screen and (min-width: 768px) {
                content: none;
              }
            }
          }
          &-title {
            font-size: 14px;
            font-weight: 700;
            margin-block: 0 $spacer;
            text-transform: uppercase;
            @media screen and (min-width: 1200px) {
              font-size: 20px;
            }
          }
          .editor {
            font-size: 14px;
            @media screen and (min-width: 1200px) {
              font-size: 16px;
            }
          }
        }
      }
      .action {
        grid-column: 1 / -1;
        @media screen and (min-width: 576px) {
          display: flex;
          justify-content: center;
        }
        .btn {
          max-width: 100%;
          @media screen and (min-width: 576px) {
            max-width: max-content;
          }
        }
      }
    }
  }
}
