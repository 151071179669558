.gallery {
    padding-block: calc($spacer-xl * 1.5);
    @media screen and (min-width: 1200px) {
        padding-block: calc($spacer-xxl * 2);
    }
    &__content {
        .swiper {
            padding-inline: calc($spacer * 1.5);
            @media screen and (min-width: 1200px) {
                padding-inline: calc($spacer-xl * 2)
            }
            &-slide {
                aspect-ratio: 16/8;
                @media screen and (min-width: 1200px) {
                    width: 75%;
                }
                img {
                    object-fit: cover;
                    width: 100%;
                    aspect-ratio: 16/8;
                }
            }
            &__navigation {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: $spacer-xl;
                margin-top: $spacer-xl;
                @media screen and (min-width: 768px) {
                    justify-content: center;
                }
            }
            &-pagination {
                position: initial;
                transform: none;
                width: max-content;
                &-bullet {
                    background-color: $gray-hover;
                    opacity: 1;
                    &-active {
                        background-color: $blue;
                    }
                }
            }
            &-navigation {
                display: flex;
                align-items: center;
            }
            &-prev,
            &-next {
                position: initial;
                width: 30px;
                height: 30px;
                cursor: pointer;
                svg {
                    .circle {
                        fill: none;
                        stroke: $text;
                        transition: $transition;
                    }
                    .arrow {
                        fill: $text;
                        transition: $transition;
                    }
                }
                &:hover {
                    svg {
                        .circle {
                            fill: none;
                            stroke: $blue;
                        }
                        .arrow {
                            fill: $blue;
                        }
                    }
                }
            }
        }
    }
}