.double-colonne-image-texte-list-block {
  padding-block: calc($spacer-xl * 1.5);
  @media screen and (min-width: 1200px) {
    padding-block: calc($spacer-xxl * 2);
  }

  .bloc-img {
    display: none;

    @media screen and (min-width: 1000px) {
      display: grid;
      place-items: center;
    }
  }

  .grid {
    &__title {
      grid-column: 1 / -1;
      .title {
        font-size: 30px;
        font-weight: $fw-400;
        text-transform: uppercase;
        margin-block: 0 calc($spacer-xl * 1.25);
        position: relative;
        @media screen and (min-width: 1200px) {
          font-size: 40px;
          margin-bottom: calc($spacer-xl * 1.375);
        }
        &::after {
          content: "_";
          display: inline-block;
          color: $red;
        }
      }
    }
  }
}
