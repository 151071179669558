.our-team {
    padding-block: calc($spacer-xl * 1.5);
    background-color: $white;
    @media screen and (min-width: 1200px) {
        padding-block: calc($spacer-xxl * 2);
    }
    .content {
        .grid {
            @media screen and (min-width: 1200px) {
                row-gap: $spacer-xl;
            }
            &__title {
                grid-column: 1 / -1;
                .title {
                    font-size: 20px;
                    font-weight: $fw-400;
                    text-transform: uppercase;
                    margin-block: 0;
                    position: relative;
                    @media screen and (min-width: 1200px) {
                        font-size: 40px;
                    }
                    &::after {
                        content: '_';
                        display: inline-block;
                        color: $red;
                    }
                }
            }
            &__content {
                grid-column: 1 / -1;
                display: grid;
                gap: $spacer;
                @media screen and (min-width: 768px) {
                    grid-template-columns: repeat(2, 1fr);
                }
                @media screen and (min-width: 1200px) {
                    grid-template-columns: repeat(4, 1fr);
                    row-gap: $spacer-xl;
                }
                .subtitle {
                    grid-column: 1 / -1;
                    font-size: 18px;
                    font-weight: $fw-700;
                }
                .item {
                    grid-column: 1 / -1;
                    border-radius: $radius;
                    border: .063rem solid $gray;
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;
                    @media screen and (min-width: 768px) {
                        grid-column: span 1;
                    }
                    .thumb {
                        display: none;
                        position: relative;
                        @media screen and (min-width: 768px) {
                            display: block;
                        }
                        img {
                            aspect-ratio: .9;
                            object-fit: cover;
                            object-position: top;
                            width: 100%;
                            height: 100%;
                        }
                        .linkedin-link {
                            position: absolute;
                            top: $spacer;
                            right: $spacer;
                            width: 35px;
                            height: 35px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            svg {
                                fill: $text;
                                transition: $transition;
                            }
                            &:hover {
                                svg {
                                    fill: $text-hover;
                                }
                            }
                        }
                    }
                    .content {
                        padding: $spacer;
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        @media screen and (min-width: 1200px) {
                            padding: calc($spacer * 1.5);
                        }
                        .name {
                            font-size: 18px;
                            font-weight: $fw-700;
                            text-transform: uppercase;
                            margin-bottom: $spacer;
                            @media screen and (min-width: 1200px) {
                                text-transform: none;
                            }
                        }
                        .job {
                            font-size: 14px;
                        }
                        .action {
                            margin-top: auto;
                            .link {
                                margin-top: $spacer;
                            }
                        }
                    }
                }
                &.founders {
                    .item {
                        @media screen and (min-width: 768px) {
                            grid-column: span 2;
                        }
                        .thumb {
                            display: block;
                            position: relative;
                            img {
                                @media screen and (min-width: 768px) {
                                    aspect-ratio: 4/3;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}