/* === SECTION-CERTIFICATIONS === */
.section {
  &--certifications {
    padding-block: calc($spacer-xl * 1.5) 0;
    position: relative;
    @media screen and (min-width: 1200px) {
      padding-block: $spacer-xxl calc($spacer-xxl * 2);
    }
    &::after {
      content: "";
      position: absolute;
      top: calc($spacer-xl * -1.5);
      left: 0;
      width: 100%;
      height: calc(100% + calc($spacer-xl * 3));
      background-color: $white;
      z-index: -1;
      @media screen and (min-width: 1200px) {
        top: calc($spacer-xxl * -2);
        height: calc(100% + calc($spacer-xxl * 4));
      }
    }
    .content {
      .title {
        grid-column: 1 / -1;
        font-size: 30px;
        font-weight: $fw-400;
        text-transform: uppercase;
        margin-bottom: calc($spacer-xl * 1.25);
        position: relative;
        @media screen and (min-width: 1200px) {
          font-size: 40px;
          margin-bottom: calc($spacer-xl * 1.375);
        }
        &::after {
          content: "_";
          display: inline-block;
          color: $red;
        }
      }
      .certification-item {
        position: relative;
        grid-column: 1 / -1;
        @media screen and (min-width: 576px) {
          grid-column: 3 / span 6;
          padding: $spacer-xl $spacer-xl 0;
        }
        @media screen and (min-width: 768px) {
          padding: calc($spacer-xl * 1.5) calc($spacer-xl * 1.5) 0;
          grid-column: span 6;
        }

        &:not(:last-child) {
          @media screen and (min-width: 576px) {
            margin-bottom: $spacer-xl;
          }
          @media screen and (min-width: 1200px) {
            margin-bottom: 0;
          }
          &::after {
            content: "";
            display: block;
            width: 100%;
            height: 0.063rem;
            margin-block: calc($spacer-xl * 1.125);
            background-color: $blue-darker;
            @media screen and (min-width: 576px) {
              content: "";
              position: absolute;
              top: $spacer;
              left: 0;
              width: 100%;
              height: 0.063rem;
              margin-block: 0 !important;
              background-color: $blue-darker;
            }
          }
        }
        &:last-child {
          &::after {
            @media screen and (min-width: 576px) {
              content: "";
              position: absolute;
              top: $spacer;
              left: 0;
              width: 100%;
              height: 0.063rem;
              margin-block: 0 !important;
              background-color: $blue-darker;
            }
          }
        }
        &::before {
          @media screen and (min-width: 576px) {
            content: "";
            position: absolute;
            top: 0;
            left: $spacer;
            height: 100%;
            width: 0.063rem;
            margin-block: 0 !important;
            background-color: $blue-darker;
          }
        }
        .logo {
          margin-bottom: $spacer-xl;
          img {
            object-fit: contain;
            max-width: 150px;
            @media screen and (min-width: 1200px) {
              width: auto;
              max-height: 65px;
            }
          }
        }
        .item-title {
          font-size: 20px;
          font-weight: $fw-400;
          text-transform: uppercase;
          margin-top: $spacer-xl;
        }
      }
    }

    &.count-2 .certification-item {
      @media screen and (min-width: 768px) {
        grid-column: span 6;
        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }

    &.count-3 .certification-item {
      @media screen and (min-width: 1000px) {
        grid-column: span 4;
        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }

    &.count-4 .certification-item {
      @media screen and (min-width: 1200px) {
        grid-column: span 3;
        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }
  }
}
