.svg-contain {
  svg {
    width: 100% !important;
    height: 100% !important;
  }
}

.iframe-contain {
  iframe {
    @apply w-full h-full;
  }
}

button.plyr__control--overlaid {
  background-color: $blue;
}
