.highlight-block {
  padding-block: calc($spacer-xl * 1.5);
  @media screen and (min-width: 1200px) {
    padding-block: calc($spacer-xxl * 2);
  }
  .content {
    .grid {
      row-gap: calc($spacer-xl * 1.25);
      &__title {
        grid-column: 1 / -1;
        @media screen and (min-width: 576px) {
          grid-column: 2 / span 8;
        }
        @media screen and (min-width: 768px) {
          grid-column: 1 / -1;
        }
        .title {
          font-size: 20px;
          font-weight: 400;
          text-transform: uppercase;
          position: relative;
          margin-block: 0;
          @media screen and (min-width: 1200px) {
            font-size: 40px;
          }
          &::after {
            content: "_";
            display: inline-block;
            color: $red;
          }
        }
      }
      &__item {
        /* grid-column: span 4; */
        position: relative;
        /*  @media screen and (min-width: 576px) {
          grid-column: 2 / span 8;
        }
        @media screen and (min-width: 768px) {
          grid-column: span 6;
        }
        @media screen and (min-width: 1200px) {
          grid-column: span 4;
        } */
        &:not(:last-child) {
          &::after {
            content: "";
            display: block;
            margin-top: calc($spacer-xl * 1.25);
            height: 0.063rem;
            background-color: $text;
            @media screen and (min-width: 768px) {
              content: none;
            }
          }
        }
        .item {
          &--icon {
            width: 35px;
            height: 35px;

            img {
              object-fit: contain;
              width: 100%;
              height: 100%;
            }
          }
          &--title {
            font-size: 18px;
            font-weight: $fw-700;
          }
        }
        hr {
          display: none;
          @media screen and (min-width: 768px) {
            display: block;
            margin-block: calc($spacer * 1.5);
            height: 0.063rem;
            background-color: $text;
            border: none;
          }
        }
        .editor {
          margin-top: calc($spacer * 1.5);
          font-size: 14px;
        }
      }
    }
  }
}
