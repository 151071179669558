.section-panorama-3d {
  .title {
    font-size: 20px;
    font-weight: $fw-400;
    text-transform: uppercase;
    position: relative;
    margin-block: 0 $spacer;
    @media screen and (min-width: 1200px) {
      font-size: 40px;
      margin-block: 0 $spacer-xl;
    }
    &::after {
      content: "_";
      display: inline-block;
      color: $red;
    }
  }
}

.swiper-panorama-3d {
  .swiper-slide {
    transition: all;
    pointer-events: none;

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background-color: black;
      pointer-events: none;
      opacity: 0.5;
      transition: all;
    }

    .play-icon {
      display: none;
    }

    &.swiper-slide-active {
      transform: rotateY(0) translateZ(0) scaleX(1.3572) scaleY(1.3585) !important;
      pointer-events: all;

      img {
        border-radius: 15px;
        filter: drop-shadow(black 3px 3px 4px);
      }

      .play-icon {
        display: block;
        position: absolute;
        z-index: 10;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;

        svg {
          width: 85px;

          @media screen and (max-width: 500px) {
            width: 65px;
          }
        }
      }

      &:before {
        opacity: 0;
      }

      .legende {
        display: -webkit-box;
        position: absolute;
        bottom: calc(16 / 9 * -20px);
        width: 100%;
        text-align: center;
        font-size: 12px;
      }
    }
  }
}

.swiper-panorama {
  overflow: visible;
}

.swiper-panorama-3d {
  padding: 80px 0;
  width: 100%;
  position: relative;

  @media screen and (min-width: 768px) {
    padding: 150px 0;
  }
}
.swiper-panorama-3d .swiper {
  width: 100%;
  height: auto;
}
.swiper-panorama-3d .slide-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.popup-video {
  position: fixed;
  z-index: 99999;
  padding: 0 16px;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: none;
  opacity: 0;
  background-color: #000000ab;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  transition: all 0.5s ease;

  &.active {
    pointer-events: all;
    opacity: 1;
    visibility: visible;
  }

  iframe {
    max-width: 768px;
    width: 100%;
  }

  .close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    cursor: pointer;
  }
}
