.image-quote {
  /* padding-block: calc($spacer-xl * 1.5); */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 60px;
  /*   aspect-ratio: 16/9; */
  position: relative;
  /*  @media screen and (min-width: 1200px) {
    padding-block: calc($spacer-xxl * 2);
    aspect-ratio: 16/6;
  } */

  @media screen and (min-width: 768px) {
    padding-top: 95px;
    padding-bottom: 95px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1806364d;
    z-index: 8;
  }

  .bg-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .content {
    position: relative;
    z-index: 10;
    .grid {
      &__title {
        grid-column: 1 / -1;
        position: relative;
        z-index: 10;
        @media screen and (min-width: 768px) {
          grid-column: 2 / span 8;
        }
        .citation {
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
          font-size: 20px;
          line-height: 0.9;
          text-align: center;
          color: $white;
          position: relative;
          @media screen and (min-width: 1200px) {
            font-size: 80px;
            line-height: 96px;
          }
          span,
          b,
          strong {
            font-family: $font-family-serif;
            font-style: italic;
          }
          &::after {
            content: "_";
            display: inline-block;
            color: $red;
          }
        }
      }
    }
  }
}
