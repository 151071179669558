.subsidiaries-block {
    padding-block: calc($spacer-xl * 1.5);
    position: relative;
    @media screen and (min-width: 1200px) {
        padding-block: calc($spacer-xxl * 2);
    }
    &::after {
        content: '';
        position: absolute;
        top: calc($spacer-xl * -1.5);
        left: 0;
        width: 100%;
        height: calc(100% + calc($spacer-xl * 3));
        background-color: $white;
        z-index: -1;
        @media screen and (min-width: 1200px) {
            top: calc($spacer-xxl * -2);
            height: calc(100% + calc($spacer-xxl * 4));
        }
    }
    .map {
        display: none;
        @media screen and (min-width: 576px) {
            display: block;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            height: 100%;
            max-width: 1440px;
        }
        img {
            @media screen and (min-width: 576px) {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }
    }
    .content {
        .grid {
            position: relative;
            z-index: 10;
            .partners {
                &__content {
                    grid-column: span 4;
                    padding: calc($spacer * 1.5);
                    border-radius: $radius;
                    background-color: $blue-light;
                    @media screen and (min-width: 576px) {
                        grid-column: span 6;
                    }
                    @media screen and (min-width: 768px) {
                        grid-column: span 7;
                    }
                    @media screen and (min-width: 1200px) {
                        grid-column: span 5;
                        padding: calc($spacer-xl * 1.25) $spacer-xxl calc($spacer-xl * 1.25) calc($spacer-xl * 1.25);
                    }
                    .title {
                        font-size: 30px;
                        font-weight: $fw-400;
                        text-transform: uppercase;
                        margin-bottom: $spacer-xl;
                        position: relative;
                        @media screen and (min-width: 1200px) {
                            font-size: 40px;
                        }
                        &::after {
                            content: '_';
                            display: inline-block;
                            color: $red;
                        }
                    }
                    .editor {
                        font-size: 14px;
                        font-weight: 400;
                        margin-block: $spacer-xl;
                    }
                    .addresses {
                        &--list {
                            .accordion-item {
                                position: relative;
                                &:not(:last-child) {
                                    &::after {
                                        content: '';
                                        position: absolute;
                                        bottom: 0;
                                        left: 0;
                                        width: 100%;
                                        height: .063rem;
                                        background-color: $blue;
                                    }
                                }
                                .accordion {
                                    &-header {
                                        padding: $spacer;
                                        &--title {
                                            font-size: 14px;
                                            font-weight: $fw-700;
                                        }
                                    }
                                    &-content {
                                        &__inner {
                                            padding: 0 $spacer-xl $spacer;
                                        }
                                    }
                                }
                            }
                            .item {
                                &-title {
                                    font-size: 14px;
                                    font-weight: $fw-700;
                                    padding: $spacer;
                                }
                                &-content {
                                    &__inner {
                                        padding: 0 $spacer-xl $spacer;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}