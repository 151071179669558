/* === SECTION-CTA-PARTNERS === */
.section {
  &--cta-partners {
    margin-top: calc($spacer-xl * 1.5);
    position: relative;
    @media screen and (min-width: 576px) {
      padding-bottom: calc($spacer-xl * 1.5);
    }
    @media screen and (min-width: 1200px) {
      margin-top: calc($spacer-xxl * 2);
      padding-bottom: calc($spacer-xxl * 3);
    }
    &::after {
      content: "";
      position: absolute;
      top: calc($spacer-xl * -1.5);
      left: 0;
      width: 100%;
      height: calc(100% + calc($spacer-xl * 3));
      background-color: $white;
      z-index: -1;
      @media screen and (min-width: 1200px) {
        top: calc($spacer-xxl * -2);
        height: calc(100% + calc($spacer-xxl * 4));
      }
    }
    .map {
      @media screen and (min-width: 576px) {
        position: absolute;
        top: calc($spacer-xl * 1.5);
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: calc(100% - calc($spacer-xl * 1.5));
      }
      @media screen and (min-width: 1200px) {
        top: calc($spacer-xxl * 2);
        height: calc(100% - calc($spacer-xxl * 2));
        max-width: 1440px;
      }
      svg {
        width: 100% !important;
        height: 100% !important;
        .continent {
          &[data-href] {
            cursor: pointer;
          }
          .cls-16 {
            fill: transparent;
            transition: $transition;
            &:hover {
              fill: #8cfbf7;
            }
          }
        }
      }
      img {
        @media screen and (min-width: 576px) {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
    .content {
      margin-top: calc($spacer * -1.5);
      @media screen and (min-width: 576px) {
        margin-top: 0;
      }
      .grid {
        .partners {
          &__content {
            position: relative;
            z-index: 10;
            grid-column: span 4;
            padding: calc($spacer * 1.5);
            border-radius: $radius;
            background-color: $blue-light;
            @media screen and (min-width: 576px) {
              grid-column: span 6;
            }
            @media screen and (min-width: 768px) {
              grid-column: span 7;
            }
            @media screen and (min-width: 1200px) {
              grid-column: span 5;
              padding: calc($spacer-xl * 1.25) $spacer-xxl
                calc($spacer-xl * 1.25) calc($spacer-xl * 1.25);
            }
            .title {
              font-size: 30px;
              font-weight: $fw-400;
              text-transform: uppercase;
              margin-bottom: $spacer-xl;
              position: relative;
              @media screen and (min-width: 1200px) {
                font-size: 40px;
              }
              &::after {
                content: "_";
                display: inline-block;
                color: $red;
              }
            }
            .editor {
              font-size: 14px;
              font-weight: 400;
              margin-block: $spacer-xl;
            }
            .links {
              &--list {
                .link-item {
                  display: flex;
                  gap: $spacer;
                  align-items: center;
                  justify-content: space-between;
                  padding: $spacer;
                  &--title {
                    font-size: 14px;
                    font-weight: 700;
                    width: calc(100% - calc($spacer * 2.5));
                  }
                  &--icon {
                    width: calc($spacer * 1.5);
                    height: calc($spacer * 1.5);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg {
                      fill: $text;
                    }
                  }
                  &:not(:last-of-type) {
                    border-bottom: 0.063rem solid $blue;
                  }
                  &:hover {
                    background-color: $blue;
                    .link-item {
                      &--title {
                        color: $white;
                      }
                      &--icon {
                        svg {
                          fill: $white;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
