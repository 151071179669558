/* === SECTION-CTA-LAB === */
.section {
  &--cta-lab {
    padding-block: calc($spacer-xl * 1.5);
    position: relative;
    @media screen and (min-width: 1200px) {
      padding-block: calc($spacer-xxl * 2);
    }
    &::after {
      content: "";
      position: absolute;
      top: calc($spacer-xl * -1.5);
      left: 0;
      width: 100%;
      height: calc(100% + calc($spacer-xl * 3));
      background-color: $white;
      z-index: -1;
      @media screen and (min-width: 1200px) {
        top: calc($spacer-xxl * -2);
        height: calc(100% + calc($spacer-xxl * 4));
      }
    }
    .content {
      .grid {
        row-gap: $spacer;
        .content {
          &__header {
            grid-column: 1 / -1;
            display: flex;
            flex-direction: column;
            gap: $spacer-xl;
            margin-bottom: $spacer-xl;
            @media screen and (min-width: 576px) {
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
            }
            .title {
              font-size: 30px;
              font-weight: $fw-400;
              text-transform: uppercase;
              position: relative;
              @media screen and (min-width: 768px) {
                margin-bottom: 0;
              }
              @media screen and (min-width: 1200px) {
                font-size: 40px;
              }
              &::after {
                content: "_";
                display: inline-block;
                color: $red;
              }
            }
            .action {
              .btn {
                max-width: 100%;
                @media screen and (min-width: 768px) {
                  max-width: max-content;
                }
              }
            }
          }
        }
        .post-item {
          grid-column: span 4;
          @media screen and (min-width: 576px) {
            grid-column: 2 / span 8;
          }
          @media screen and (min-width: 768px) {
            grid-column: 3 / span 8;
          }
          @media screen and (min-width: 1200px) {
            grid-column: span 4;
          }

          .thumb {
            aspect-ratio: 16/10;
          }
        }
      }
    }
  }
}
