.our-history {
    padding-block: calc($spacer-xl * 1.5);
    position: relative;
    @media screen and (min-width: 1200px) {
        padding-block: calc($spacer-xxl * 2);
    }
    .bg-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    .content {
        position: relative;
        z-index: 10;
        .grid {
            &__title {
                grid-column: 1 / -1;
                .title {
                    font-size: 20px;
                    font-weight: $fw-400;
                    text-transform: uppercase;
                    color: $white;
                    margin-block: 0;
                    position: relative;
                    @media screen and (min-width: 1200px) {
                        font-size: 40px;
                    }
                    &::after {
                        content: '_';
                        display: inline-block;
                        color: $red;
                    }
                }
            }
            &__item {
                grid-column: 1 / -1;
                @media screen and (min-width: 768px) {
                    grid-column: span 6;
                }
                @media screen and (min-width: 1200px) {
                    grid-column: span 5;
                }
                &.first {
                    @media screen and (min-width: 1200px) {
                        grid-column: 2 / span 5;
                    }
                }
                &:not(.first) {
                    @media screen and (min-width: 768px) {
                        margin-top: $spacer-xl;
                    }
                    @media screen and (min-width: 1200px) {
                        margin-top: $spacer-xxl;
                    }
                }
                .item {
                    padding: $spacer;
                    background-color: $white;
                    border-radius: $radius;
                    @media screen and (min-width: 1200px) {
                        padding: calc($spacer-xl * 1.25);
                    }
                    &--header {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: $spacer;
                        .start,
                        .end {
                            font-size: 20px;
                            color: $red;
                            @media screen and (min-width: 1200px) {
                                font-size: 40px;
                            }
                        }
                        .deco {
                            svg {
                                stroke: $red;
                            }
                        }
                    }
                    &--title {
                        font-size: 14px;
                        font-weight: $fw-700;
                        margin-block: $spacer $spacer-xl;
                        @media screen and (min-width: 1200px) {
                            font-size: 32px;
                            margin-block: calc($spacer-xl * 1.25);
                        }
                    }
                    .editor {
                        font-size: 14px;
                        position: relative;
                        ul {
                            padding-left: calc($spacer * 1.5);
                            li {
                                position: relative;
                                &::marker {
                                    content: none;
                                }
                                &::before {
                                    content: '';
                                    position: absolute;
                                    top: calc($spacer * .75);
                                    left: calc($spacer * -1.5);
                                    width: 14px;
                                    height: 1px;
                                    background-color: $red;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}