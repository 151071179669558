.our-promises {
    padding-block: calc($spacer-xl * 1.5);
    @media screen and (min-width: 1200px) {
        padding-block: calc($spacer-xxl * 2);
    }
    .content {
        .grid {
            &__content {
                grid-column: 1 / -1;
                .title {
                    font-size: 20px;
                    font-weight: $fw-400;
                    text-transform: uppercase;
                    margin-block: 0 $spacer;
                    position: relative;
                    @media screen and (min-width: 1200px) {
                        font-size: 40px;
                        margin-bottom: $spacer-xl;
                    }
                    &::after {
                        content: '_';
                        display: inline-block;
                        color: $red;
                    }
                }
                .editor {
                    font-size: 14px;
                }
                .action {
                    margin-top: $spacer-xl;
                    .btn {
                        max-width: 100%;
                        @media screen and (min-width: 1200px) {
                            max-width: max-content;
                        }
                    }
                }
            }
        }
    }
}