.members {
    margin-block: calc($spacer-xl * 1.5);
    @media screen and (min-width: 1200px) {
        margin-block: calc($spacer-xxl * 2);
    }
    .content {
        .grid {
            &__title {
                grid-column: 1 / -1;
                position: relative;
                @media screen and (min-width: 768px) {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
                .title {
                    font-size: 20px;
                    font-weight: 400;
                    text-transform: uppercase;
                    position: relative;
                    margin-block: 0;
                    @media screen and (min-width: 1200px) {
                        font-size: 40px;
                    }
                    &::after {
                        content: "_";
                        display: inline-block;
                        color: $red;
                    }
                }
            }
            hr {
                grid-column: 1 / -1;
                margin-block: 0 $spacer;
                color: $text;
            }
            &__media {
                grid-column: 1 / -1;
                @media screen and (min-width: 768px) {
                    grid-column: 7 / span 6;
                    grid-row: 3 / span 1;
                }
                .img-container {
                    img {
                        object-fit: cover;
                        width: 100%;
                    }
                }
            }
            &__content {
                grid-column: 1 / -1;
                @media screen and (min-width: 768px) {
                    grid-column: 1 / span 5;
                    grid-row: 3 / span 1;
                }
                .editor {
                    font-size: 14px;
                }
                .action {
                    margin-top: $spacer;
                    @media screen and (min-width: 1200px) {
                        margin-top: $spacer-xl;
                    }
                    .btn {
                        max-width: 100%;
                        @media screen and (min-width: 1200px) {
                            max-width: max-content;
                        }
                    }
                }
            }
        }
    }
}