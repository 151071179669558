.numbers-legend {
    padding-block: calc($spacer-xl * 1.5);
    @media screen and (min-width: 1200px) {
        padding-block: calc($spacer-xxl * 2);
    }
    &.blue-dark-bg {
        background-color: $text;
    }
    &.blue-gray-bg {
        background-color: $blue-gray;
    }
    .grid {
        &__content {
            grid-column: 1 / -1;
            display: flex;
            flex-wrap: wrap;
            gap: $spacer;
            justify-content: space-around;
            padding: $spacer-xl;
            border: .063rem solid $white;
            border-radius: calc($radius * 3);
            background-color: rgba(28, 21, 52, 1);
            @media screen and (min-width: 1200px) {
                padding-block: $spacer-xxl;
            }
            .item {
                text-align: center;
                @media screen and (min-width: 768px) {
                    width: 50%;
                }
                @media screen and (min-width: 1200px) {
                    width: 30%;
                }
                @media screen and (min-width: 1400px) {
                    padding-inline: 1.5rem;
                }
                &__number {
                    font-size: 36px;
                    font-weight: $fw-600;
                    @media screen and (min-width: 1200px) {
                        font-size: 62px;
                    }
                }
                &__legend {
                    font-size: 16px;
                    font-weight: $fw-400;
                    @media screen and (min-width: 1200px) {
                        font-size: 22px;
                    }
                }
            }
        }
    }
}