.media {
  padding-block: calc($spacer-xl * 1.5);
  @media screen and (min-width: 1200px) {
    padding-block: calc($spacer-xxl * 2);
  }
  .content {
    .grid {
      &__media {
        grid-column: 1 / -1;
        width: 80%;
        margin: 0 auto;
        .img-container {
          img {
            object-fit: cover;
            width: 100%;
          }
        }
        iframe {
          width: 100%;
        }
      }
    }
  }
}
