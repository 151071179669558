.testimonials-block {
    padding-block: calc($spacer-xl * 1.5);
    background-color: $blue-light;
    @media screen and (min-width: 1200px) {
        padding-block: 0;
    }
    .content {
        .grid {
            .testimonials {
                &__content {
                    grid-column: span 4;
                    @media screen and (min-width: 576px) {
                        grid-column: 2 / span 8;
                    }
                    @media screen and (min-width: 768px) {
                        grid-column: 3 / span 8;
                    }
                    @media screen and (min-width: 1200px) {
                        grid-column: span 4;
                        padding-block: calc($spacer-xxl * 2);
                        grid-column: span 7;
                    }
                    > .title {
                        font-size: 30px;
                        font-weight: 400;
                        text-transform: uppercase;
                        margin-block: 0 calc($spacer-xl * 1.25);
                        position: relative;
                        @media screen and (min-width: 1200px) {
                            font-size: 40px;
                            margin-block: 0 calc($spacer-xl  * 1.375);
                        }
                        &::after {
                            content: '_';
                            display: inline-block;
                            color: $red;
                        }
                    }
                    .swiper {
                        &-slide {
                            opacity: 0!important;
                            &-active {
                                opacity: 1!important;
                            }
                        }
                        &__navigation {
                            position: relative;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            gap: $spacer-xl;
                            margin-top: $spacer-xl;
                            @media screen and (min-width: 768px) {
                                justify-content: center;
                            }
                        }
                        &-pagination {
                            position: initial;
                            transform: none;
                            width: max-content;
                            &-bullet {
                                background-color: $gray-hover;
                                opacity: 1;
                                &-active {
                                    background-color: $blue;
                                }
                            }
                        }
                        &-navigation {
                            display: flex;
                            align-items: center;
                        }
                        &-prev,
                        &-next {
                            position: initial;
                            width: 30px;
                            height: 30px;
                            cursor: pointer;
                            svg {
                                .circle {
                                    fill: none;
                                    stroke: $text;
                                    transition: $transition;
                                }
                                .arrow {
                                    fill: $text;
                                    transition: $transition;
                                }
                            }
                            &:hover {
                                svg {
                                    .circle {
                                        fill: none;
                                        stroke: $blue;
                                    }
                                    .arrow {
                                        fill: $blue;
                                    }
                                }
                            }
                        }
                    }
                }
                &__deco {
                    display: none;
                    @media screen and (min-width: 1200px) {
                        display: block;
                        grid-column: 9 / span 4;
                        .img-container {
                            background-color: $white;
                            height: 100%;
                            width: calc(100% + calc($spacer-xl * 1.5));
                            @media screen and (min-width: 1440px) {
                                width: calc(100% + calc($spacer-xl * 1.875));
                            }
                            @media screen and (min-width: 1680px) {
                                width: auto;
                                aspect-ratio: 1;
                            }
                            img {
                                object-fit: cover;
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}