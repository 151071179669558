.keywords-block {
    padding-block: calc($spacer-xl * 1.5);
    background-color: $white;
    @media screen and (min-width: 1200px) {
        padding-block: 0;
    }
    .content {
        .grid {
            &__deco {
                display: none;
                @media screen and (min-width: 1200px) {
                    display: block;
                    grid-column: 1 / span 5;
                    position: relative;
                }
                img {
                    @media screen and (min-width: 1200px) {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: $spacer-xl;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            &__content {
                grid-column: 1 / -1;
                @media screen and (min-width: 576px) {
                    grid-column: 2 / span 8;
                }
                @media screen and (min-width: 768px) {
                    grid-column: 3 / span 8;
                }
                @media screen and (min-width: 1200px) {
                    grid-column: 6 / span 7;
                    padding-block: calc($spacer-xxl * 2);
                }
                .title {
                    font-size: 20px;
                    font-weight: $fw-400;
                    text-transform: uppercase;
                    margin-block: 0 $spacer-xl;
                    position: relative;
                    @media screen and (min-width: 1200px) {
                        font-size: 40px;
                        margin-bottom: calc($spacer-xl * 1.25);
                    }
                    &::after {
                        content: "_";
                        display: inline-block;
                        color: $red;
                    }
                }
                .keyword-item {
                    font-size: 14px;
                    font-weight: $fw-700;
                    position: relative;
                    @media screen and (min-width: 1200px) {
                        font-size: 18px;
                    }
                    &:not(:last-child) {
                        &::after {
                            content: '';
                            display: block;
                            margin-block: $spacer;
                            height: .063rem;
                            background-color: $black;
                            @media screen and (min-width: 1200px) {
                                margin-block: calc($spacer * 1.5);
                            }
                        }
                    }
                }
            }
        }
    }
}