.section {
  &--cta-about {
    margin-block: calc($spacer-xl * 1.5);
    @media screen and (min-width: 1200px) {
      margin-block-start: calc($spacer-xxl * 2);
      margin-block-end: calc($spacer-xxl);
    }
    .grid {
      .grid--item {
        grid-column: span 4;
        margin-bottom: $spacer;
        position: relative;
        @media screen and (min-width: 576px) {
          grid-column: 2 / span 8;
          padding: calc($spacer-xl * 2) $spacer-xl $spacer;
        }
        @media screen and (min-width: 768px) {
          grid-column: 3 / span 8;
          padding-inline: calc($spacer-xl * 1.5);
        }
        @media screen and (min-width: 1200px) {
          grid-column: span 4;
        }
        &:not(:first-of-type)::before {
          content: "";
          display: block;
          margin-block: $spacer calc($spacer-xl * 1.5);
          width: 100%;
          height: 0.063rem;
          background-color: $blue-darker;
        }
        &::before {
          @media screen and (min-width: 576px) {
            content: "";
            position: absolute;
            top: $spacer;
            left: 0;
            margin-block: 0 !important;
            width: 100%;
            height: 0.063rem;
            background-color: $blue-darker;
          }
        }
        &::after {
          @media screen and (min-width: 576px) {
            content: "";
            position: absolute;
            top: 0;
            left: $spacer;
            width: 0.063rem;
            height: 100%;
            background-color: $blue-darker;
          }
        }
        &__content {
          .item {
            &--icon {
              width: 75px;
              aspect-ratio: 1;
              margin-bottom: $spacer-xl;
              img {
                object-fit: contain;
                width: 100%;
                height: 100%;
              }
            }
            &--title {
              font-size: 24px;
              font-weight: $fw-400;
              text-transform: uppercase;
              margin-block: $spacer-xl;
            }
          }
          .editor {
            color: $gray-dark;
          }
        }
      }
    }
    .action {
      display: flex;
      justify-content: center;
      margin-top: calc($spacer * 3.125);
      padding-inline: calc($spacer * 0.5);
      @media screen and (min-width: 360px) {
        padding-inline: $spacer;
      }
      .btn {
        width: 100%;
        max-width: 100%;
        @media screen and (min-width: 576px) {
          width: max-content;
        }
      }
    }
  }
}
