.partners-block {
  padding-block: calc($spacer-xl * 1.5);
  @media screen and (min-width: 1200px) {
    padding-block: calc($spacer-xxl * 2);
  }
  .content {
    .grid {
      row-gap: calc($spacer-xl * 1.25);
      &__title {
        grid-column: 1 / -1;
        @media screen and (min-width: 576px) {
          grid-column: 2 / span 8;
        }
        @media screen and (min-width: 768px) {
          grid-column: 1 / -1;
        }
        .title {
          font-size: 20px;
          font-weight: 400;
          text-transform: uppercase;
          position: relative;
          margin-block: 0;
          @media screen and (min-width: 1200px) {
            font-size: 40px;
          }
          &::after {
            content: "_";
            display: inline-block;
            color: $red;
          }
        }
        .editor {
          margin-top: calc($spacer-xl * 1.25);
        }
      }
      &__content {
        grid-column: 1 / -1;
        display: flex;
        flex-direction: column;
        gap: calc($spacer-xl * 1.25);
        @media screen and (min-width: 1200px) {
          flex-direction: row;
          gap: $spacer;
          flex-wrap: wrap;
        }
        .item {
          position: relative;
          @media screen and (min-width: 1200px) {
            flex: 1;
            min-width: calc(100% / 6 - $spacer);
            max-width: calc(100% / 6 - $spacer);
          }
          &:not(:last-child) {
            &::after {
              content: "";
              display: block;
              margin-top: calc($spacer-xl * 1.25);
              height: 0.063rem;
              background-color: $text;
              @media screen and (min-width: 768px) {
                content: none;
              }
            }
          }
          > a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
          &--icon {
            width: 45px;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              object-fit: contain;
              width: 100%;
              height: 100%;
            }
          }
          &--title {
            font-size: 14px;
            @media screen and (min-width: 1200px) {
              font-size: 20px;
              font-weight: bold;
            }
          }
          hr {
            display: none;
            @media screen and (min-width: 768px) {
              display: block;
              margin-block: calc($spacer * 1.5);
              height: 0.063rem;
              background-color: $text;
              border: none;
              width: 100%;
            }
          }
          .editor {
            margin-top: calc($spacer * 1.5);
            font-size: 14px;
          }
        }
      }
    }
  }
}
