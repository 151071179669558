body {
  font-family: $font-family-sans-serif;
  margin: 0;
  background-color: $light;
}

.font-serif {
  font-family: $font-family-serif;
}

.main-breadcrumbs {
  margin-bottom: $spacer;
  @media screen and (min-width: 1200px) {
    margin-bottom: $spacer-xxl;
  }
  span {
    display: flex;
    align-items: center;
    gap: calc($spacer * 0.25);
    flex-wrap: wrap;
    span {
      color: $text;
      display: flex;
      align-items: center;
      gap: calc($spacer * 0.25);
      position: relative;
      &:not(:last-child) {
        opacity: 0.5;
        &::after {
          content: "";
          display: block;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath d='M13.1714 12.0007L8.22168 7.05093L9.63589 5.63672L15.9999 12.0007L9.63589 18.3646L8.22168 16.9504L13.1714 12.0007Z' fill='rgba(24,6,54,1)'%3E%3C/path%3E%3C/svg%3E");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          width: $spacer;
          height: $spacer;
        }
      }
    }
  }
}

a {
  text-decoration: none;
  color: currentColor;
}

.editor {
  font-size: 14px;
  @media screen and (min-width: 1200px) {
    font-size: 18px;
  }
  *:first-child {
    margin-top: 0;
  }
  *:last-child {
    margin-bottom: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  blockquote {
    margin: $spacer 0;
    border-radius: $radius;
  }
  h2 {
    font-size: 20px;
    @media screen and (min-width: 1200px) {
      font-size: 40px;
    }
  }
  h3 {
    font-size: 18px;
    @media screen and (min-width: 1200px) {
      font-size: 36px;
    }
  }
  h4 {
    font-size: 16px;
    @media screen and (min-width: 1200px) {
      font-size: 30px;
    }
  }
  h5 {
    font-size: 14px;
    @media screen and (min-width: 1200px) {
      font-size: 28px;
    }
  }
  h5 {
    font-size: 14px;
    @media screen and (min-width: 1200px) {
      font-size: 24px;
    }
  }
  hr {
    margin: $spacer-xl 0;
    clear: both;
  }
  a {
    color: $text;
    border-bottom: solid 2px $gray;
    &:hover {
      color: $text;
      border-bottom: solid 2px $red;
    }
  }
  ul,
  ol {
    padding-left: calc($spacer * 1.5);
  }
  ul {
    li {
      position: relative;
      &::marker {
        content: none;
      }
      &::before {
        content: "";
        position: absolute;
        top: calc($spacer * 0.75);
        left: calc($spacer * -1.5);
        width: 14px;
        height: 1px;
        background-color: $red;
      }
    }
  }
  blockquote {
    font-style: italic;
    padding: $spacer;
    border: solid 0.125rem $light;
  }
  img {
    border-radius: $radius;
    &.alignleft {
      margin-bottom: $spacer;
      width: 100% !important;
      height: auto;
      @media screen and (min-width: 768px) {
        /* float: left; */
        width: 50% !important;
        margin-right: $spacer;
      }
    }
    &.alignright {
      margin-bottom: $spacer;
      width: 100% !important;
      height: auto;
      @media screen and (min-width: 768px) {
        /*  float: right; */
        width: 50% !important;
        margin-left: $spacer;
      }
    }
    &.aligncenter {
      margin: $spacer auto;
      display: block;
      max-width: 100%;
      height: auto;
    }
    &.alignnone {
      margin: $spacer 0;
      display: block;
      width: 100%;
      height: auto;
      max-width: inherit;
      @media screen and (min-width: 768px) {
        margin: $spacer 0;
      }
    }
  }
  figure + figure {
    margin-top: 40px;
  }
  .wp-caption {
    &.alignleft {
      margin-bottom: $spacer;
      width: 100% !important;
      height: auto;
      @media screen and (min-width: 768px) {
        float: left;
        width: 50% !important;
        margin-right: $spacer;
      }
      img {
        width: 100% !important;
      }
    }
    &.alignright {
      margin-bottom: $spacer;
      width: 100% !important;
      height: auto;
      @media screen and (min-width: 768px) {
        float: right;
        width: 50% !important;
        margin-left: $spacer;
      }
      img {
        width: 100% !important;
      }
    }
    &.alignnone {
      width: 100% !important;
      @media screen and (min-width: 768px) {
        margin: $spacer calc($spacer * -1);
        width: calc(100% + calc($spacer * 2)) !important;
      }
      .wp-caption-text {
        margin: 0;
        @media screen and (min-width: 768px) {
          margin: 0 $spacer;
        }
      }
    }
    .wp-caption-text {
      margin: 0;
      font-size: 85%;
      color: $gray-dark;
      font-style: italic;
    }
  }
}

.btn {
  padding: $spacer calc($spacer * 1.5);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: calc($spacer * 0.5);
  max-width: max-content;
  border: 0.063rem solid;
  border-radius: $radius;
  transition: $transition;
  &-red {
    background-color: $red;
    color: $white;
    border-color: $red;
    &:hover,
    &:active {
      background-color: $red-hover;
      border-color: $red-hover;
    }
    &:disabled {
      background-color: $gray-hover;
    }
    &:focus {
      outline: 0.063rem solid $black;
      outline-offset: 0.125rem;
    }
  }
  &-bordered {
    border-color: $text;
    transition: $transition;
    &:hover {
      background-color: $text;
      color: $white;
    }
  }
  &-white {
    background-color: $white;
    border-color: $white;
    color: $text;
  }
  &-blue {
    background-color: $blue;
    border-color: $blue;
    color: $white;
    .btn-icon {
      svg {
        fill: $white;
      }
    }
  }
  .btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      transition: $transition;
      fill: currentColor;
    }
  }
  &-white {
    &:hover {
      background-color: transparent;
      color: $white;
      .btn-icon {
        svg {
          fill: $white;
        }
      }
    }
  }
  &-blue {
    &:hover {
      background-color: $blue-dark-secondary;
      border-color: $blue-dark-secondary;
    }
  }
}

.link {
  display: flex;
  align-items: center;
  gap: calc($spacer * 0.5);
  width: max-content;
  &-deco {
    display: flex;
    align-items: center;
    gap: calc($spacer * 0.125);
    width: max-content;
    position: relative;
    &::before {
      content: "";
      display: flex;
      width: 2.5rem;
      height: 0.063rem;
      background-color: $text;
      transition: $transition;
    }
    &::after {
      content: "";
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12px;
      height: 12px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='12' height='12'%3E%3Cpath d='M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z'%3E%3C/path%3E%3C/svg%3E");
      background-size: contain;
      background-repeat: no-repeat;
      transition: $transition;
    }
  }

  &:hover {
    .link {
      &-deco {
        &::before {
          margin-left: 2.5rem;
          width: 0;
        }
        &::after {
          transform: rotate(180deg);
        }
      }
    }
  }
}

/* === GRID === */
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: calc($spacer * 0.5);
  padding-inline: calc($spacer * 0.5);
  margin-inline: auto;
  max-width: 1320px;
  @media screen and (min-width: 360px) {
    padding-inline: $spacer;
  }
  @media screen and (min-width: 576px) {
    grid-template-columns: repeat(10, 1fr);
  }
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(12, 1fr);
    gap: $spacer;
    padding-inline: calc($spacer-xl * 1.5);
  }
  @media screen and (min-width: 1200px) {
    padding-inline: calc($spacer * 0.5);
  }
}

.container {
  max-width: 1320px;
  padding-inline: calc($spacer * 0.5);
  @media screen and (min-width: 360px) {
    padding-inline: $spacer;
  }

  @media screen and (min-width: 768px) {
    padding-inline: calc($spacer-xl * 1.5);
  }
}

header {
  top: 0;
  left: 0;
}

/* === ACCORDION === */
.accordion {
  &-header {
    cursor: pointer;
    padding: $spacer;
    @media screen and (min-width: 1200px) {
      padding: calc($spacer-xl * 1.25);
    }
    &:focus-visible {
      outline: none;
    }
    &--title {
      display: block;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: $spacer;
        height: $spacer;
        background-image: url("../img/icons/icon-accordion.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        transform-origin: 50% 50%;
        transition: $transition;
        @media screen and (min-width: 1200px) {
          top: calc($spacer * -0.375);
          width: $spacer-xl;
          height: $spacer-xl;
        }
      }
    }
    &.active {
      .accordion-header {
        &--title {
          &::after {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }
  &-content {
    height: 0;
    overflow: hidden;
  }
}

/* === FORM-CHECK === */
.form-check {
  position: relative;
  &-input {
    opacity: 0;
    position: absolute;
    z-index: -1;
    &:checked + .form-check-label::before {
      background: $white;
      border-color: $black;
    }
  }
  &-label {
    display: flex;
    padding-left: calc($spacer * 1.5);
    position: relative;
    cursor: pointer;
    transition: $transition;
    &::before {
      content: "";
      position: absolute;
      top: calc($spacer * 0.375);
      left: 0;
      width: calc($spacer * 0.75);
      height: calc($spacer * 0.75);
      border-radius: 50%;
      border: 0.063rem solid $black;
      background: white;
    }
    &::after {
      content: "";
      position: absolute;
      top: calc($spacer * 0.5);
      left: calc($spacer * 0.125);
      width: calc($spacer * 0.5);
      height: calc($spacer * 0.5);
      border-radius: 50%;
      background-color: $black;
      transform: scale(0);
      transition: $transition;
    }
  }
  .form-check-input:checked + &-label {
    font-weight: $fw-700;
  }
  .form-check-input:checked + &-label::after {
    transform: scale(1);
  }
}

/* === BTN-CHECK === */
.btn-check {
  &-input {
    display: none;
    + label {
      display: block;
      width: max-content;
      padding: calc($spacer * 0.25) calc($spacer * 0.75);
      border: 0.125rem solid $gw-light-blue;
      border-radius: $radius;
      color: $gw-light-blue;
      transition: $transition;
      cursor: pointer;
      &:hover {
        background-color: $gw-light-blue;
        color: $text-hover;
      }
    }
    &:checked {
      + label {
        background-color: $gw-light-blue;
        color: $text-hover;
      }
    }
  }
}

/* === LIST-ITEM MARKER RESET ===*/
.list-item {
  &::marker {
    content: none;
  }
}
