.title-dual-col-wysiwyg {
    padding-block: calc($spacer-xl * 1.5);
    @media screen and (min-width: 1200px) {
        padding-block: calc($spacer-xxl * 2);
    }
    &.blue-dark-bg {
        background-color: $text;
    }
    &.blue-gray-bg {
        background-color: $blue-gray;
    }
    .grid {
        &__title {
            grid-column: 1 / -1;
            .title {
                font-size: 30px;
                font-weight: $fw-400;
                text-transform: uppercase;
                margin-block: 0 calc($spacer-xl * 1.25);
                position: relative;
                @media screen and (min-width: 1200px) {
                    font-size: 40px;
                    margin-bottom: calc($spacer-xl * 1.375);
                }
                &::after {
                    content: "_";
                    display: inline-block;
                    color: $red;
                }
            }
        }
        &__left {
            grid-column: 1 / -1;
            @media screen and (min-width: 1200px) {
                grid-column: 1 / span 6;
                padding-right: $spacer-xl;
            }
            &.has-border {
                border: .063rem solid $white;
                border-radius: calc($radius * 2);
                padding: $spacer;
                height: max-content;
                @media screen and (min-width: 1200px) {
                    padding: $spacer-xl;
                }
            }
        }
        &__right {
            grid-column: 1 / -1;
            @media screen and (min-width: 1200px) {
                grid-column: 7 / span 6;
                padding-left: $spacer-xl;
            }
            &.has-border {
                border: .063rem solid $white;
                border-radius: calc($radius * 2);
                padding: $spacer;
                height: max-content;
                @media screen and (min-width: 1200px) {
                    padding: $spacer-xl;
                }
            }
        }
    }
}