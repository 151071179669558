.css-footermenu__link {
  @apply text-white hover:text-gw-light-blue duration-200 text-[16px] md:text-[14px] font-sansserif  tracking-wide;
}

.js-accordion {
  &:not(.is-opened) {
    .js-accordion-content {
      height: 0;
    }
  }

  &.is-opened {
    .js-accordion-content {
      height: max-content;
    }
    .css-icon {
      @apply -rotate-180;
    }
  }
  .js-accordion-content {
    overflow: hidden;
    @screen md {
      height: max-content !important;
    }
  }
}
