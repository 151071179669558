/* Barre de défilement principale (à l'extérieur) */
::-webkit-scrollbar {
	width: 3px; /* Largeur de la barre de défilement */
}

/* Barre de défilement principale (à l'intérieur) */
::-webkit-scrollbar-thumb {
	@apply bg-red;
}
/* Couleur de fond de la zone de défilement */
::-webkit-scrollbar-track {
	background: #f1f1f1; /* Couleur de fond */
}
/* Couleur de fond de la zone de défilement */
::-webkit-scrollbar-track {
	background: #f1f1f1; /* Couleur de fond */
}
/* Barre de défilement horizontale (à l'extérieur) */
::-webkit-scrollbar-horizontal {
	height: 12px; /* Hauteur de la barre de défilement horizontale */
}
/* Barre de défilement horizontale (à l'intérieur) */
::-webkit-scrollbar-thumb:horizontal {
	@apply bg-blue;
}
