.section {
  &--cta-solutions {
    padding-block: calc($spacer-xl * 1.5);
    background-color: $gw-light-blue;
    @media screen and (min-width: 1200px) {
      padding-block-start: calc($spacer-xxl);
      padding-block-end: calc($spacer-xxl * 2);
      position: relative;
    }
    .bg-container {
      display: none;
      @media screen and (min-width: 1200px) {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .content {
      position: relative;
      z-index: 10;
      .grid {
        .title {
          grid-column: 1 / -1;
          font-size: 30px;
          font-weight: $fw-400;
          text-transform: uppercase;
          margin-block: 0 calc($spacer-xl * 1.25);
          position: relative;
          @media screen and (min-width: 1200px) {
            font-size: 40px;
            margin-bottom: calc($spacer-xl * 1.375);
          }
          &::after {
            content: "_";
            display: inline-block;
            color: $red;
          }
        }
      }
    }
  }
}

.solution-item {
  grid-column: span 4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $spacer-xl calc($spacer * 1.5) calc($spacer * 1.5);
  background-color: $white;
  border: 0.063rem solid $gray;
  border-radius: $radius;
  @media screen and (min-width: 576px) {
    grid-column: 2 / span 8;
  }
  @media screen and (min-width: 768px) {
    grid-column: span 6;
  }
  @media screen and (min-width: 1200px) {
    grid-column: span 4;
  }
  .icon {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.625rem;
  }
  &__content {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: $spacer;
  }
  .baseline {
    font-weight: $fw-400;
    margin-top: 0.625rem;
    width: calc(100% - $spacer);
    @media screen and (min-width: 1200px) {
      margin-block: $spacer 0;
    }
  }
  .arrow {
    svg {
      fill: $red;
    }
  }
}
