.css-menu__group {
  .css-menu__link {
    @apply overflow-hidden py-1 font-sansserif tracking-wide;

    &::after {
      content: "";
      position: absolute;
      @apply h-px -bottom-0 -left-1 right-1 -translate-x-full bg-blue duration-200;
    }
  }

  &:hover {
    .css-menu__link {
      &::after {
        @apply translate-x-0;
      }
    }
  }
}

.disable-hover {
  z-index: -10 !important;
  pointer-events: none !important;
}
