.tool-pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $spacer-xl;
    margin-top: $spacer-xl;
    @media screen and (min-width: 768px) {
        justify-content: flex-start;
    }
    .prev,
    .next {
        height: 30px;
        width: 30px;
        cursor: pointer;
        svg {
            .circle {
                fill: none;
                stroke: $text;
                transition: $transition;
            }
            .arrow {
                fill: $text;
                transition: $transition;
            }
        }
        &:hover {
            svg {
                .circle {
                    fill: none;
                    stroke: $blue;
                }
                .arrow {
                    fill: $blue;
                }
            }
        }
        &.disabled {
            opacity: .5;
            pointer-events: none;
        }
    }
    .pages {
        display: flex;
        align-items: center;
        gap: calc($spacer * .25);
        .page-number {
            font-size: 14px;
        }
        .current {
            font-weight: $fw-700;
            color: $blue;
        }
        a {
            color: $gray-hover;
            &:hover {
                color: $blue;
            }
        }
    }
}