/* === SECTION-CTA-PRODUCTS === */
.section {
  &--cta-products {
    padding-block: calc($spacer-xl * 1.5);
    background-color: $light;
    @media screen and (min-width: 1200px) {
      padding-block-start: calc($spacer-xxl * 2);
      padding-block-end: calc($spacer-xxl);
    }
    .content {
      .grid {
        .title {
          grid-column: 1 / -1;
          font-size: 30px;
          font-weight: $fw-400;
          text-transform: uppercase;
          margin-bottom: calc($spacer-xl * 1.25);
          position: relative;
          @media screen and (min-width: 1200px) {
            font-size: 40px;
            margin-bottom: calc($spacer-xl * 1.375);
          }
          &::after {
            content: "_";
            display: inline-block;
            color: $red;
          }
        }
      }
    }
  }
}

.product-item {
  grid-column: span 4;
  border: 0.063rem solid $gray;
  background-color: $white;
  border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
  padding: calc($spacer-xl * 2.25) calc($spacer * 1.25);
  @media screen and (min-width: 576px) {
    grid-column: span 5;
  }
  @media screen and (min-width: 768px) {
    grid-column: span 6;
  }
  &.large {
    @media screen and (min-width: 576px) {
      grid-column: 1 / -1;
    }
    @media screen and (min-width: 768px) {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: $spacer;
      align-items: center;
    }
  }
  //&.border-red {
  //  border-top: 0.313rem solid $red;
  //}
  //&.border-yellow {
  //  border-top: 0.313rem solid $yellow;
  //}
  //&.border-blue {
  //  border-top: 0.313rem solid $blue;
  //}
  .icon {
    width: 75px;
    height: 75px;
    margin-bottom: $spacer-xl;
    @media screen and (min-width: 1200px) {
      grid-column: span 2;
      width: 100px;
      height: 100px;
    }
  }
  .product-title {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: $fw-400;
    margin-block: $spacer-xl;
    position: relative;
    @media screen and (min-width: 1200px) {
      font-size: 30px;
    }
  }
  hr {
    display: block;
    width: 100%;
    height: 0.063rem;
    border: none;
    background-color: $blue-darker;
    margin-block: $spacer-xl;
  }
  .excerpt {
    font-size: 14px;
    font-weight: $fw-400;
    margin-block: $spacer-xl;
    @media screen and (min-width: 768px) {
      grid-column: 3 / span 8;
      margin-block: 0;
    }
    @media screen and (min-width: 1200px) {
      grid-column: span 5;
      margin-block: 0;
    }
  }
  .action {
    margin-top: $spacer-xl;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: calc($spacer * 1);
  }
  &.large {
    .icon {
      @media screen and (min-width: 768px) {
        grid-column: span 2;
        margin-bottom: 0;
      }
      @media screen and (min-width: 1200px) {
        grid-column: span 1;
      }
    }
    .product-title {
      @media screen and (min-width: 768px) {
        grid-column: 3 / span 6;
        padding-right: 0;
        margin-block: 0;
      }
      @media screen and (min-width: 1200px) {
        grid-column: span 4;
        padding-right: 0;
        margin-block: 0;
        margin-left: $spacer-xxl;
      }
    }
    .action {
      @media screen and (min-width: 768px) {
        grid-column: 3 / span 6;
        margin-top: 0;
      }
      @media screen and (min-width: 1200px) {
        grid-column: span 2;
        margin-top: 0;
        margin-inline: auto;
      }
    }
  }

  &.in-menu {
    &:last-child {
      grid-column: 1 / -1;
      .product-title {
        width: 50%;
      }
      .excerpt {
        width: 50%;
      }
      .icon {
        @media screen and (min-width: 768px) {
          grid-column: span 2;
          margin-bottom: 0;
        }
        @media screen and (min-width: 1200px) {
          grid-column: span 1;
        }
      }
      .product-title {
        @media screen and (min-width: 768px) {
          grid-column: 3 / span 6;
          padding-right: 0;
          margin-block: 0;
        }
        @media screen and (min-width: 1200px) {
          grid-column: span 4;
          padding-right: 0;
          margin-block: 0;
          margin-left: $spacer-xxl;
        }
      }
      .action {
        @media screen and (min-width: 768px) {
          grid-column: 3 / span 6;
          margin-top: 0;
        }
        @media screen and (min-width: 1200px) {
          grid-column: span 2;
          margin-top: 0;
          margin-inline: auto;
        }
      }
    }
  }
}
