@charset "UTF-8";
/* -- Thin -- */
/* -- Light -- */
/* -- Regular -- */
/* -- Medium -- */
/* -- SemiBold -- */
/* -- Bold -- */
@import "plyr/dist/plyr.css";
@import "swiper/swiper-bundle.css";
@tailwind base;
@tailwind components;
@tailwind utilities; /* eslint-disable */ /* eslint-disable */
.svg-contain svg {
  width: 100% !important;
  height: 100% !important;
}

.iframe-contain iframe {
  @apply w-full h-full;
}

button.plyr__control--overlaid {
  background-color: #3E3CFF;
}

body {
  font-family: "Spline Sans", sans-serif;
  margin: 0;
  background-color: #FAFAFA;
}

.font-serif, .block-hero--5 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) em, .block-hero--3 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) em, .block-hero--2 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) em, .block-hero--1 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) em {
  font-family: "ivypresto-display", serif;
}

.main-breadcrumbs {
  margin-bottom: 1rem;
}
@media screen and (min-width: 1200px) {
  .main-breadcrumbs {
    margin-bottom: 4rem;
  }
}
.main-breadcrumbs span {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  flex-wrap: wrap;
}
.main-breadcrumbs span span {
  color: #180636;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  position: relative;
}
.main-breadcrumbs span span:not(:last-child) {
  opacity: 0.5;
}
.main-breadcrumbs span span:not(:last-child)::after {
  content: "";
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath d='M13.1714 12.0007L8.22168 7.05093L9.63589 5.63672L15.9999 12.0007L9.63589 18.3646L8.22168 16.9504L13.1714 12.0007Z' fill='rgba(24,6,54,1)'%3E%3C/path%3E%3C/svg%3E");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 1rem;
  height: 1rem;
}

a {
  text-decoration: none;
  color: currentColor;
}

.editor {
  font-size: 14px;
}
@media screen and (min-width: 1200px) {
  .editor {
    font-size: 18px;
  }
}
.editor *:first-child {
  margin-top: 0;
}
.editor *:last-child {
  margin-bottom: 0;
}
.editor h1,
.editor h2,
.editor h3,
.editor h4,
.editor h5,
.editor h6,
.editor p,
.editor ul,
.editor ol,
.editor blockquote {
  margin: 1rem 0;
  border-radius: 0.75rem;
}
.editor h2 {
  font-size: 20px;
}
@media screen and (min-width: 1200px) {
  .editor h2 {
    font-size: 40px;
  }
}
.editor h3 {
  font-size: 18px;
}
@media screen and (min-width: 1200px) {
  .editor h3 {
    font-size: 36px;
  }
}
.editor h4 {
  font-size: 16px;
}
@media screen and (min-width: 1200px) {
  .editor h4 {
    font-size: 30px;
  }
}
.editor h5 {
  font-size: 14px;
}
@media screen and (min-width: 1200px) {
  .editor h5 {
    font-size: 28px;
  }
}
.editor h5 {
  font-size: 14px;
}
@media screen and (min-width: 1200px) {
  .editor h5 {
    font-size: 24px;
  }
}
.editor hr {
  margin: 2rem 0;
  clear: both;
}
.editor a {
  color: #180636;
  border-bottom: solid 2px #DADBE0;
}
.editor a:hover {
  color: #180636;
  border-bottom: solid 2px #FF1435;
}
.editor ul,
.editor ol {
  padding-left: 1.5rem;
}
.editor ul li {
  position: relative;
}
.editor ul li::marker {
  content: none;
}
.editor ul li::before {
  content: "";
  position: absolute;
  top: 0.75rem;
  left: -1.5rem;
  width: 14px;
  height: 1px;
  background-color: #FF1435;
}
.editor blockquote {
  font-style: italic;
  padding: 1rem;
  border: solid 0.125rem #FAFAFA;
}
.editor img {
  border-radius: 0.75rem;
}
.editor img.alignleft {
  margin-bottom: 1rem;
  width: 100% !important;
  height: auto;
}
@media screen and (min-width: 768px) {
  .editor img.alignleft {
    /* float: left; */
    width: 50% !important;
    margin-right: 1rem;
  }
}
.editor img.alignright {
  margin-bottom: 1rem;
  width: 100% !important;
  height: auto;
}
@media screen and (min-width: 768px) {
  .editor img.alignright {
    /*  float: right; */
    width: 50% !important;
    margin-left: 1rem;
  }
}
.editor img.aligncenter {
  margin: 1rem auto;
  display: block;
  max-width: 100%;
  height: auto;
}
.editor img.alignnone {
  margin: 1rem 0;
  display: block;
  width: 100%;
  height: auto;
  max-width: inherit;
}
@media screen and (min-width: 768px) {
  .editor img.alignnone {
    margin: 1rem 0;
  }
}
.editor figure + figure {
  margin-top: 40px;
}
.editor .wp-caption.alignleft {
  margin-bottom: 1rem;
  width: 100% !important;
  height: auto;
}
@media screen and (min-width: 768px) {
  .editor .wp-caption.alignleft {
    float: left;
    width: 50% !important;
    margin-right: 1rem;
  }
}
.editor .wp-caption.alignleft img {
  width: 100% !important;
}
.editor .wp-caption.alignright {
  margin-bottom: 1rem;
  width: 100% !important;
  height: auto;
}
@media screen and (min-width: 768px) {
  .editor .wp-caption.alignright {
    float: right;
    width: 50% !important;
    margin-left: 1rem;
  }
}
.editor .wp-caption.alignright img {
  width: 100% !important;
}
.editor .wp-caption.alignnone {
  width: 100% !important;
}
@media screen and (min-width: 768px) {
  .editor .wp-caption.alignnone {
    margin: 1rem -1rem;
    width: calc(100% + 2rem) !important;
  }
}
.editor .wp-caption.alignnone .wp-caption-text {
  margin: 0;
}
@media screen and (min-width: 768px) {
  .editor .wp-caption.alignnone .wp-caption-text {
    margin: 0 1rem;
  }
}
.editor .wp-caption .wp-caption-text {
  margin: 0;
  font-size: 85%;
  color: #595B65;
  font-style: italic;
}

.btn {
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  max-width: max-content;
  border: 0.063rem solid;
  border-radius: 0.75rem;
  transition: all 0.35s ease-in-out;
}
.btn-red {
  background-color: #FF1435;
  color: #FFFFFF;
  border-color: #FF1435;
}
.btn-red:hover, .btn-red:active {
  background-color: #C6292A;
  border-color: #C6292A;
}
.btn-red:disabled {
  background-color: #C1C1C1;
}
.btn-red:focus {
  outline: 0.063rem solid #000000;
  outline-offset: 0.125rem;
}
.btn-bordered {
  border-color: #180636;
  transition: all 0.35s ease-in-out;
}
.btn-bordered:hover {
  background-color: #180636;
  color: #FFFFFF;
}
.btn-white {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  color: #180636;
}
.btn-blue {
  background-color: #3E3CFF;
  border-color: #3E3CFF;
  color: #FFFFFF;
}
.btn-blue .btn-icon svg {
  fill: #FFFFFF;
}
.btn .btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn .btn-icon svg {
  transition: all 0.35s ease-in-out;
  fill: currentColor;
}
.btn-white:hover {
  background-color: transparent;
  color: #FFFFFF;
}
.btn-white:hover .btn-icon svg {
  fill: #FFFFFF;
}
.btn-blue:hover {
  background-color: #22296C;
  border-color: #22296C;
}

.link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: max-content;
}
.link-deco {
  display: flex;
  align-items: center;
  gap: 0.125rem;
  width: max-content;
  position: relative;
}
.link-deco::before {
  content: "";
  display: flex;
  width: 2.5rem;
  height: 0.063rem;
  background-color: #180636;
  transition: all 0.35s ease-in-out;
}
.link-deco::after {
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='12' height='12'%3E%3Cpath d='M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z'%3E%3C/path%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.35s ease-in-out;
}
.link:hover .link-deco::before {
  margin-left: 2.5rem;
  width: 0;
}
.link:hover .link-deco::after {
  transform: rotate(180deg);
}

/* === GRID === */
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
  padding-inline: 0.5rem;
  margin-inline: auto;
  max-width: 1320px;
}
@media screen and (min-width: 360px) {
  .grid {
    padding-inline: 1rem;
  }
}
@media screen and (min-width: 576px) {
  .grid {
    grid-template-columns: repeat(10, 1fr);
  }
}
@media screen and (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
    padding-inline: 3rem;
  }
}
@media screen and (min-width: 1200px) {
  .grid {
    padding-inline: 0.5rem;
  }
}

.container {
  max-width: 1320px;
  padding-inline: 0.5rem;
}
@media screen and (min-width: 360px) {
  .container {
    padding-inline: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .container {
    padding-inline: 3rem;
  }
}

header {
  top: 0;
  left: 0;
}

/* === ACCORDION === */
.accordion-header {
  cursor: pointer;
  padding: 1rem;
}
@media screen and (min-width: 1200px) {
  .accordion-header {
    padding: 2.5rem;
  }
}
.accordion-header:focus-visible {
  outline: none;
}
.accordion-header--title {
  display: block;
  position: relative;
}
.accordion-header--title::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1rem;
  height: 1rem;
  background-image: url("../img/icons/icon-accordion.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform-origin: 50% 50%;
  transition: all 0.35s ease-in-out;
}
@media screen and (min-width: 1200px) {
  .accordion-header--title::after {
    top: -0.375rem;
    width: 2rem;
    height: 2rem;
  }
}
.accordion-header.active .accordion-header--title::after {
  transform: rotate(-180deg);
}
.accordion-content {
  height: 0;
  overflow: hidden;
}

/* === FORM-CHECK === */
.form-check {
  position: relative;
}
.form-check-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.form-check-input:checked + .form-check-label::before {
  background: #FFFFFF;
  border-color: #000000;
}
.form-check-label {
  display: flex;
  padding-left: 1.5rem;
  position: relative;
  cursor: pointer;
  transition: all 0.35s ease-in-out;
}
.form-check-label::before {
  content: "";
  position: absolute;
  top: 0.375rem;
  left: 0;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  border: 0.063rem solid #000000;
  background: white;
}
.form-check-label::after {
  content: "";
  position: absolute;
  top: 0.5rem;
  left: 0.125rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #000000;
  transform: scale(0);
  transition: all 0.35s ease-in-out;
}
.form-check-input:checked + .form-check-label {
  font-weight: 700;
}
.form-check-input:checked + .form-check-label::after {
  transform: scale(1);
}

/* === BTN-CHECK === */
.btn-check-input {
  display: none;
}
.btn-check-input + label {
  display: block;
  width: max-content;
  padding: 0.25rem 0.75rem;
  border: 0.125rem solid #7FF9EB;
  border-radius: 0.75rem;
  color: #7FF9EB;
  transition: all 0.35s ease-in-out;
  cursor: pointer;
}
.btn-check-input + label:hover {
  background-color: #7FF9EB;
  color: #5A5974;
}
.btn-check-input:checked + label {
  background-color: #7FF9EB;
  color: #5A5974;
}

/* === LIST-ITEM MARKER RESET ===*/
.list-item::marker {
  content: none;
}

.obvious-version {
  background-color: #180636;
}
.obvious-version .main-breadcrumbs span span {
  color: #C1C1C1;
}
.obvious-version .main-breadcrumbs span span:not(:last-child) {
  opacity: 0.5;
}
.obvious-version .main-breadcrumbs span span:not(:last-child)::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath d='M13.1714 12.0007L8.22168 7.05093L9.63589 5.63672L15.9999 12.0007L9.63589 18.3646L8.22168 16.9504L13.1714 12.0007Z' fill='rgba(193,193,193,1)'%3E%3C/path%3E%3C/svg%3E");
}
.obvious-version .block-hero--2, .obvious-version .block-hero--3 {
  background-color: #180636;
}
.obvious-version .block-hero--2 .content .grid__content .uppertitle, .obvious-version .block-hero--3 .content .grid__content .uppertitle {
  color: #FFFFFF;
}
.obvious-version .block-hero--2 .content .grid__content .title, .obvious-version .block-hero--3 .content .grid__content .title {
  color: #FFFFFF;
}
.obvious-version .block-hero--2 .content .grid__content .editor, .obvious-version .block-hero--3 .content .grid__content .editor {
  color: #FFFFFF;
}
.obvious-version .block-hero--2 .content .grid__content .editor a, .obvious-version .block-hero--3 .content .grid__content .editor a {
  color: #FFFFFF;
}
.obvious-version .title-media-description,
.obvious-version .title-description-media {
  background-color: #180636;
  color: #FFFFFF;
}
.obvious-version .title-media-description .content .grid__title .title,
.obvious-version .title-description-media .content .grid__title .title {
  color: #FFFFFF;
}
.obvious-version .title-media-description .content .grid hr,
.obvious-version .title-description-media .content .grid hr {
  color: #FFFFFF;
}
.obvious-version .title-media-description .content .grid__content .editor,
.obvious-version .title-description-media .content .grid__content .editor {
  color: #FFFFFF;
}
.obvious-version .title-media-description .content .grid__content .editor a,
.obvious-version .title-description-media .content .grid__content .editor a {
  color: #FFFFFF;
}
.obvious-version .gallery {
  background-color: #180636;
}
.obvious-version .gallery__content .swiper-pagination-bullet {
  background-color: #C1C1C1;
}
.obvious-version .gallery__content .swiper-pagination-bullet-active {
  background-color: #3E3CFF;
}
.obvious-version .gallery__content .swiper-prev svg .circle, .obvious-version .gallery__content .swiper-next svg .circle {
  stroke: #FFFFFF;
}
.obvious-version .gallery__content .swiper-prev svg .arrow, .obvious-version .gallery__content .swiper-next svg .arrow {
  fill: #FFFFFF;
}
.obvious-version .gallery__content .swiper-prev:hover svg .circle, .obvious-version .gallery__content .swiper-next:hover svg .circle {
  stroke: #3E3CFF;
}
.obvious-version .gallery__content .swiper-prev:hover svg .arrow, .obvious-version .gallery__content .swiper-next:hover svg .arrow {
  fill: #3E3CFF;
}
.obvious-version .media {
  background-color: #180636;
}
.obvious-version .title-editor-full-width {
  background-color: rgb(28, 21, 52);
}
.obvious-version .title-editor-full-width .title {
  color: #FFFFFF;
}
.obvious-version .title-editor-full-width .editor {
  color: #FFFFFF;
}
.obvious-version .title-editor-full-width .editor a {
  color: #FFFFFF;
}
.obvious-version .dual-col-title-text-img {
  background-color: #180636;
}
.obvious-version .dual-col-title-text-img .title {
  color: #FFFFFF;
}
.obvious-version .dual-col-title-text-img .editor {
  color: #FFFFFF;
}
.obvious-version .dual-col-title-text-img .editor a {
  color: #FFFFFF;
}
.obvious-version .title-dual-col-wysiwyg {
  background-color: #180636;
}
.obvious-version .title-dual-col-wysiwyg .title {
  color: #FFFFFF;
}
.obvious-version .title-dual-col-wysiwyg .editor {
  color: #FFFFFF;
}
.obvious-version .title-dual-col-wysiwyg .editor a {
  color: #FFFFFF;
}
.obvious-version .numbers-legend .item {
  color: #FFFFFF;
}
.obvious-version .full-width-editor .blue-dark-bg {
  color: #FFFFFF;
}

/* Barre de défilement principale (à l'extérieur) */
::-webkit-scrollbar {
  width: 3px; /* Largeur de la barre de défilement */
}

/* Barre de défilement principale (à l'intérieur) */
::-webkit-scrollbar-thumb {
  @apply bg-red;
}

/* Couleur de fond de la zone de défilement */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Couleur de fond */
}

/* Couleur de fond de la zone de défilement */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Couleur de fond */
}

/* Barre de défilement horizontale (à l'extérieur) */
::-webkit-scrollbar-horizontal {
  height: 12px; /* Hauteur de la barre de défilement horizontale */
}

/* Barre de défilement horizontale (à l'intérieur) */
::-webkit-scrollbar-thumb:horizontal {
  @apply bg-blue;
}

.block-hero--1 {
  background-color: #ECF2FE;
}
.block-hero--1 .content {
  position: relative;
}
.block-hero--1 .content .deco--mobile {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: 16/9;
}
@media screen and (min-width: 1200px) {
  .block-hero--1 .content .deco--mobile {
    display: none;
  }
}
.block-hero--1 .content .deco--mobile img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
}
.block-hero--1 .content .deco--desktop {
  display: none;
}
@media screen and (min-width: 1200px) {
  .block-hero--1 .content .deco--desktop {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  .block-hero--1 .content .deco--desktop img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: right;
  }
}
.block-hero--1 .content .spacer {
  aspect-ratio: 16/7;
  grid-column: span 4;
}
@media screen and (min-width: 576px) {
  .block-hero--1 .content .spacer {
    grid-column: span 12;
  }
}
@media screen and (min-width: 1200px) {
  .block-hero--1 .content .spacer {
    display: none;
  }
}
.block-hero--1 .content .grid {
  position: relative;
}
.block-hero--1 .content .grid .breadcrumb {
  grid-column: 1/span 8;
}
.block-hero--1 .content .grid__content {
  grid-column: span 4;
  padding-block: 5rem 7.125rem;
}
@media screen and (min-width: 576px) {
  .block-hero--1 .content .grid__content {
    grid-column: span 7;
  }
}
@media screen and (min-width: 768px) {
  .block-hero--1 .content .grid__content {
    grid-column: span 8;
    padding-block: 4rem 8.125rem;
  }
}
@media screen and (min-width: 1400px) {
  .block-hero--1 .content .grid__content {
    grid-column: span 7;
    padding-block: 8rem 9.75rem;
  }
}
.block-hero--1 .content .grid__content .uppertitle {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}
@media screen and (min-width: 1200px) {
  .block-hero--1 .content .grid__content .uppertitle {
    font-size: 20px;
    margin-bottom: 2rem;
  }
}
.block-hero--1 .content .grid__content .title {
  font-size: 36px;
  line-height: 0.9;
  margin-block: 0 2rem;
  position: relative;
}
@media screen and (min-width: 768px) {
  .block-hero--1 .content .grid__content .title {
    font-size: 72px;
  }
}
@media screen and (min-width: 1200px) {
  .block-hero--1 .content .grid__content .title {
    font-size: 100px;
    margin-bottom: 3rem;
  }
}
.block-hero--1 .content .grid__content .title .font-sans-serif {
  font-weight: 400;
  color: #FF1435;
}
.block-hero--1 .content .grid__content .title .font-serif, .block-hero--1 .content .grid__content .title .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) em, .block-hero--1 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) .title em {
  font-weight: 400;
  font-style: italic;
}
.block-hero--1 .content .grid__content .title > *:last-child {
  position: relative;
}
.block-hero--1 .content .grid__content .title > *:last-child::after {
  content: "_";
  display: inline;
  font-family: "Spline Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #FF1435;
}
.block-hero--1 .content .grid__content .action {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
}
@media screen and (min-width: 1200px) {
  .block-hero--1 .content .grid__content .action {
    margin-top: 3rem;
    flex-direction: row;
  }
}
.block-hero--1 .content .grid__content .action .btn {
  max-width: 100%;
}
.block-hero--1 .content .grid__content .wysiwyg-hero {
  font-size: 14px;
}
@media screen and (min-width: 1200px) {
  .block-hero--1 .content .grid__content .wysiwyg-hero {
    font-size: 20px;
  }
}
.block-hero--1 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) {
  font-size: 36px;
  line-height: 0.9;
  margin-block: 0 2rem;
  position: relative;
  font-weight: 400;
  color: #FF1435;
}
@media screen and (min-width: 768px) {
  .block-hero--1 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) {
    font-size: 72px;
  }
}
@media screen and (min-width: 1200px) {
  .block-hero--1 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) {
    font-size: 100px;
    margin-bottom: 3rem;
  }
}
.block-hero--1 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6)::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
  margin-left: -1rem;
}
.block-hero--1 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) em {
  font-weight: 400;
  font-style: italic;
  color: #000000;
}
.block-hero--1__ressources {
  margin-block: -4.125rem 3rem;
  position: relative;
  z-index: 10;
}
@media screen and (min-width: 1200px) {
  .block-hero--1__ressources {
    margin-block: -5.75rem 8rem;
  }
}
.block-hero--1__ressources .content .grid {
  gap: 0;
}
.block-hero--1__ressources .content .grid .post-item {
  grid-column: 1/-1;
  border-radius: 0.75rem;
  border: 0.063rem solid #DADBE0;
  border-bottom: none;
  background-color: #FFFFFF;
  overflow: hidden;
}
@media screen and (min-width: 576px) {
  .block-hero--1__ressources .content .grid .post-item {
    border-right: none;
    border-bottom: 0.063rem solid #DADBE0;
  }
}
@media screen and (min-width: 768px) {
  .block-hero--1__ressources .content .grid .post-item {
    grid-column: 2/span 10;
  }
}
@media screen and (min-width: 1000px) {
  .block-hero--1__ressources .content .grid .post-item {
    grid-column: 2/span 10;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
  }
}
.block-hero--1__ressources .content .grid .post-item .thumb {
  display: none;
}
@media screen and (min-width: 1000px) {
  .block-hero--1__ressources .content .grid .post-item .thumb {
    display: block;
    aspect-ratio: 16/9;
    height: 100%;
    max-width: 40%;
  }
  .block-hero--1__ressources .content .grid .post-item .thumb img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.block-hero--1__ressources .content .grid .post-item .content {
  padding: 1rem;
}
@media screen and (min-width: 1200px) {
  .block-hero--1__ressources .content .grid .post-item .content {
    padding-inline: 2rem;
  }
}
.block-hero--1__ressources .content .grid .post-item .content__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.block-hero--1__ressources .content .grid .post-item .content__header .post-type {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
  position: relative;
}
.block-hero--1__ressources .content .grid .post-item .content__header .post-type::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.block-hero--1__ressources .content .grid .post-item .content__header .cat {
  padding: 0.25rem 0.5rem;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  color: #3E3CFF;
  border: 0.063rem solid #3E3CFF;
  border-radius: 0.5rem;
}
.block-hero--1__ressources .content .grid .post-item .content .title {
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  margin-block: 1.5rem;
}
@media screen and (min-width: 1200px) {
  .block-hero--1__ressources .content .grid .post-item .content .title {
    font-size: 24px;
  }
}
.block-hero--1__ressources .content .grid .post-item .content .excerpt {
  margin-block: 1.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.block-hero--1__ressources .content .grid .post-item:hover .link-deco::before {
  margin-left: 2.5rem;
  width: 0;
}
.block-hero--1__ressources .content .grid .post-item:hover .link-deco::after {
  transform: rotate(180deg);
}
.block-hero--1__ressources .content .grid .cta-download {
  grid-column: span 4;
  background-color: #E8E8FD;
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
  border: 0.063rem solid #DADBE0;
  border-left: none;
  border-top: none;
  transition: all 0.35s ease-in-out;
}
@media screen and (min-width: 576px) {
  .block-hero--1__ressources .content .grid .cta-download {
    grid-column: span 3;
    border-top: 0.063rem solid #DADBE0;
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0;
    display: flex;
    align-items: flex-end;
  }
}
@media screen and (min-width: 768px) {
  .block-hero--1__ressources .content .grid .cta-download {
    grid-column: span 4;
  }
}
@media screen and (min-width: 1200px) {
  .block-hero--1__ressources .content .grid .cta-download {
    grid-column: span 2;
    border: none;
    position: relative;
  }
}
.block-hero--1__ressources .content .grid .cta-download:hover {
  background-color: #D6D5FF;
}
.block-hero--1__ressources .content .grid .cta-download__link {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 1.5rem 1rem;
}
@media screen and (min-width: 576px) {
  .block-hero--1__ressources .content .grid .cta-download__link {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}
@media screen and (min-width: 1200px) {
  .block-hero--1__ressources .content .grid .cta-download__link {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 4rem);
    height: calc(100% - 2rem);
    padding: 1rem 2rem;
  }
}
.block-hero--1__ressources .content .grid .cta-download__icon {
  width: 30px;
}
.block-hero--1__ressources .content .grid .cta-download__title {
  font-size: 14px;
  font-weight: 700;
  width: calc(100% - 30px - 1.5rem);
}
@media screen and (min-width: 576px) {
  .block-hero--1__ressources .content .grid .cta-download__title {
    font-size: 16px;
    width: 100%;
  }
}
.block-hero--2 {
  background-color: #ECF2FE;
  overflow: hidden;
  position: relative;
}
.block-hero--2 .content {
  position: relative;
  z-index: 10;
}
.block-hero--2 .content.has-deco-mobile {
  margin-top: 8rem;
}
@media screen and (min-width: 768px) {
  .block-hero--2 .content.has-deco-mobile {
    margin-top: 0;
  }
}
.block-hero--2 .content .grid {
  position: relative;
}
.block-hero--2 .content .grid__content {
  grid-column: span 4;
  padding-top: 3rem;
}
@media screen and (min-width: 576px) {
  .block-hero--2 .content .grid__content {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 768px) {
  .block-hero--2 .content .grid__content {
    grid-column: span 6;
    grid-row: 1/-1;
    padding-block: 4rem;
  }
}
@media screen and (min-width: 1200px) {
  .block-hero--2 .content .grid__content {
    grid-column: span 7;
    grid-row: 1/-1;
    padding-block: 8rem;
  }
}
.block-hero--2 .content .grid__content .uppertitle {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}
@media screen and (min-width: 1200px) {
  .block-hero--2 .content .grid__content .uppertitle {
    font-size: 20px;
    margin-bottom: 2rem;
  }
}
.block-hero--2 .content .grid__content .title {
  font-size: 36px;
  line-height: 0.9;
  margin-block: 0 2rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .block-hero--2 .content .grid__content .title {
    font-size: 64px;
    margin-bottom: 3rem;
    padding-right: 4rem;
  }
}
.block-hero--2 .content .grid__content .title .font-serif, .block-hero--2 .content .grid__content .title .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) em, .block-hero--2 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) .title em {
  font-weight: 400;
  font-style: italic;
}
.block-hero--2 .content .grid__content .title .font-sans-serif {
  font-weight: 400;
  line-height: 1;
}
.block-hero--2 .content .grid__content .title > *:last-child {
  position: relative;
}
.block-hero--2 .content .grid__content .title > *:last-child::after {
  content: "_";
  display: inline;
  font-family: "Spline Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #FF1435;
}
.block-hero--2 .content .grid__content .wysiwyg-hero {
  font-size: 14px;
}
@media screen and (min-width: 1200px) {
  .block-hero--2 .content .grid__content .wysiwyg-hero {
    font-size: 18px;
  }
}
.block-hero--2 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) {
  font-size: 36px;
  line-height: 0.9;
  margin-block: 0 2rem;
  position: relative;
  font-weight: 400;
  color: #FF1435;
}
@media screen and (min-width: 1200px) {
  .block-hero--2 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) {
    font-size: 64px;
    margin-bottom: 3rem;
    padding-right: 4rem;
  }
}
.block-hero--2 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6)::after {
  content: "_";
  display: inline-block;
  font-weight: 400;
  color: #FF1435;
}
.block-hero--2 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) em {
  font-weight: 400;
  font-style: italic;
  color: #000000;
}
.block-hero--2 .content .grid__content .action {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
}
@media screen and (min-width: 1200px) {
  .block-hero--2 .content .grid__content .action {
    margin-top: 3rem;
    flex-direction: row;
  }
}
.block-hero--2 .content .grid__content .action .btn {
  max-width: 100%;
}
.block-hero--2 .content .grid__links {
  grid-column: span 4;
  padding-bottom: 2rem;
}
@media screen and (min-width: 576px) {
  .block-hero--2 .content .grid__links {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 768px) {
  .block-hero--2 .content .grid__links {
    grid-column: 8/span 5;
    grid-row: 1/-1;
    align-self: center;
    padding-bottom: 0;
    position: relative;
    z-index: 10;
  }
}
@media screen and (min-width: 1200px) {
  .block-hero--2 .content .grid__links {
    grid-column: 9/span 4;
  }
}
.block-hero--2 .content .grid__links__inner .link-item {
  background-color: #FFFFFF;
  border-radius: 0.75rem;
  overflow: hidden;
}
.block-hero--2 .content .grid__links__inner .link-item:not(:last-child) {
  margin-bottom: 1rem;
}
.block-hero--2 .content .grid__links__inner .link-item .link-item__content {
  position: relative;
  align-self: center;
}
.block-hero--2 .content .grid__links__inner .link-item .link-item__content .link {
  max-width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.block-hero--2 .content .grid__links__inner .link-item .link-item__content .link-title {
  width: calc(100% - 2rem - 1.875rem);
  font-size: 14px;
  font-weight: 700;
}
@media screen and (min-width: 1200px) {
  .block-hero--2 .content .grid__links__inner .link-item .link-item__content .link-title {
    font-size: 18px;
  }
}
.block-hero--2 .content .grid__links__inner .link-item .link-item__content .link-icon {
  width: 1.875rem;
  height: 1.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.block-hero--2 .content .grid__links__inner .link-item .link-item__content .link-icon svg .circle {
  fill: none;
  stroke: #180636;
  transition: all 0.35s ease-in-out;
}
.block-hero--2 .content .grid__links__inner .link-item .link-item__content .link-icon svg .arrow {
  fill: #180636;
  transition: all 0.35s ease-in-out;
}
.block-hero--2 .content .grid__links__inner .link-item .link-item__content .link:hover .link-icon svg .circle {
  stroke: #3E3CFF;
}
.block-hero--2 .content .grid__links__inner .link-item .link-item__content .link:hover .link-icon svg .arrow {
  fill: #3E3CFF;
}
.block-hero--2 .content .grid__links__inner .link-item.has-img {
  display: flex;
  flex-wrap: wrap;
}
.block-hero--2 .content .grid__links__inner .link-item.has-img .thumb {
  display: none;
}
@media screen and (min-width: 1200px) {
  .block-hero--2 .content .grid__links__inner .link-item.has-img .thumb {
    display: block;
    flex: 1;
    aspect-ratio: 16/10;
    overflow: hidden;
  }
}
.block-hero--2 .content .grid__links__inner .link-item.has-img .thumb picture, .block-hero--2 .content .grid__links__inner .link-item.has-img .thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.block-hero--2 .content .grid__links__inner .link-item.has-img .link-item__content {
  flex: 1.25;
  padding: 1rem;
}
@media screen and (min-width: 1200px) {
  .block-hero--2 .content .grid__links__inner .link-item.has-img .link-item__content {
    padding-right: 2rem;
  }
}
.block-hero--2 .content .grid__links__inner .link-item.has-img .link-item__content .link {
  width: 100%;
  padding: 0;
}
.block-hero--2 .content .grid__links__inner .link-item:not(.has-img) {
  background-color: #E8E8FD;
}
.block-hero--2 .content .grid__links__inner .link-item:not(.has-img) .link-item__content .link {
  padding: 2rem;
}
.block-hero--2 .deco--mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 768px) {
  .block-hero--2 .deco--mobile {
    display: none;
  }
}
.block-hero--2 .deco--mobile img {
  object-fit: cover;
  width: 100%;
}
.block-hero--2 .deco__desktop {
  display: none;
}
@media screen and (min-width: 768px) {
  .block-hero--2 .deco__desktop {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.block-hero--2 .deco__desktop img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.block-hero--3 {
  background-color: #ECF2FE;
  overflow: hidden;
  position: relative;
}
.block-hero--3 .content {
  position: relative;
  z-index: 10;
}
.block-hero--3 .content.has-deco-mobile {
  margin-top: 7rem;
}
@media screen and (min-width: 768px) {
  .block-hero--3 .content.has-deco-mobile {
    margin-top: 0;
  }
}
.block-hero--3 .content .grid {
  position: relative;
}
.block-hero--3 .content .grid__content {
  grid-column: span 4;
  padding-block: 12rem 2rem;
  padding-top: 3rem;
}
@media screen and (min-width: 576px) {
  .block-hero--3 .content .grid__content {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 768px) {
  .block-hero--3 .content .grid__content {
    grid-column: span 6;
    grid-row: 1/-1;
    padding-block: 4rem;
  }
}
@media screen and (min-width: 1200px) {
  .block-hero--3 .content .grid__content {
    grid-column: 1/span 7;
    grid-row: 1/-1;
    padding-block: 8rem;
  }
}
.block-hero--3 .content .grid__content .uppertitle {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}
@media screen and (min-width: 1200px) {
  .block-hero--3 .content .grid__content .uppertitle {
    font-size: 20px;
    margin-bottom: 2rem;
  }
}
.block-hero--3 .content .grid__content .title {
  font-size: 36px;
  line-height: 0.9;
  margin-block: 0 2rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .block-hero--3 .content .grid__content .title {
    font-size: 64px;
    margin-bottom: 3rem;
    padding-right: 4rem;
  }
}
.block-hero--3 .content .grid__content .title .font-serif, .block-hero--3 .content .grid__content .title .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) em, .block-hero--3 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) .title em {
  font-weight: 400;
  font-style: italic;
}
.block-hero--3 .content .grid__content .title .font-sans-serif {
  font-weight: 400;
  line-height: 1;
}
.block-hero--3 .content .grid__content .title > *:last-child {
  position: relative;
}
.block-hero--3 .content .grid__content .title > *:last-child::after {
  content: "_";
  display: inline;
  font-family: "Spline Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #FF1435;
}
.block-hero--3 .content .grid__content .wysiwyg-hero {
  font-size: 14px;
}
@media screen and (min-width: 1200px) {
  .block-hero--3 .content .grid__content .wysiwyg-hero {
    font-size: 18px;
  }
}
.block-hero--3 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) {
  font-size: 36px;
  line-height: 0.9;
  margin-block: 0 2rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .block-hero--3 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) {
    font-size: 64px;
    margin-bottom: 3rem;
    padding-right: 4rem;
  }
}
.block-hero--3 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6)::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.block-hero--3 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) em {
  font-weight: 400;
  font-style: italic;
  color: #000000;
}
.block-hero--3 .content .grid__content .action {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
}
@media screen and (min-width: 1200px) {
  .block-hero--3 .content .grid__content .action {
    margin-top: 3rem;
    flex-direction: row;
  }
}
.block-hero--3 .content .grid__content .action .btn {
  max-width: 100%;
}
.block-hero--3 .deco--mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 768px) {
  .block-hero--3 .deco--mobile {
    display: none;
  }
}
.block-hero--3 .deco--mobile img {
  object-fit: cover;
  width: 100%;
}
.block-hero--3 .deco--desktop {
  display: none;
}
@media screen and (min-width: 768px) {
  .block-hero--3 .deco--desktop {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.block-hero--3 .deco--desktop img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.block-hero--4 {
  background-color: #ECF2FE;
  overflow: hidden;
}
.block-hero--4 .content {
  position: relative;
}
@media screen and (min-width: 768px) {
  .block-hero--4 .content .deco--mobile {
    display: none;
  }
}
.block-hero--4 .content .deco--mobile img {
  object-fit: cover;
  aspect-ratio: 16/8;
  width: 100%;
  height: 100%;
}
.block-hero--4 .content .grid {
  position: relative;
}
.block-hero--4 .content .grid__content {
  grid-column: span 4;
  padding-block: 5rem 1rem;
}
@media screen and (min-width: 576px) {
  .block-hero--4 .content .grid__content {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 768px) {
  .block-hero--4 .content .grid__content {
    grid-column: span 6;
    grid-row: 1/-1;
    padding-block: 4rem;
  }
}
@media screen and (min-width: 1200px) {
  .block-hero--4 .content .grid__content {
    grid-column: 1/span 7;
    grid-row: 1/-1;
    padding-block: 8rem;
  }
}
@media screen and (min-width: 1400px) {
  .block-hero--4 .content .grid__content {
    grid-column: 1/span 8;
  }
}
.block-hero--4 .content .grid__content .uppertitle {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}
@media screen and (min-width: 1200px) {
  .block-hero--4 .content .grid__content .uppertitle {
    font-size: 20px;
    margin-bottom: 2rem;
  }
}
.block-hero--4 .content .grid__content .title {
  font-size: 36px;
  line-height: 0.9;
  margin-block: 0 2rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .block-hero--4 .content .grid__content .title {
    font-size: 64px;
    margin-bottom: 3rem;
    padding-right: 4rem;
  }
}
.block-hero--4 .content .grid__content .title .font-serif, .block-hero--4 .block-hero--5 .content .grid__content .title .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) em, .block-hero--5 .block-hero--4 .content .grid__content .title .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) em, .block-hero--4 .block-hero--5 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) .title em, .block-hero--5 .block-hero--4 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) .title em, .block-hero--4 .block-hero--1 .content .grid__content .title .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) em, .block-hero--1 .block-hero--4 .content .grid__content .title .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) em, .block-hero--4 .block-hero--1 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) .title em, .block-hero--1 .block-hero--4 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) .title em, .block-hero--4 .block-hero--2 .content .grid__content .title .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) em, .block-hero--2 .block-hero--4 .content .grid__content .title .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) em, .block-hero--4 .block-hero--2 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) .title em, .block-hero--2 .block-hero--4 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) .title em, .block-hero--4 .block-hero--3 .content .grid__content .title .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) em, .block-hero--3 .block-hero--4 .content .grid__content .title .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) em, .block-hero--4 .block-hero--3 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) .title em, .block-hero--3 .block-hero--4 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) .title em {
  font-weight: 400;
  font-style: italic;
}
.block-hero--4 .content .grid__content .title .font-sans-serif {
  font-weight: 400;
  line-height: 1;
}
.block-hero--4 .content .grid__content .title > *:last-child {
  position: relative;
}
.block-hero--4 .content .grid__content .title > *:last-child::after {
  content: "_";
  display: inline;
  font-family: "Spline Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #FF1435;
}
.block-hero--4 .content .grid__content .action {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
}
@media screen and (min-width: 1200px) {
  .block-hero--4 .content .grid__content .action {
    margin-top: 3rem;
    flex-direction: row;
  }
}
.block-hero--4 .content .grid__content .action .btn {
  max-width: 100%;
}
.block-hero--4 .content .grid__deco__desktop {
  display: none;
}
@media screen and (min-width: 768px) {
  .block-hero--4 .content .grid__deco__desktop {
    display: block;
    position: relative;
    grid-column: 9/span 4;
    grid-row: 1/-1;
  }
}
@media screen and (min-width: 768px) {
  .block-hero--4 .content .grid__deco__desktop .deco-mire {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    aspect-ratio: 1;
    background-image: url("../img/decos/deco-gatewatcher-mire-hero-4.png");
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
  }
}
.block-hero--5 {
  background-color: #ECF2FE;
  padding-block: 3rem;
  overflow: hidden;
  position: relative;
}
@media screen and (min-width: 768px) {
  .block-hero--5 {
    padding-block: 8rem;
  }
}
.block-hero--5 .content .grid__content {
  grid-column: span 4;
}
@media screen and (min-width: 576px) {
  .block-hero--5 .content .grid__content {
    grid-column: span 8;
  }
}
.block-hero--5 .content .grid__content .uppertitle {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}
@media screen and (min-width: 1200px) {
  .block-hero--5 .content .grid__content .uppertitle {
    font-size: 20px;
    margin-bottom: 2rem;
  }
}
.block-hero--5 .content .grid__content .title {
  font-size: 36px;
  font-weight: 400;
  line-height: 0.9;
  margin-block: 0 2rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .block-hero--5 .content .grid__content .title {
    font-size: 64px;
    margin-block: 0 3rem;
  }
}
.block-hero--5 .content .grid__content .title .font-sans-serif {
  font-weight: 400;
  line-height: 1;
}
.block-hero--5 .content .grid__content .title .font-serif, .block-hero--5 .content .grid__content .title .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) em, .block-hero--5 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) .title em {
  font-weight: 400;
  font-style: italic;
}
.block-hero--5 .content .grid__content .title > *:last-child {
  position: relative;
}
.block-hero--5 .content .grid__content .title > *:last-child::after {
  content: "_";
  display: inline;
  font-family: "Spline Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #FF1435;
}
.block-hero--5 .content .grid__content .wysiwyg-hero {
  font-size: 14px;
}
@media screen and (min-width: 1200px) {
  .block-hero--5 .content .grid__content .wysiwyg-hero {
    font-size: 18px;
  }
}
.block-hero--5 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) {
  font-size: 36px;
  font-weight: 400;
  line-height: 0.9;
  margin-block: 0 2rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .block-hero--5 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) {
    font-size: 64px;
    margin-block: 0 3rem;
  }
}
.block-hero--5 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6)::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.block-hero--5 .content .grid__content .wysiwyg-hero :is(h1, h2, h3, h4, h5, h6) em {
  font-weight: 400;
  font-style: italic;
  color: #000000;
}
.block-hero--5 .content .grid__content .action {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
}
@media screen and (min-width: 1200px) {
  .block-hero--5 .content .grid__content .action {
    margin-top: 3rem;
    flex-direction: row;
  }
}
.block-hero--5 .content .grid__content .action .btn {
  max-width: 100%;
}
.block-hero--5 .deco-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 1200px) {
  .block-hero--5 .deco-mobile {
    display: none;
  }
}
.block-hero--5 .deco-mobile img {
  object-fit: cover;
  width: 100%;
}
.block-hero--5 .deco-desktop {
  display: none;
}
@media screen and (min-width: 1200px) {
  .block-hero--5 .deco-desktop {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.block-hero--5 .deco-desktop img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.section--cta-about {
  margin-block: 3rem;
}
@media screen and (min-width: 1200px) {
  .section--cta-about {
    margin-block-start: 8rem;
    margin-block-end: 4rem;
  }
}
.section--cta-about .grid .grid--item {
  grid-column: span 4;
  margin-bottom: 1rem;
  position: relative;
}
@media screen and (min-width: 576px) {
  .section--cta-about .grid .grid--item {
    grid-column: 2/span 8;
    padding: 4rem 2rem 1rem;
  }
}
@media screen and (min-width: 768px) {
  .section--cta-about .grid .grid--item {
    grid-column: 3/span 8;
    padding-inline: 3rem;
  }
}
@media screen and (min-width: 1200px) {
  .section--cta-about .grid .grid--item {
    grid-column: span 4;
  }
}
.section--cta-about .grid .grid--item:not(:first-of-type)::before {
  content: "";
  display: block;
  margin-block: 1rem 3rem;
  width: 100%;
  height: 0.063rem;
  background-color: #180636;
}
@media screen and (min-width: 576px) {
  .section--cta-about .grid .grid--item::before {
    content: "";
    position: absolute;
    top: 1rem;
    left: 0;
    margin-block: 0 !important;
    width: 100%;
    height: 0.063rem;
    background-color: #180636;
  }
}
@media screen and (min-width: 576px) {
  .section--cta-about .grid .grid--item::after {
    content: "";
    position: absolute;
    top: 0;
    left: 1rem;
    width: 0.063rem;
    height: 100%;
    background-color: #180636;
  }
}
.section--cta-about .grid .grid--item__content .item--icon {
  width: 75px;
  aspect-ratio: 1;
  margin-bottom: 2rem;
}
.section--cta-about .grid .grid--item__content .item--icon img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.section--cta-about .grid .grid--item__content .item--title {
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
  margin-block: 2rem;
}
.section--cta-about .grid .grid--item__content .editor {
  color: #595B65;
}
.section--cta-about .action {
  display: flex;
  justify-content: center;
  margin-top: 3.125rem;
  padding-inline: 0.5rem;
}
@media screen and (min-width: 360px) {
  .section--cta-about .action {
    padding-inline: 1rem;
  }
}
.section--cta-about .action .btn {
  width: 100%;
  max-width: 100%;
}
@media screen and (min-width: 576px) {
  .section--cta-about .action .btn {
    width: max-content;
  }
}

.section--cta-solutions {
  padding-block: 3rem;
  background-color: #7FF9EB;
}
@media screen and (min-width: 1200px) {
  .section--cta-solutions {
    padding-block-start: 4rem;
    padding-block-end: 8rem;
    position: relative;
  }
}
.section--cta-solutions .bg-container {
  display: none;
}
@media screen and (min-width: 1200px) {
  .section--cta-solutions .bg-container {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.section--cta-solutions .bg-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.section--cta-solutions .content {
  position: relative;
  z-index: 10;
}
.section--cta-solutions .content .grid .title {
  grid-column: 1/-1;
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
  margin-block: 0 2.5rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .section--cta-solutions .content .grid .title {
    font-size: 40px;
    margin-bottom: 2.75rem;
  }
}
.section--cta-solutions .content .grid .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}

.solution-item {
  grid-column: span 4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 1.5rem 1.5rem;
  background-color: #FFFFFF;
  border: 0.063rem solid #DADBE0;
  border-radius: 0.75rem;
}
@media screen and (min-width: 576px) {
  .solution-item {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 768px) {
  .solution-item {
    grid-column: span 6;
  }
}
@media screen and (min-width: 1200px) {
  .solution-item {
    grid-column: span 4;
  }
}
.solution-item .icon {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.625rem;
}
.solution-item__content {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1rem;
}
.solution-item .baseline {
  font-weight: 400;
  margin-top: 0.625rem;
  width: calc(100% - 1rem);
}
@media screen and (min-width: 1200px) {
  .solution-item .baseline {
    margin-block: 1rem 0;
  }
}
.solution-item .arrow svg {
  fill: #FF1435;
}

/* === SECTION-CTA-PRODUCTS === */
.section--cta-products {
  padding-block: 3rem;
  background-color: #FAFAFA;
}
@media screen and (min-width: 1200px) {
  .section--cta-products {
    padding-block-start: 8rem;
    padding-block-end: 4rem;
  }
}
.section--cta-products .content .grid .title {
  grid-column: 1/-1;
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 2.5rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .section--cta-products .content .grid .title {
    font-size: 40px;
    margin-bottom: 2.75rem;
  }
}
.section--cta-products .content .grid .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}

.product-item {
  grid-column: span 4;
  border: 0.063rem solid #DADBE0;
  background-color: #FFFFFF;
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
  padding: 4.5rem 1.25rem;
}
@media screen and (min-width: 576px) {
  .product-item {
    grid-column: span 5;
  }
}
@media screen and (min-width: 768px) {
  .product-item {
    grid-column: span 6;
  }
}
@media screen and (min-width: 576px) {
  .product-item.large {
    grid-column: 1/-1;
  }
}
@media screen and (min-width: 768px) {
  .product-item.large {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
    align-items: center;
  }
}
.product-item .icon {
  width: 75px;
  height: 75px;
  margin-bottom: 2rem;
}
@media screen and (min-width: 1200px) {
  .product-item .icon {
    grid-column: span 2;
    width: 100px;
    height: 100px;
  }
}
.product-item .product-title {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 400;
  margin-block: 2rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .product-item .product-title {
    font-size: 30px;
  }
}
.product-item hr {
  display: block;
  width: 100%;
  height: 0.063rem;
  border: none;
  background-color: #180636;
  margin-block: 2rem;
}
.product-item .excerpt {
  font-size: 14px;
  font-weight: 400;
  margin-block: 2rem;
}
@media screen and (min-width: 768px) {
  .product-item .excerpt {
    grid-column: 3/span 8;
    margin-block: 0;
  }
}
@media screen and (min-width: 1200px) {
  .product-item .excerpt {
    grid-column: span 5;
    margin-block: 0;
  }
}
.product-item .action {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
}
@media screen and (min-width: 768px) {
  .product-item.large .icon {
    grid-column: span 2;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1200px) {
  .product-item.large .icon {
    grid-column: span 1;
  }
}
@media screen and (min-width: 768px) {
  .product-item.large .product-title {
    grid-column: 3/span 6;
    padding-right: 0;
    margin-block: 0;
  }
}
@media screen and (min-width: 1200px) {
  .product-item.large .product-title {
    grid-column: span 4;
    padding-right: 0;
    margin-block: 0;
    margin-left: 4rem;
  }
}
@media screen and (min-width: 768px) {
  .product-item.large .action {
    grid-column: 3/span 6;
    margin-top: 0;
  }
}
@media screen and (min-width: 1200px) {
  .product-item.large .action {
    grid-column: span 2;
    margin-top: 0;
    margin-inline: auto;
  }
}
.product-item.in-menu:last-child {
  grid-column: 1/-1;
}
.product-item.in-menu:last-child .product-title {
  width: 50%;
}
.product-item.in-menu:last-child .excerpt {
  width: 50%;
}
@media screen and (min-width: 768px) {
  .product-item.in-menu:last-child .icon {
    grid-column: span 2;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1200px) {
  .product-item.in-menu:last-child .icon {
    grid-column: span 1;
  }
}
@media screen and (min-width: 768px) {
  .product-item.in-menu:last-child .product-title {
    grid-column: 3/span 6;
    padding-right: 0;
    margin-block: 0;
  }
}
@media screen and (min-width: 1200px) {
  .product-item.in-menu:last-child .product-title {
    grid-column: span 4;
    padding-right: 0;
    margin-block: 0;
    margin-left: 4rem;
  }
}
@media screen and (min-width: 768px) {
  .product-item.in-menu:last-child .action {
    grid-column: 3/span 6;
    margin-top: 0;
  }
}
@media screen and (min-width: 1200px) {
  .product-item.in-menu:last-child .action {
    grid-column: span 2;
    margin-top: 0;
    margin-inline: auto;
  }
}

/* === SECTION-CERTIFICATIONS === */
.section--certifications {
  padding-block: 3rem 0;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .section--certifications {
    padding-block: 4rem 8rem;
  }
}
.section--certifications::after {
  content: "";
  position: absolute;
  top: -3rem;
  left: 0;
  width: 100%;
  height: calc(100% + 6rem);
  background-color: #FFFFFF;
  z-index: -1;
}
@media screen and (min-width: 1200px) {
  .section--certifications::after {
    top: -8rem;
    height: calc(100% + 16rem);
  }
}
.section--certifications .content .title {
  grid-column: 1/-1;
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 2.5rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .section--certifications .content .title {
    font-size: 40px;
    margin-bottom: 2.75rem;
  }
}
.section--certifications .content .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.section--certifications .content .certification-item {
  position: relative;
  grid-column: 1/-1;
}
@media screen and (min-width: 576px) {
  .section--certifications .content .certification-item {
    grid-column: 3/span 6;
    padding: 2rem 2rem 0;
  }
}
@media screen and (min-width: 768px) {
  .section--certifications .content .certification-item {
    padding: 3rem 3rem 0;
    grid-column: span 6;
  }
}
@media screen and (min-width: 576px) {
  .section--certifications .content .certification-item:not(:last-child) {
    margin-bottom: 2rem;
  }
}
@media screen and (min-width: 1200px) {
  .section--certifications .content .certification-item:not(:last-child) {
    margin-bottom: 0;
  }
}
.section--certifications .content .certification-item:not(:last-child)::after {
  content: "";
  display: block;
  width: 100%;
  height: 0.063rem;
  margin-block: 2.25rem;
  background-color: #180636;
}
@media screen and (min-width: 576px) {
  .section--certifications .content .certification-item:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 1rem;
    left: 0;
    width: 100%;
    height: 0.063rem;
    margin-block: 0 !important;
    background-color: #180636;
  }
}
@media screen and (min-width: 576px) {
  .section--certifications .content .certification-item:last-child::after {
    content: "";
    position: absolute;
    top: 1rem;
    left: 0;
    width: 100%;
    height: 0.063rem;
    margin-block: 0 !important;
    background-color: #180636;
  }
}
@media screen and (min-width: 576px) {
  .section--certifications .content .certification-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 1rem;
    height: 100%;
    width: 0.063rem;
    margin-block: 0 !important;
    background-color: #180636;
  }
}
.section--certifications .content .certification-item .logo {
  margin-bottom: 2rem;
}
.section--certifications .content .certification-item .logo img {
  object-fit: contain;
  max-width: 150px;
}
@media screen and (min-width: 1200px) {
  .section--certifications .content .certification-item .logo img {
    width: auto;
    max-height: 65px;
  }
}
.section--certifications .content .certification-item .item-title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 2rem;
}
@media screen and (min-width: 768px) {
  .section--certifications.count-2 .certification-item {
    grid-column: span 6;
  }
  .section--certifications.count-2 .certification-item:not(:last-child) {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1000px) {
  .section--certifications.count-3 .certification-item {
    grid-column: span 4;
  }
  .section--certifications.count-3 .certification-item:not(:last-child) {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1200px) {
  .section--certifications.count-4 .certification-item {
    grid-column: span 3;
  }
  .section--certifications.count-4 .certification-item:not(:last-child) {
    margin-bottom: 0;
  }
}

/* === SECTION-CTA-PARTNERS === */
.section--cta-partners {
  margin-top: 3rem;
  position: relative;
}
@media screen and (min-width: 576px) {
  .section--cta-partners {
    padding-bottom: 3rem;
  }
}
@media screen and (min-width: 1200px) {
  .section--cta-partners {
    margin-top: 8rem;
    padding-bottom: 12rem;
  }
}
.section--cta-partners::after {
  content: "";
  position: absolute;
  top: -3rem;
  left: 0;
  width: 100%;
  height: calc(100% + 6rem);
  background-color: #FFFFFF;
  z-index: -1;
}
@media screen and (min-width: 1200px) {
  .section--cta-partners::after {
    top: -8rem;
    height: calc(100% + 16rem);
  }
}
@media screen and (min-width: 576px) {
  .section--cta-partners .map {
    position: absolute;
    top: 3rem;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: calc(100% - 3rem);
  }
}
@media screen and (min-width: 1200px) {
  .section--cta-partners .map {
    top: 8rem;
    height: calc(100% - 8rem);
    max-width: 1440px;
  }
}
.section--cta-partners .map svg {
  width: 100% !important;
  height: 100% !important;
}
.section--cta-partners .map svg .continent[data-href] {
  cursor: pointer;
}
.section--cta-partners .map svg .continent .cls-16 {
  fill: transparent;
  transition: all 0.35s ease-in-out;
}
.section--cta-partners .map svg .continent .cls-16:hover {
  fill: #8cfbf7;
}
@media screen and (min-width: 576px) {
  .section--cta-partners .map img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.section--cta-partners .content {
  margin-top: -1.5rem;
}
@media screen and (min-width: 576px) {
  .section--cta-partners .content {
    margin-top: 0;
  }
}
.section--cta-partners .content .grid .partners__content {
  position: relative;
  z-index: 10;
  grid-column: span 4;
  padding: 1.5rem;
  border-radius: 0.75rem;
  background-color: #ECF2FE;
}
@media screen and (min-width: 576px) {
  .section--cta-partners .content .grid .partners__content {
    grid-column: span 6;
  }
}
@media screen and (min-width: 768px) {
  .section--cta-partners .content .grid .partners__content {
    grid-column: span 7;
  }
}
@media screen and (min-width: 1200px) {
  .section--cta-partners .content .grid .partners__content {
    grid-column: span 5;
    padding: 2.5rem 4rem 2.5rem 2.5rem;
  }
}
.section--cta-partners .content .grid .partners__content .title {
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 2rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .section--cta-partners .content .grid .partners__content .title {
    font-size: 40px;
  }
}
.section--cta-partners .content .grid .partners__content .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.section--cta-partners .content .grid .partners__content .editor {
  font-size: 14px;
  font-weight: 400;
  margin-block: 2rem;
}
.section--cta-partners .content .grid .partners__content .links--list .link-item {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}
.section--cta-partners .content .grid .partners__content .links--list .link-item--title {
  font-size: 14px;
  font-weight: 700;
  width: calc(100% - 2.5rem);
}
.section--cta-partners .content .grid .partners__content .links--list .link-item--icon {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section--cta-partners .content .grid .partners__content .links--list .link-item--icon svg {
  fill: #180636;
}
.section--cta-partners .content .grid .partners__content .links--list .link-item:not(:last-of-type) {
  border-bottom: 0.063rem solid #3E3CFF;
}
.section--cta-partners .content .grid .partners__content .links--list .link-item:hover {
  background-color: #3E3CFF;
}
.section--cta-partners .content .grid .partners__content .links--list .link-item:hover .link-item--title {
  color: #FFFFFF;
}
.section--cta-partners .content .grid .partners__content .links--list .link-item:hover .link-item--icon svg {
  fill: #FFFFFF;
}

/* === SECTION-CTA-LAB === */
.section--cta-lab {
  padding-block: 3rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .section--cta-lab {
    padding-block: 8rem;
  }
}
.section--cta-lab::after {
  content: "";
  position: absolute;
  top: -3rem;
  left: 0;
  width: 100%;
  height: calc(100% + 6rem);
  background-color: #FFFFFF;
  z-index: -1;
}
@media screen and (min-width: 1200px) {
  .section--cta-lab::after {
    top: -8rem;
    height: calc(100% + 16rem);
  }
}
.section--cta-lab .content .grid {
  row-gap: 1rem;
}
.section--cta-lab .content .grid .content__header {
  grid-column: 1/-1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
}
@media screen and (min-width: 576px) {
  .section--cta-lab .content .grid .content__header {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
.section--cta-lab .content .grid .content__header .title {
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
}
@media screen and (min-width: 768px) {
  .section--cta-lab .content .grid .content__header .title {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1200px) {
  .section--cta-lab .content .grid .content__header .title {
    font-size: 40px;
  }
}
.section--cta-lab .content .grid .content__header .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.section--cta-lab .content .grid .content__header .action .btn {
  max-width: 100%;
}
@media screen and (min-width: 768px) {
  .section--cta-lab .content .grid .content__header .action .btn {
    max-width: max-content;
  }
}
.section--cta-lab .content .grid .post-item {
  grid-column: span 4;
}
@media screen and (min-width: 576px) {
  .section--cta-lab .content .grid .post-item {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 768px) {
  .section--cta-lab .content .grid .post-item {
    grid-column: 3/span 8;
  }
}
@media screen and (min-width: 1200px) {
  .section--cta-lab .content .grid .post-item {
    grid-column: span 4;
  }
}
.section--cta-lab .content .grid .post-item .thumb {
  aspect-ratio: 16/10;
}

.section {
  /* === SECTION-CTA-PRESS === */
}
.section--cta-press {
  padding-block: 3rem;
  background-color: #FAFAFA;
  /*  + .section--cta-demo {
    margin-top: calc($spacer-xl * -3);
    @media screen and (min-width: 1200px) {
      margin-top: calc($spacer-xxl * -4);
    }
  } */
}
@media screen and (min-width: 1200px) {
  .section--cta-press {
    padding-block: 8rem;
  }
}
.section--cta-press .content .grid .content__header {
  grid-column: 1/-1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
}
@media screen and (min-width: 576px) {
  .section--cta-press .content .grid .content__header {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
.section--cta-press .content .grid .content__header .title {
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
}
@media screen and (min-width: 768px) {
  .section--cta-press .content .grid .content__header .title {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1200px) {
  .section--cta-press .content .grid .content__header .title {
    font-size: 40px;
  }
}
.section--cta-press .content .grid .content__header .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.section--cta-press .content .grid .content__header .action .btn {
  max-width: 100%;
}
@media screen and (min-width: 768px) {
  .section--cta-press .content .grid .content__header .action .btn {
    max-width: max-content;
  }
}
.section--cta-press .content .grid .post-item {
  grid-column: span 4;
}
@media screen and (min-width: 576px) {
  .section--cta-press .content .grid .post-item {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 768px) {
  .section--cta-press .content .grid .post-item {
    grid-column: 3/span 8;
  }
}
@media screen and (min-width: 1200px) {
  .section--cta-press .content .grid .post-item {
    grid-column: span 4;
  }
}
.section--cta-press .content .grid .post-item:not(.featured-post) {
  display: flex;
  flex-direction: column;
}
.section--cta-press .content .grid .post-item .thumb img {
  aspect-ratio: 16/10;
}
.section--cta-press .content .grid .post-item__content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.section--cta-press .content .grid .post-item__content--inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.section--cta-press .content .grid .post-item__content--inner .action {
  margin-top: auto;
}
.section--cta-press .content .grid .post-item.featured-post .post-item__content {
  height: auto;
}

.section {
  /* === SECTION-CTA-RESSOURCES === */
}
.section--cta-ressources {
  padding-block: 3rem;
  background-color: #ECF2FE;
  /*  + .section--cta-press {
          margin-top: calc($spacer-xl * -1.5);
          @media screen and (min-width: 1200px) {
              margin-top: calc($spacer-xxl * -2);
          }
      } */
}
@media screen and (min-width: 1200px) {
  .section--cta-ressources {
    padding-block: 8rem;
  }
}
.section--cta-ressources .content .grid .content__header {
  grid-column: 1/-1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
}
@media screen and (min-width: 576px) {
  .section--cta-ressources .content .grid .content__header {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
.section--cta-ressources .content .grid .content__header .title {
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
}
@media screen and (min-width: 768px) {
  .section--cta-ressources .content .grid .content__header .title {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1200px) {
  .section--cta-ressources .content .grid .content__header .title {
    font-size: 40px;
  }
}
.section--cta-ressources .content .grid .content__header .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.section--cta-ressources .content .grid .content__header .action .btn {
  max-width: 100%;
}
@media screen and (min-width: 768px) {
  .section--cta-ressources .content .grid .content__header .action .btn {
    max-width: max-content;
  }
}
.section--cta-ressources .content .grid .post-item {
  grid-column: span 4;
}
@media screen and (min-width: 576px) {
  .section--cta-ressources .content .grid .post-item {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 768px) {
  .section--cta-ressources .content .grid .post-item {
    grid-column: 3/span 8;
  }
}
@media screen and (min-width: 1200px) {
  .section--cta-ressources .content .grid .post-item {
    grid-column: span 4;
  }
}
.section--cta-ressources .content .grid .post-item:not(.featured-post) {
  display: flex;
  flex-direction: column;
}
.section--cta-ressources .content .grid .post-item .thumb img {
  aspect-ratio: 16/10;
}
.section--cta-ressources .content .grid .post-item__content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.section--cta-ressources .content .grid .post-item__content--inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.section--cta-ressources .content .grid .post-item__content--inner .action {
  margin-top: auto;
}
.section--cta-ressources .content .grid .post-item.featured-post .post-item__content {
  height: auto;
}

/* === SECTION-CTA-DEMO === */
.section--cta-demo {
  padding-block: 3rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .section--cta-demo {
    padding-block: 8rem;
  }
}
.section--cta-demo.gray-bg {
  background-color: #FAFAFA;
}
.section--cta-demo.white-bg {
  background-color: #FFFFFF;
}
.section--cta-demo.blue_light-bg {
  background-color: #ECF2FE;
}
.section--cta-demo.blue_dark-bg {
  background-color: #180636;
}
.section--cta-demo::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: #180636;
}
.section--cta-demo .content {
  position: relative;
  z-index: 10;
}
.section--cta-demo .content .grid {
  position: relative;
}
.section--cta-demo .content .grid .background {
  display: none;
}
@media screen and (min-width: 1200px) {
  .section--cta-demo .content .grid .background {
    display: block;
    position: absolute;
    top: 0;
    left: 0.5rem;
    width: calc(100% - 6rem);
    height: 100%;
    border-radius: 0.75rem;
  }
  .section--cta-demo .content .grid .background.blue {
    background-color: #7FF9EB;
  }
  .section--cta-demo .content .grid .background.red {
    background-color: #FF1435;
  }
}
.section--cta-demo .content .grid .inner-content {
  grid-column: span 4;
  padding: 1rem;
  border-radius: 0.75rem;
  position: relative;
  z-index: 10;
}
@media screen and (min-width: 576px) {
  .section--cta-demo .content .grid .inner-content {
    grid-column: 2/span 8;
    padding: 4rem;
  }
}
@media screen and (min-width: 768px) {
  .section--cta-demo .content .grid .inner-content {
    grid-column: 3/span 8;
  }
}
@media screen and (min-width: 1200px) {
  .section--cta-demo .content .grid .inner-content {
    grid-column: 1/span 7;
    grid-row: 1/-1;
    padding: 4rem 0 4rem 4rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    align-self: center;
  }
}
.section--cta-demo .content .grid .inner-content .icon {
  display: none;
}
@media screen and (min-width: 1200px) {
  .section--cta-demo .content .grid .inner-content .icon {
    display: block;
    width: 60px;
    height: 60px;
    margin-bottom: 1rem;
  }
}
.section--cta-demo .content .grid .inner-content .icon img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.section--cta-demo .content .grid .inner-content .title {
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
  margin-block: 0 1rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .section--cta-demo .content .grid .inner-content .title {
    font-size: 40px;
  }
}
.section--cta-demo .content .grid .inner-content .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.section--cta-demo .content .grid .inner-content .editor {
  margin-block: 1rem;
}
@media screen and (min-width: 1200px) {
  .section--cta-demo .content .grid .inner-content .editor {
    max-width: calc(50% + 1rem);
  }
}
.section--cta-demo .content .grid .inner-content .action {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media screen and (min-width: 1200px) {
  .section--cta-demo .content .grid .inner-content .action {
    flex-direction: row;
  }
}
.section--cta-demo .content .grid .inner-content .action .btn {
  max-width: 100%;
}
@media screen and (min-width: 1200px) {
  .section--cta-demo .content .grid .inner-content .action .btn {
    max-width: max-content;
  }
}
.section--cta-demo .content .grid .inner-content.bg-blue {
  background-color: #7FF9EB;
}
@media screen and (min-width: 1200px) {
  .section--cta-demo .content .grid .inner-content.bg-blue {
    background-color: transparent;
  }
}
.section--cta-demo .content .grid .inner-content.bg-red {
  background-color: #FF1435;
  color: #FFFFFF;
}
@media screen and (min-width: 1200px) {
  .section--cta-demo .content .grid .inner-content.bg-red {
    background-color: transparent;
  }
}
.section--cta-demo .content .grid .inner-content.bg-red .title::after {
  color: #FFFFFF;
}
.section--cta-demo .content .grid .deco {
  display: none;
}
@media screen and (min-width: 1200px) {
  .section--cta-demo .content .grid .deco {
    display: block;
    grid-column: 5/span 8;
    grid-row: 1/-1;
    position: relative;
    margin-left: -1.125rem;
    border-radius: 0 0.75rem 0.75rem 0;
  }
}
.section--cta-demo .content .grid .deco img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 0 0.75rem 0.75rem 0;
}

.number-list-block {
  padding-block: 3rem;
  background-color: #ECF2FE;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .number-list-block {
    padding-block: 8rem;
  }
}
.number-list-block .bg-container {
  display: none;
}
@media screen and (min-width: 768px) {
  .number-list-block .bg-container {
    display: block;
    position: absolute;
    top: calc(6rem + var(--numberListHeight));
    left: 0;
    width: 100%;
    height: calc(100% - (6rem + var(--numberListHeight)));
  }
}
@media screen and (min-width: 1200px) {
  .number-list-block .bg-container {
    top: calc(16rem + var(--numberListHeight));
    height: calc(100% - (16rem + var(--numberListHeight)));
  }
}
.number-list-block .bg-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.number-list-block .content {
  position: relative;
  z-index: 10;
}
.number-list-block .content .grid {
  row-gap: 2rem;
}
.number-list-block .number-list__title {
  grid-column: span 4;
}
@media screen and (min-width: 576px) {
  .number-list-block .number-list__title {
    grid-column: 2/span 6;
  }
}
@media screen and (min-width: 768px) {
  .number-list-block .number-list__title {
    grid-column: span 5;
  }
}
@media screen and (min-width: 1200px) {
  .number-list-block .number-list__title {
    grid-column: span 4;
  }
}
.number-list-block .number-list__title .title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  margin-block: 0 1rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .number-list-block .number-list__title .title {
    font-size: 40px;
    margin-block: 0 2rem;
  }
}
.number-list-block .number-list__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.number-list-block .number-list__title .action {
  margin-top: 1rem;
}
@media screen and (min-width: 1200px) {
  .number-list-block .number-list__title .action {
    margin-top: 2rem;
  }
}
.number-list-block .number-list__title .action .btn {
  max-width: 100%;
}
@media screen and (min-width: 576px) {
  .number-list-block .number-list__title .action .btn {
    max-width: max-content;
  }
}
.number-list-block .number-list__content {
  grid-column: span 4;
}
@media screen and (min-width: 576px) {
  .number-list-block .number-list__content {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 576px) {
  .number-list-block .number-list__content {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 768px) {
  .number-list-block .number-list__content {
    grid-column: span 7;
  }
}
@media screen and (min-width: 1200px) {
  .number-list-block .number-list__content {
    grid-column: 6/span 7;
  }
}
.number-list-block .number-list__content .list-item {
  padding: 1rem;
  background-color: #FFFFFF;
  border: 0.063rem solid #DADBE0;
  border-radius: 0.75rem;
}
@media screen and (min-width: 1200px) {
  .number-list-block .number-list__content .list-item {
    padding: 2.5rem;
  }
}
.number-list-block .number-list__content .list-item:not(:last-child) {
  margin-bottom: 1rem;
}
@media screen and (min-width: 1200px) {
  .number-list-block .number-list__content .list-item {
    display: flex;
    align-items: flex-start;
    gap: 2.5rem;
  }
}
.number-list-block .number-list__content .list-item__number {
  font-size: 32px;
  font-weight: 400;
  color: #FF1435;
  margin-bottom: 1rem;
}
@media screen and (min-width: 1200px) {
  .number-list-block .number-list__content .list-item__number {
    font-size: 40px;
  }
}
.number-list-block .number-list__content .list-item__content .uppertitle {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .number-list-block .number-list__content .list-item__content .uppertitle {
    font-size: 20px;
  }
}
.number-list-block .number-list__content .list-item__content .uppertitle::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.number-list-block .number-list__content .list-item__content .title {
  font-size: 14px;
  margin-block: 1rem;
}
@media screen and (min-width: 1200px) {
  .number-list-block .number-list__content .list-item__content .title {
    font-size: 18px;
  }
}
.number-list-block .number-list__content .list-item__content .editor {
  font-size: 14px;
  font-weight: 400;
  color: #2B2B2B;
}

.study-case-block {
  padding-block: 3rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .study-case-block {
    padding-block: 8rem;
  }
}
.study-case-block .bg-container {
  display: none;
}
@media screen and (min-width: 768px) {
  .study-case-block .bg-container {
    display: block;
    position: absolute;
    top: calc(6rem + var(--studyCaseHeight));
    left: 0;
    width: 100%;
    height: calc(100% - (6rem + var(--studyCaseHeight)));
  }
}
@media screen and (min-width: 1200px) {
  .study-case-block .bg-container {
    top: calc(16rem + var(--studyCaseHeight));
    height: calc(100% - (16rem + var(--studyCaseHeight)));
  }
}
.study-case-block .bg-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.study-case-block .content {
  position: relative;
  z-index: 10;
}
.study-case-block .content .grid {
  row-gap: 2rem;
}
.study-case-block .study-case__title {
  grid-column: span 4;
}
@media screen and (min-width: 576px) {
  .study-case-block .study-case__title {
    grid-column: 2/span 6;
  }
}
@media screen and (min-width: 768px) {
  .study-case-block .study-case__title {
    grid-column: span 5;
  }
}
@media screen and (min-width: 1200px) {
  .study-case-block .study-case__title {
    grid-column: span 4;
  }
}
.study-case-block .study-case__title .title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  margin-block: 0 1rem;
}
@media screen and (min-width: 1200px) {
  .study-case-block .study-case__title .title {
    font-size: 40px;
    margin-block: 0 2rem;
  }
}
.study-case-block .study-case__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.study-case-block .study-case__title .editor {
  font-size: 14px;
  margin-top: 1rem;
}
@media screen and (min-width: 1200px) {
  .study-case-block .study-case__title .editor {
    margin-top: 2rem;
  }
}
.study-case-block .study-case__title .action {
  margin-top: 1rem;
}
@media screen and (min-width: 1200px) {
  .study-case-block .study-case__title .action {
    margin-top: 2rem;
  }
}
.study-case-block .study-case__title .action .btn {
  max-width: 100%;
}
@media screen and (min-width: 576px) {
  .study-case-block .study-case__title .action .btn {
    max-width: max-content;
  }
}
.study-case-block .study-case__content {
  grid-column: span 4;
}
@media screen and (min-width: 576px) {
  .study-case-block .study-case__content {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 576px) {
  .study-case-block .study-case__content {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 768px) {
  .study-case-block .study-case__content {
    grid-column: span 7;
  }
}
@media screen and (min-width: 1200px) {
  .study-case-block .study-case__content {
    grid-column: 6/span 7;
  }
}
.study-case-block .study-case__content .list-item {
  background-color: #FFFFFF;
  border: 0.063rem solid #DADBE0;
  border-radius: 0.75rem;
}
.study-case-block .study-case__content .list-item:not(:last-child) {
  margin-bottom: 1rem;
}
.study-case-block .study-case__content .list-item__content {
  position: relative;
  padding: 1rem;
}
@media screen and (min-width: 1200px) {
  .study-case-block .study-case__content .list-item__content {
    padding: 2.5rem;
  }
}
.study-case-block .study-case__content .list-item__content .item-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.study-case-block .study-case__content .list-item__content .item-title {
  font-size: 14px;
  font-weight: 700;
  margin-block: 0;
}
@media screen and (min-width: 1200px) {
  .study-case-block .study-case__content .list-item__content .item-title {
    font-size: 18px;
  }
}
.study-case-block .study-case__content .list-item__content .editor {
  font-weight: 700;
}
.study-case-block .study-case__content .list-item__content .action {
  margin-top: 1rem;
}

.level-block {
  padding-block: 3rem;
  background-color: #ECF2FE;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .level-block {
    padding-block: 8rem;
  }
}
.level-block .bg-container {
  display: none;
}
@media screen and (min-width: 768px) {
  .level-block .bg-container {
    display: block;
    position: absolute;
    top: calc(6rem + var(--levelBlockHeight));
    left: 0;
    width: 100%;
    height: calc(100% - (6rem + var(--levelBlockHeight)));
  }
}
@media screen and (min-width: 1200px) {
  .level-block .bg-container {
    top: calc(16rem + var(--levelBlockHeight));
    height: calc(100% - (16rem + var(--levelBlockHeight)));
  }
}
.level-block .bg-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.level-block .content {
  position: relative;
  z-index: 10;
}
.level-block .content .grid {
  row-gap: 2rem;
}
.level-block .level__title {
  grid-column: span 4;
}
@media screen and (min-width: 576px) {
  .level-block .level__title {
    grid-column: 2/span 6;
  }
}
@media screen and (min-width: 768px) {
  .level-block .level__title {
    grid-column: span 5;
  }
}
@media screen and (min-width: 1200px) {
  .level-block .level__title {
    grid-column: span 4;
  }
}
.level-block .level__title .title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  margin-block: 0 1rem;
}
@media screen and (min-width: 1200px) {
  .level-block .level__title .title {
    font-size: 40px;
    margin-block: 0 2rem;
  }
}
.level-block .level__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.level-block .level__title .editor {
  font-size: 14px;
  margin-top: 1rem;
}
@media screen and (min-width: 1200px) {
  .level-block .level__title .editor {
    margin-top: 2rem;
  }
}
.level-block .level__title .action {
  margin-top: 1rem;
}
@media screen and (min-width: 1200px) {
  .level-block .level__title .action {
    margin-top: 2rem;
  }
}
.level-block .level__title .action .btn {
  max-width: 100%;
}
@media screen and (min-width: 576px) {
  .level-block .level__title .action .btn {
    max-width: max-content;
  }
}
.level-block .level__content {
  grid-column: span 4;
}
@media screen and (min-width: 576px) {
  .level-block .level__content {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 576px) {
  .level-block .level__content {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 768px) {
  .level-block .level__content {
    grid-column: span 7;
  }
}
@media screen and (min-width: 1200px) {
  .level-block .level__content {
    grid-column: 6/span 7;
  }
}
.level-block .level__content .list-item {
  background-color: #FFFFFF;
  border: 0.063rem solid #DADBE0;
  border-radius: 0.75rem;
}
.level-block .level__content .list-item:not(:last-child) {
  margin-bottom: 1rem;
}
.level-block .level__content .list-item__content .item-title {
  font-size: 14px;
  font-weight: 700;
  margin-block: 0;
}
@media screen and (min-width: 1200px) {
  .level-block .level__content .list-item__content .item-title {
    font-size: 18px;
  }
}
.level-block .level__content .list-item__content .editor {
  padding: 0 1rem 1rem;
}
@media screen and (min-width: 1200px) {
  .level-block .level__content .list-item__content .editor {
    padding: 0 2.5rem 2.5rem;
  }
}
.level-block .level__content .list-item__content .action {
  margin-top: 1rem;
}

.solution-highlight-block {
  padding-block: 3rem;
  background-color: #ECF2FE;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .solution-highlight-block {
    padding-block: 8rem;
  }
}
.solution-highlight-block .bg-container {
  display: none;
}
@media screen and (min-width: 768px) {
  .solution-highlight-block .bg-container {
    display: block;
    position: absolute;
    top: calc(6rem + var(--solutionHighlightHeight));
    left: 0;
    width: 100%;
    height: calc(100% - (6rem + var(--solutionHighlightHeight)));
  }
}
@media screen and (min-width: 1200px) {
  .solution-highlight-block .bg-container {
    top: calc(16rem + var(--solutionHighlightHeight));
    height: calc(100% - (16rem + var(--solutionHighlightHeight)));
  }
}
.solution-highlight-block .bg-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.solution-highlight-block .content {
  position: relative;
  z-index: 10;
}
.solution-highlight-block .content .grid {
  row-gap: 2rem;
}
.solution-highlight-block .solution-highlight__title {
  grid-column: span 4;
}
@media screen and (min-width: 576px) {
  .solution-highlight-block .solution-highlight__title {
    grid-column: 2/span 6;
  }
}
@media screen and (min-width: 768px) {
  .solution-highlight-block .solution-highlight__title {
    grid-column: span 5;
  }
}
@media screen and (min-width: 1200px) {
  .solution-highlight-block .solution-highlight__title {
    grid-column: span 4;
  }
}
.solution-highlight-block .solution-highlight__title .title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  margin-block: 0 1rem;
}
@media screen and (min-width: 1200px) {
  .solution-highlight-block .solution-highlight__title .title {
    font-size: 40px;
    margin-block: 0 2rem;
  }
}
.solution-highlight-block .solution-highlight__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.solution-highlight-block .solution-highlight__title .editor {
  font-size: 14px;
  margin-top: 1rem;
}
@media screen and (min-width: 1200px) {
  .solution-highlight-block .solution-highlight__title .editor {
    margin-top: 2rem;
  }
}
.solution-highlight-block .solution-highlight__title .action {
  margin-top: 1rem;
}
@media screen and (min-width: 1200px) {
  .solution-highlight-block .solution-highlight__title .action {
    margin-top: 2rem;
  }
}
.solution-highlight-block .solution-highlight__title .action .btn {
  max-width: 100%;
}
@media screen and (min-width: 576px) {
  .solution-highlight-block .solution-highlight__title .action .btn {
    max-width: max-content;
  }
}
.solution-highlight-block .solution-highlight__content {
  grid-column: span 4;
}
@media screen and (min-width: 576px) {
  .solution-highlight-block .solution-highlight__content {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 576px) {
  .solution-highlight-block .solution-highlight__content {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 768px) {
  .solution-highlight-block .solution-highlight__content {
    grid-column: span 7;
  }
}
@media screen and (min-width: 1200px) {
  .solution-highlight-block .solution-highlight__content {
    grid-column: 6/span 7;
  }
}
.solution-highlight-block .solution-highlight__content .list-item {
  padding: 1rem;
  background-color: #FFFFFF;
  border: 0.063rem solid #DADBE0;
  border-radius: 0.75rem;
}
@media screen and (min-width: 1200px) {
  .solution-highlight-block .solution-highlight__content .list-item {
    padding: 2.5rem;
  }
}
.solution-highlight-block .solution-highlight__content .list-item:not(:last-child) {
  margin-bottom: 1rem;
}
@media screen and (min-width: 1200px) {
  .solution-highlight-block .solution-highlight__content .list-item {
    display: flex;
    align-items: flex-start;
    gap: 2.5rem;
  }
}
.solution-highlight-block .solution-highlight__content .list-item__content .item-title {
  font-size: 14px;
  font-weight: 700;
  margin-block: 0 1rem;
}
@media screen and (min-width: 1200px) {
  .solution-highlight-block .solution-highlight__content .list-item__content .item-title {
    font-size: 18px;
  }
}
.solution-highlight-block .solution-highlight__content .list-item__content .action {
  margin-top: 1rem;
}

.quote-block {
  padding-block: 3rem;
  background-color: #22296C;
  color: #FFFFFF;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .quote-block {
    padding-block: 4rem;
  }
}
.quote-block::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  background-image: url("../img/decos/deco-gatewatcher-mire-claim-mobile.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@media screen and (min-width: 576px) {
  .quote-block::after {
    background-image: url("../img/decos/deco-gatewatcher-mire-claim-desktop.svg");
  }
}
.quote-block .content {
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;
}
@media screen and (min-width: 576px) {
  .quote-block .content {
    aspect-ratio: 16/9;
  }
}
@media screen and (min-width: 1200px) {
  .quote-block .content {
    aspect-ratio: 16/4;
  }
}
.quote-block .content .grid {
  height: 100%;
  align-items: center;
  position: relative;
  z-index: 20;
}
.quote-block .content .grid::before {
  content: "";
  position: absolute;
  top: 2rem;
  left: 2rem;
  width: 15%;
  aspect-ratio: 1;
  background-image: url("../img/decos/deco-gatewatcher-quote-left.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (min-width: 576px) {
  .quote-block .content .grid::before {
    width: 10%;
  }
}
@media screen and (min-width: 1200px) {
  .quote-block .content .grid::before {
    top: 0;
    left: 0.75rem;
    width: 8.33333333%;
  }
}
@media screen and (min-width: 1400px) {
  .quote-block .content .grid::before {
    left: 0;
  }
}
@media screen and (max-width: 400px) {
  .quote-block .content .grid::before {
    top: -2rem;
  }
}
.quote-block .content .grid::after {
  content: "";
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  width: 15%;
  aspect-ratio: 1;
  background-image: url("../img/decos/deco-gatewatcher-quote-right.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (min-width: 576px) {
  .quote-block .content .grid::after {
    width: 10%;
  }
}
@media screen and (min-width: 1200px) {
  .quote-block .content .grid::after {
    right: 0.75rem;
    bottom: 0;
    width: 8.33333333%;
  }
}
@media screen and (min-width: 1400px) {
  .quote-block .content .grid::after {
    right: 0;
  }
}
@media screen and (max-width: 400px) {
  .quote-block .content .grid::after {
    bottom: -2rem;
  }
}
.quote-block .content .grid .quote__content {
  grid-column: span 4;
}
@media screen and (min-width: 576px) {
  .quote-block .content .grid .quote__content {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 768px) {
  .quote-block .content .grid .quote__content {
    grid-column: 2/span 10;
  }
}
.quote-block .content .grid .quote__content .text {
  font-family: "ivypresto-display", serif;
  font-size: 25px;
  font-style: italic;
  text-align: center;
}
@media screen and (min-width: 1200px) {
  .quote-block .content .grid .quote__content .text {
    font-size: 50px;
    line-height: 60px;
  }
}
.quote-block .content .grid .quote__content .author {
  text-align: center;
  margin-top: 50px;
}
.quote-block .content .grid .quote__content .author > * {
  font-family: "Spline Sans", sans-serif;
  display: block;
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
}
@media screen and (min-width: 1200px) {
  .quote-block .content .grid .quote__content .author > * {
    font-size: 24px;
    line-height: 29px;
  }
}
.quote-block .content .grid .quote__content .author > *:first-child {
  font-weight: 400;
}

.testimonials-block {
  padding-block: 3rem;
  background-color: #ECF2FE;
}
@media screen and (min-width: 1200px) {
  .testimonials-block {
    padding-block: 0;
  }
}
.testimonials-block .content .grid .testimonials__content {
  grid-column: span 4;
}
@media screen and (min-width: 576px) {
  .testimonials-block .content .grid .testimonials__content {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 768px) {
  .testimonials-block .content .grid .testimonials__content {
    grid-column: 3/span 8;
  }
}
@media screen and (min-width: 1200px) {
  .testimonials-block .content .grid .testimonials__content {
    grid-column: span 4;
    padding-block: 8rem;
    grid-column: span 7;
  }
}
.testimonials-block .content .grid .testimonials__content > .title {
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
  margin-block: 0 2.5rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .testimonials-block .content .grid .testimonials__content > .title {
    font-size: 40px;
    margin-block: 0 2.75rem;
  }
}
.testimonials-block .content .grid .testimonials__content > .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.testimonials-block .content .grid .testimonials__content .swiper-slide {
  opacity: 0 !important;
}
.testimonials-block .content .grid .testimonials__content .swiper-slide-active {
  opacity: 1 !important;
}
.testimonials-block .content .grid .testimonials__content .swiper__navigation {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 2rem;
}
@media screen and (min-width: 768px) {
  .testimonials-block .content .grid .testimonials__content .swiper__navigation {
    justify-content: center;
  }
}
.testimonials-block .content .grid .testimonials__content .swiper-pagination {
  position: initial;
  transform: none;
  width: max-content;
}
.testimonials-block .content .grid .testimonials__content .swiper-pagination-bullet {
  background-color: #C1C1C1;
  opacity: 1;
}
.testimonials-block .content .grid .testimonials__content .swiper-pagination-bullet-active {
  background-color: #3E3CFF;
}
.testimonials-block .content .grid .testimonials__content .swiper-navigation {
  display: flex;
  align-items: center;
}
.testimonials-block .content .grid .testimonials__content .swiper-prev, .testimonials-block .content .grid .testimonials__content .swiper-next {
  position: initial;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.testimonials-block .content .grid .testimonials__content .swiper-prev svg .circle, .testimonials-block .content .grid .testimonials__content .swiper-next svg .circle {
  fill: none;
  stroke: #180636;
  transition: all 0.35s ease-in-out;
}
.testimonials-block .content .grid .testimonials__content .swiper-prev svg .arrow, .testimonials-block .content .grid .testimonials__content .swiper-next svg .arrow {
  fill: #180636;
  transition: all 0.35s ease-in-out;
}
.testimonials-block .content .grid .testimonials__content .swiper-prev:hover svg .circle, .testimonials-block .content .grid .testimonials__content .swiper-next:hover svg .circle {
  fill: none;
  stroke: #3E3CFF;
}
.testimonials-block .content .grid .testimonials__content .swiper-prev:hover svg .arrow, .testimonials-block .content .grid .testimonials__content .swiper-next:hover svg .arrow {
  fill: #3E3CFF;
}
.testimonials-block .content .grid .testimonials__deco {
  display: none;
}
@media screen and (min-width: 1200px) {
  .testimonials-block .content .grid .testimonials__deco {
    display: block;
    grid-column: 9/span 4;
  }
  .testimonials-block .content .grid .testimonials__deco .img-container {
    background-color: #FFFFFF;
    height: 100%;
    width: calc(100% + 3rem);
  }
}
@media screen and (min-width: 1200px) and (min-width: 1440px) {
  .testimonials-block .content .grid .testimonials__deco .img-container {
    width: calc(100% + 3.75rem);
  }
}
@media screen and (min-width: 1200px) and (min-width: 1680px) {
  .testimonials-block .content .grid .testimonials__deco .img-container {
    width: auto;
    aspect-ratio: 1;
  }
}
@media screen and (min-width: 1200px) {
  .testimonials-block .content .grid .testimonials__deco .img-container img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.description-image {
  padding-block: 3rem;
  background-color: #E8E8FD;
}
@media screen and (min-width: 1200px) {
  .description-image {
    padding-block: 8rem;
  }
}
.description-image .content .grid {
  row-gap: 2rem;
}
.description-image__title {
  grid-column: 1/-1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
@media screen and (min-width: 576px) {
  .description-image__title {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 768px) {
  .description-image__title {
    grid-column: 2/span 10;
  }
}
@media screen and (min-width: 1200px) {
  .description-image__title {
    grid-column: 1/-1;
  }
}
@media screen and (min-width: 1200px) {
  .description-image__title {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
.description-image__title .title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  margin-block: 0;
}
@media screen and (min-width: 1200px) {
  .description-image__title .title {
    font-size: 40px;
  }
}
.description-image__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.description-image__title .action .btn {
  max-width: 100%;
}
@media screen and (min-width: 576px) {
  .description-image__title .action .btn {
    max-width: max-content;
  }
}
.description-image__content {
  grid-column: 1/-1;
}
@media screen and (min-width: 576px) {
  .description-image__content {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 768px) {
  .description-image__content {
    grid-column: 2/span 10;
  }
}
@media screen and (min-width: 1200px) {
  .description-image__content {
    grid-column: 1/-1;
  }
}
.description-image__content .item:not(:last-child) {
  margin-bottom: 2rem;
}
@media screen and (min-width: 1200px) {
  .description-image__content .item:not(:last-child) {
    margin-bottom: 4rem;
  }
}
@media screen and (min-width: 1200px) {
  .description-image__content .item {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
    align-items: center;
  }
}
.description-image__content .item__image {
  border-radius: 0.75rem 0.75rem 0 0;
  aspect-ratio: 4/3;
  overflow: hidden;
}
@media screen and (min-width: 1200px) {
  .description-image__content .item__image {
    border-radius: 0.75rem;
    aspect-ratio: 16/11;
  }
}
.description-image__content .item__image .img-container {
  height: 100%;
}
.description-image__content .item__image .img-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.description-image__content .item__content {
  background-color: #FFFFFF;
  border-radius: 0 0 0.75rem 0.75rem;
  padding: 1rem;
}
@media screen and (min-width: 768px) {
  .description-image__content .item__content {
    padding: 2rem;
  }
}
@media screen and (min-width: 1200px) {
  .description-image__content .item__content {
    border-radius: 0.75rem;
  }
}
.description-image__content .item__content .title {
  font-size: 14px;
  font-weight: 700;
  margin-block: 0 1rem;
}
@media screen and (min-width: 1200px) {
  .description-image__content .item__content .title {
    font-size: 32px;
  }
}
.description-image__content .item__content__inner .icon {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.description-image__content .item__content__inner.has-icon {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media screen and (min-width: 768px) {
  .description-image__content .item__content__inner.has-icon {
    flex-direction: row;
  }
}
@media screen and (min-width: 768px) {
  .description-image__content .item__content__inner.has-icon .editor {
    width: calc(100% - 35px - 1rem);
  }
}
@media screen and (min-width: 1200px) {
  .description-image__content .item.img-left .item__image {
    grid-column: 1/span 7;
    grid-row: 1/-1;
    position: relative;
    z-index: 10;
  }
  .description-image__content .item.img-left .item__content {
    grid-column: 7/span 6;
    grid-row: 1/-1;
    position: relative;
    z-index: 20;
  }
}
@media screen and (min-width: 1200px) {
  .description-image__content .item.img-right .item__image {
    grid-column: 6/span 7;
    grid-row: 1/-1;
    position: relative;
    z-index: 10;
  }
  .description-image__content .item.img-right .item__content {
    grid-column: 1/span 6;
    grid-row: 1/-1;
    position: relative;
    z-index: 20;
  }
}

.section-posts {
  padding-block: 3rem;
}
@media screen and (min-width: 1200px) {
  .section-posts {
    padding-block: 8rem;
  }
}
.section-posts .content .grid .posts__search {
  grid-column: 1/-1;
  margin-bottom: 1rem;
}
@media screen and (min-width: 1200px) {
  .section-posts .content .grid .posts__search {
    margin-bottom: 2rem;
  }
}
.section-posts .content .grid .posts__search .search {
  position: relative;
}
.section-posts .content .grid .posts__search .search input {
  width: 100%;
  border: 0.063rem solid #DADBE0;
  border-radius: 0.75rem;
  padding: 1rem 5rem 1rem 1.5rem;
  font-size: 18px;
  transition: all 0.35s ease-in-out;
}
@media screen and (min-width: 1200px) {
  .section-posts .content .grid .posts__search .search input {
    font-size: 20px;
  }
}
.section-posts .content .grid .posts__search .search input:focus {
  border-color: #D6D5FF;
  border-width: 0.063rem;
}
.section-posts .content .grid .posts__search .search input:focus-visible {
  outline: none;
}
.section-posts .content .grid .posts__search .search input::placeholder {
  color: #180636;
  opacity: 0.4;
  font-size: 18px;
}
@media screen and (min-width: 1200px) {
  .section-posts .content .grid .posts__search .search input::placeholder {
    font-size: 24px;
  }
}
.section-posts .content .grid .posts__search .search button {
  position: absolute;
  top: 0.063rem;
  right: 0.063rem;
  height: calc(100% - 0.126rem);
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 0 0.6375rem 0.6375rem 0;
  background: #D6D5FF;
  transition: all 0.35s ease-in-out;
}
.section-posts .content .grid .posts__search .search button svg {
  fill: #180636;
}
.section-posts .content .grid .posts__filters {
  grid-column: 1/-1;
  margin-bottom: 1rem;
}
@media screen and (min-width: 1200px) {
  .section-posts .content .grid .posts__filters {
    grid-column: span 3;
    margin-bottom: 0;
  }
}
.section-posts .content .grid .posts__filters__inner .accordion-item {
  background-color: #FFFFFF;
  border: 0.063rem solid #DADBE0;
  border-radius: 0.75rem;
}
.section-posts .content .grid .posts__filters__inner .accordion-header--title {
  font-size: 18px;
  font-weight: 700;
}
.section-posts .content .grid .posts__filters__inner .accordion-header--title::after {
  width: 1.875rem;
  height: 1.875rem;
}
@media screen and (min-width: 1200px) {
  .section-posts .content .grid .posts__filters__inner .accordion-content {
    padding: 2rem;
  }
}
.section-posts .content .grid .posts__filters__inner .accordion-content__inner {
  padding: 0 1rem 1rem;
}
@media screen and (min-width: 1200px) {
  .section-posts .content .grid .posts__filters__inner .accordion-content__inner {
    padding: 0;
  }
}
.section-posts .content .grid .posts__filters__inner .accordion-content__inner .cat-content:not(:last-child) {
  margin-bottom: 1rem;
}
.section-posts .content .grid .posts__filters__inner .accordion-content__inner .cat-content .cat-title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 1rem;
}
@media screen and (min-width: 1200px) {
  .section-posts .content .grid .posts__filters__inner .accordion-content__inner .cat-content .cat-title {
    font-size: 18px;
  }
}
@media screen and (min-width: 1200px) {
  .section-posts .content .grid .posts__filters__inner .accordion-content__inner .cat-content {
    padding: 2rem;
    border-radius: 0.75rem;
  }
}
.section-posts .content .grid .posts__filters__inner .form-check:not(:last-child) {
  margin-bottom: 1rem;
}
.section-posts .content .grid .posts__filters__inner .cat-content.tags .wrap-taxonomy {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.section-posts .content .grid .posts__content {
  grid-column: 1/-1;
}
@media screen and (min-width: 1200px) {
  .section-posts .content .grid .posts__content {
    grid-column: 4/span 9;
  }
}
@media screen and (min-width: 1200px) {
  .section-posts .content .grid .posts__content .list-posts {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}
.section-posts .content .grid .posts__content .list-posts .post-item {
  grid-column: span 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.section-posts .content .grid .posts__content .list-posts .post-item:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 1200px) {
  .section-posts .content .grid .posts__content .list-posts .post-item {
    margin-bottom: 0;
  }
}
.section-posts .content .grid .posts__content .list-posts .post-item .thumb {
  position: relative;
}
.section-posts .content .grid .posts__content .list-posts .post-item .thumb a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.section-posts .content .grid .posts__content .list-posts .highlight-post {
  margin-bottom: 1rem;
}
@media screen and (min-width: 1200px) {
  .section-posts .content .grid .posts__content .list-posts .highlight-post {
    grid-column: 1/-1;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1200px) {
  .section-posts .content .grid .posts__content .list-posts .highlight-post .post-item {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
  }
}
.section-posts .content .grid .posts__content .list-posts .highlight-post .post-item .thumb {
  position: relative;
}
@media screen and (min-width: 1200px) {
  .section-posts .content .grid .posts__content .list-posts .highlight-post .post-item .thumb {
    grid-column: 1/span 7;
  }
}
.section-posts .content .grid .posts__content .list-posts .highlight-post .post-item .thumb a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
@media screen and (min-width: 1200px) {
  .section-posts .content .grid .posts__content .list-posts .highlight-post .post-item__content {
    grid-column: 5/span 5;
  }
}
@media screen and (min-width: 1200px) {
  .section-posts .content .grid .posts__content .list-posts .highlight-post .post-item__content--target {
    right: auto;
    left: 0;
    transform: translate(-50%, -50%);
  }
}
@media screen and (min-width: 1200px) {
  .section-posts .content .grid .posts__content .list-posts .highlight-post .post-item__content__inner {
    border-radius: 0 0.75rem 0.75rem 0.75rem;
    padding: 2rem;
  }
}
.section-posts .content .grid .posts__content .list-posts .loader {
  grid-column: 1/-1;
}
.section-posts .content .grid .posts__content .list-posts .loader-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.section-posts .content .grid .posts__content .list-posts .loader-spinner {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #180636 #180636 transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.section-posts .content .grid .posts__content .list-posts .loader-spinner::after, .section-posts .content .grid .posts__content .list-posts .loader-spinner::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #FF1435 #FF1435;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}
.section-posts .content .grid .posts__content .list-posts .loader-spinner::before {
  width: 32px;
  height: 32px;
  border-color: #180636 #180636 transparent transparent;
  animation: rotation 1.5s linear infinite;
}
@media screen and (min-width: 1200px) {
  .section-posts.press-posts .content .grid .posts__filters__inner .accordion-header {
    display: none;
  }
}
.section-posts.press-posts .content .grid .posts__filters__inner .accordion-header--title {
  font-size: 18px;
  font-weight: 700;
}
.section-posts.press-posts .content .grid .posts__filters__inner .accordion-header--title::after {
  width: 1.875rem;
  height: 1.875rem;
}
@media screen and (min-width: 1200px) {
  .section-posts.press-posts .content .grid .posts__filters__inner .accordion-content {
    height: auto !important;
    padding: 2rem;
  }
}
.section-posts.press-posts .content .grid .posts__filters__inner .accordion-content__inner {
  padding: 0 1rem 1rem;
}
@media screen and (min-width: 1200px) {
  .section-posts.press-posts .content .grid .posts__filters__inner .accordion-content__inner {
    padding: 0;
  }
}
.section-posts.press-posts .content .grid .posts__filters__inner .accordion-content__inner .cat-content:not(:last-child) {
  margin-bottom: 1rem;
}
.section-posts.press-posts .content .grid .posts__filters__inner .accordion-content__inner .cat-content .cat-title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 1rem;
}
@media screen and (min-width: 1200px) {
  .section-posts.press-posts .content .grid .posts__filters__inner .accordion-content__inner .cat-content .cat-title {
    font-size: 18px;
  }
}
@media screen and (min-width: 1200px) {
  .section-posts.press-posts .content .grid .posts__filters__inner .accordion-content__inner .cat-content {
    padding: 2rem;
    border-radius: 0.75rem;
  }
}
.section-posts.lab-posts {
  background-color: #180636;
}
.section-posts.lab-posts .content .grid .posts__search .search input {
  background-color: #5A5974;
  color: #FFFFFF;
  border-color: #5A5974;
}
.section-posts.lab-posts .content .grid .posts__search .search input:focus {
  border-color: #7FF9EB;
}
.section-posts.lab-posts .content .grid .posts__search .search button {
  background-color: #7FF9EB;
}
.section-posts.lab-posts .content .grid .posts__search .search button svg {
  fill: #FFFFFF;
}
.section-posts.lab-posts .content .grid .posts__filters__inner .accordion-item {
  background-color: #5A5974;
  border: none;
}
@media screen and (min-width: 1200px) {
  .section-posts.lab-posts .content .grid .posts__filters__inner .accordion-item {
    background-color: transparent;
    border-radius: 0;
  }
}
@media screen and (min-width: 1200px) {
  .section-posts.lab-posts .content .grid .posts__filters__inner .accordion-header {
    display: none;
  }
}
.section-posts.lab-posts .content .grid .posts__filters__inner .accordion-header--title {
  color: #FFFFFF;
}
@media screen and (min-width: 1200px) {
  .section-posts.lab-posts .content .grid .posts__filters__inner .accordion-header--title {
    display: none;
  }
}
.section-posts.lab-posts .content .grid .posts__filters__inner .accordion-header--title::after {
  background-image: url("../img/icons/icon-accordion-white.svg");
}
@media screen and (min-width: 1200px) {
  .section-posts.lab-posts .content .grid .posts__filters__inner .accordion-content {
    height: auto !important;
    padding: 0;
  }
}
.section-posts.lab-posts .content .grid .posts__filters__inner .accordion-content .cat-content:not(:last-child) {
  margin-bottom: 1rem;
}
.section-posts.lab-posts .content .grid .posts__filters__inner .accordion-content .cat-content .cat-title {
  font-size: 14px;
  font-weight: 700;
  color: #FFFFFF;
  margin-bottom: 1rem;
}
@media screen and (min-width: 1200px) {
  .section-posts.lab-posts .content .grid .posts__filters__inner .accordion-content .cat-content .cat-title {
    font-size: 18px;
  }
}
@media screen and (min-width: 1200px) {
  .section-posts.lab-posts .content .grid .posts__filters__inner .accordion-content .cat-content {
    padding: 2rem;
    background-color: #5A5974;
    border-radius: 0.75rem;
  }
}
.section-posts.lab-posts .content .grid .posts__content .list-posts .loader-spinner {
  border-color: #FFFFFF #FFFFFF transparent transparent;
}
.section-posts.lab-posts .content .grid .posts__content .list-posts .loader-spinner::before {
  border-color: #FFFFFF #FFFFFF transparent transparent;
}
.section-posts.lab-posts .content .grid .posts__content .tool-pagination .prev svg .circle,
.section-posts.lab-posts .content .grid .posts__content .tool-pagination .next svg .circle {
  stroke: #FFFFFF;
}
.section-posts.lab-posts .content .grid .posts__content .tool-pagination .prev svg .arrow,
.section-posts.lab-posts .content .grid .posts__content .tool-pagination .next svg .arrow {
  fill: #FFFFFF;
}
.section-posts.lab-posts .content .grid .posts__content .tool-pagination .prev:hover svg .circle,
.section-posts.lab-posts .content .grid .posts__content .tool-pagination .next:hover svg .circle {
  fill: none;
  stroke: #7FF9EB;
}
.section-posts.lab-posts .content .grid .posts__content .tool-pagination .prev:hover svg .arrow,
.section-posts.lab-posts .content .grid .posts__content .tool-pagination .next:hover svg .arrow {
  fill: #7FF9EB;
}
.section-posts.lab-posts .content .grid .posts__content .tool-pagination .prev.disabled,
.section-posts.lab-posts .content .grid .posts__content .tool-pagination .next.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.section-posts.lab-posts .content .grid .posts__content .tool-pagination .pages .current {
  color: #7FF9EB;
}
.section-posts.lab-posts .content .grid .posts__content .tool-pagination .pages a {
  color: #C1C1C1;
}
.section-posts.lab-posts .content .grid .posts__content .tool-pagination .pages a:hover {
  color: #7FF9EB;
}
@media screen and (min-width: 1200px) {
  .section-posts.lab-posts .content .grid .post-item.featured-post {
    overflow: visible;
    gap: 1rem;
    align-items: center;
  }
}
.section-posts.lab-posts .content .grid .post-item.featured-post .thumb {
  grid-row: 1/-1;
  border-radius: 0.75rem;
  overflow: hidden;
  aspect-ratio: 16/11;
  z-index: 10;
}
.section-posts.lab-posts .content .grid .post-item.featured-post .post-item__content {
  display: block;
  padding: 0;
  background-color: transparent;
  height: auto;
}
@media screen and (min-width: 1200px) {
  .section-posts.lab-posts .content .grid .post-item.featured-post .post-item__content {
    grid-row: 1/-1;
    position: relative;
    z-index: 20;
  }
}
@media screen and (min-width: 1200px) {
  .section-posts.lab-posts .content .grid .post-item.featured-post .post-item__content--target {
    display: block;
    position: absolute;
    top: 0;
    right: auto;
    left: 0;
    transform: translate(-50%, -50%);
    aspect-ratio: 1;
    width: 80%;
    background-image: url("../img/decos/deco-post-item-featured-target-white.svg");
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 10;
  }
}
.section-posts.lab-posts .content .grid .post-item.featured-post .post-item__content__inner {
  padding: 1.5rem;
  background-color: #5A5974;
  border: 0.063rem solid #180636;
  position: relative;
  z-index: 20;
}
@media screen and (min-width: 1200px) {
  .section-posts.lab-posts .content .grid .post-item.featured-post .post-item__content__inner {
    padding: 2rem;
  }
}
.section-posts.lab-posts .content .grid .post-item.featured-post .post-item__content--header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.5rem;
  margin-bottom: 1rem;
}
.section-posts.lab-posts .content .grid .post-item.featured-post .post-item__content--header .cat {
  color: #FFFFFF;
}
.section-posts.lab-posts .content .grid .post-item.featured-post .post-item__content--inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.section-posts.lab-posts .content .grid .post-item.featured-post .post-item__content--inner .title {
  color: #FFFFFF;
}
.section-posts.lab-posts .content .grid .post-item.featured-post .post-item__content--inner .excerpt {
  color: #FFFFFF;
}
.section-posts.lab-posts .content .grid .post-item.featured-post .post-item__content--inner .action {
  margin-top: auto;
}
.section-posts.lab-posts .content .grid .post-item.featured-post .post-item__content--inner .action .link {
  color: #FFFFFF;
}
.section-posts.lab-posts .content .grid .post-item.featured-post .post-item__content--inner .action .link-deco::before {
  background-color: #FFFFFF;
}
.section-posts.lab-posts .content .grid .post-item.featured-post .post-item__content--inner .action .link-deco::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath d='M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z' fill='rgba(255,255,255,1)'%3E%3C/path%3E%3C/svg%3E");
}
.section-posts.lab-posts .content .grid .post-item:not(.featured-post) {
  display: flex;
  flex-direction: column;
  border-color: #180636;
}
.section-posts.lab-posts .content .grid .post-item:not(.featured-post) .thumb {
  display: none;
}
@media screen and (min-width: 1200px) {
  .section-posts.lab-posts .content .grid .post-item:not(.featured-post) .thumb {
    display: block;
    aspect-ratio: 16/10;
  }
}
@media screen and (min-width: 1200px) {
  .section-posts.lab-posts .content .grid .post-item:not(.featured-post) .thumb img {
    aspect-ratio: 16/10;
  }
}
.section-posts.lab-posts .content .grid .post-item:not(.featured-post) .post-item__content {
  background-color: #5A5974;
  display: flex;
  flex-direction: column;
}
.section-posts.lab-posts .content .grid .post-item:not(.featured-post) .post-item__content__inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #5A5974;
  border: 0.063rem solid #180636;
}
.section-posts.lab-posts .content .grid .post-item:not(.featured-post) .post-item__content--header {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
}
.section-posts.lab-posts .content .grid .post-item:not(.featured-post) .post-item__content--header .cat {
  color: #FFFFFF;
}
.section-posts.lab-posts .content .grid .post-item:not(.featured-post) .post-item__content--header .tag {
  width: max-content;
  font-size: 14px;
  padding: 0.25rem 0.75rem;
  border: 0.125rem solid #7FF9EB;
  border-radius: 0.75rem;
  color: #7FF9EB;
}
.section-posts.lab-posts .content .grid .post-item:not(.featured-post) .post-item__content--inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.section-posts.lab-posts .content .grid .post-item:not(.featured-post) .post-item__content--inner .title {
  color: #FFFFFF;
}
.section-posts.lab-posts .content .grid .post-item:not(.featured-post) .post-item__content--inner .excerpt {
  color: #FFFFFF;
}
.section-posts.lab-posts .content .grid .post-item:not(.featured-post) .post-item__content--inner .action {
  margin-top: auto;
}
.section-posts.lab-posts .content .grid .post-item:not(.featured-post) .post-item__content--inner .action .link {
  color: #FFFFFF;
}
.section-posts.lab-posts .content .grid .post-item:not(.featured-post) .post-item__content--inner .action .link-deco::before {
  background-color: #FFFFFF;
}
.section-posts.lab-posts .content .grid .post-item:not(.featured-post) .post-item__content--inner .action .link-deco::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath d='M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z' fill='rgba(255,255,255,1)'%3E%3C/path%3E%3C/svg%3E");
}
.section-posts.lab-posts .form-check-label {
  color: #FFFFFF;
}
.section-posts.lab-posts .form-check-label::before {
  border-color: #FFFFFF;
  background-color: #5A5974;
}
.section-posts.lab-posts .form-check-label::after {
  background-color: #FFFFFF;
}
.section-posts.ressource-posts .content .grid .posts__filters__inner {
  position: initial;
}
@media screen and (min-width: 1200px) {
  .section-posts.ressource-posts .content .grid .posts__filters__inner .accordion-item {
    border: none;
    background-color: transparent;
    border-radius: 0;
  }
}
@media screen and (min-width: 1200px) {
  .section-posts.ressource-posts .content .grid .posts__filters__inner .accordion-header {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .section-posts.ressource-posts .content .grid .posts__filters__inner .accordion-header--title {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .section-posts.ressource-posts .content .grid .posts__filters__inner .accordion-content {
    height: auto !important;
    padding: 0;
  }
}
.section-posts.ressource-posts .content .grid .posts__filters__inner .accordion-content .cat-content:not(:last-child) {
  margin-bottom: 1rem;
}
@media screen and (min-width: 1200px) {
  .section-posts.ressource-posts .content .grid .posts__filters__inner .accordion-content .cat-content {
    padding: 2rem;
    border-radius: 0.75rem;
    background-color: #FFFFFF;
    border: 0.063rem solid #DADBE0;
  }
}
.section-posts.ressource-posts .content .grid .posts__filters__inner .accordion-content .cat-content .cat-title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 1rem;
}
@media screen and (min-width: 1200px) {
  .section-posts.ressource-posts .content .grid .posts__filters__inner .accordion-content .cat-content .cat-title {
    font-size: 18px;
  }
}
.section-posts.ressource-posts .content .grid .posts__filters__inner .accordion-content .cat-content.tags .btn-check-input + label {
  border-color: #3E3CFF;
  color: #3E3CFF;
  font-size: 16px;
}
.section-posts.ressource-posts .content .grid .posts__filters__inner .accordion-content .cat-content.tags .btn-check-input + label:hover {
  background-color: #3E3CFF;
  color: #FFFFFF;
}
.section-posts.ressource-posts .content .grid .posts__filters__inner .accordion-content .cat-content.tags .btn-check-input:checked + label {
  background-color: #3E3CFF;
  color: #FFFFFF;
}
.section-posts.ressource-posts .content .grid .post-item__content--header {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
}
.section-posts.ressource-posts .content .grid .post-item__content--header .tags-list {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}
.section-posts.ressource-posts .content .grid .post-item__content--header .tag {
  width: max-content;
  font-size: 14px;
  padding: 0.25rem 0.75rem;
  border: 0.125rem solid #3E3CFF;
  border-radius: 0.75rem;
  color: #3E3CFF;
}
.section-posts.use-case-posts .content .grid .posts__filters__inner {
  position: initial;
}
@media screen and (min-width: 1200px) {
  .section-posts.use-case-posts .content .grid .posts__filters__inner .accordion-item {
    border: none;
    background-color: transparent;
    border-radius: 0;
  }
}
@media screen and (min-width: 1200px) {
  .section-posts.use-case-posts .content .grid .posts__filters__inner .accordion-header {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .section-posts.use-case-posts .content .grid .posts__filters__inner .accordion-header--title {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .section-posts.use-case-posts .content .grid .posts__filters__inner .accordion-content {
    height: auto !important;
    padding: 0;
  }
}
.section-posts.use-case-posts .content .grid .posts__filters__inner .accordion-content .cat-content:not(:last-child) {
  margin-bottom: 1rem;
}
@media screen and (min-width: 1200px) {
  .section-posts.use-case-posts .content .grid .posts__filters__inner .accordion-content .cat-content {
    padding: 2rem;
    border-radius: 0.75rem;
    background-color: #FFFFFF;
    border: 0.063rem solid #DADBE0;
  }
}
.section-posts.use-case-posts .content .grid .posts__filters__inner .accordion-content .cat-content .cat-title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 1rem;
}
@media screen and (min-width: 1200px) {
  .section-posts.use-case-posts .content .grid .posts__filters__inner .accordion-content .cat-content .cat-title {
    font-size: 18px;
  }
}
.section-posts.use-case-posts .content .grid .posts__filters__inner .accordion-content .cat-content.tags .btn-check-input + label {
  border-color: #3E3CFF;
  color: #3E3CFF;
  font-size: 16px;
}
.section-posts.use-case-posts .content .grid .posts__filters__inner .accordion-content .cat-content.tags .btn-check-input + label:hover {
  background-color: #3E3CFF;
  color: #FFFFFF;
}
.section-posts.use-case-posts .content .grid .posts__filters__inner .accordion-content .cat-content.tags .btn-check-input:checked + label {
  background-color: #3E3CFF;
  color: #FFFFFF;
}
.section-posts.use-case-posts .content .grid .post-item__content--header {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
}
.section-posts.use-case-posts .content .grid .post-item__content--header .tags-list {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  flex-wrap: wrap;
}
.section-posts.use-case-posts .content .grid .post-item__content--header .tag {
  width: max-content;
  font-size: 14px;
  padding: 0.25rem 0.75rem;
  border: 0.125rem solid #3E3CFF;
  border-radius: 0.75rem;
  color: #3E3CFF;
}
.section-posts.partner-posts {
  background-color: #ECF2FE;
}
@media screen and (min-width: 1200px) {
  .section-posts.partner-posts .content .grid .posts__filters__inner {
    position: sticky;
    top: 7rem;
  }
}
.section-posts.partner-posts .content .grid .posts__filters__inner .accordion-item:not(:last-child) {
  margin-bottom: 1rem;
}
@media screen and (min-width: 1200px) {
  .section-posts.partner-posts .content .grid .posts__filters__inner .accordion-content {
    padding: 0;
  }
}
@media screen and (min-width: 1200px) {
  .section-posts.partner-posts .content .grid .posts__filters__inner .accordion-content__inner {
    padding: 0 2rem 2rem;
  }
}
.section-posts.partner-posts .content .grid .posts__filters__inner .form-check:not(:last-child) {
  margin-bottom: 1rem;
}
.section-posts.partner-posts .content .grid .posts__filters__inner .cat-content.tags .wrap-taxonomy {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
@media screen and (min-width: 1200px) {
  .section-posts.partner-posts .content .grid .posts__content .list-posts {
    grid-template-columns: repeat(3, 1fr);
  }
}
.section-posts.partner-posts .content .grid .posts__content .list-posts .post-item.partner {
  border-radius: 0.75rem;
  border: 0.063rem solid #DADBE0;
}
.section-posts.partner-posts .content .grid .posts__content .list-posts .post-item.partner .thumb {
  background-color: #FAFAFA;
  aspect-ratio: initial;
  position: relative;
}
.section-posts.partner-posts .content .grid .posts__content .list-posts .post-item.partner .thumb img {
  aspect-ratio: 16/9;
  object-fit: contain;
  width: 80%;
  margin: auto;
  mix-blend-mode: multiply;
}
.section-posts.partner-posts .content .grid .posts__content .list-posts .post-item.partner .thumb a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.section-posts.partner-posts .content .grid .posts__content .list-posts .post-item.partner .post-item__content {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.section-posts.partner-posts .content .grid .posts__content .list-posts .post-item.partner .post-item__content .title {
  font-size: 18px;
  font-weight: 700;
}
.section-posts.partner-posts .content .grid .posts__content .list-posts .post-item.partner .post-item__content .action {
  margin-top: auto;
}
.section-posts.alliances-posts .content .grid .posts__content {
  grid-column: 1/-1;
}
@media screen and (min-width: 768px) {
  .section-posts.alliances-posts .content .grid .posts__content .list-posts {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
  }
}
@media screen and (min-width: 1200px) {
  .section-posts.alliances-posts .content .grid .posts__content .list-posts {
    grid-template-columns: repeat(4, 1fr);
  }
}
.section-posts.alliances-posts .content .grid .posts__content .list-posts .post-item.partner {
  border-radius: 0.75rem;
  border: 0.063rem solid #DADBE0;
}
.section-posts.alliances-posts .content .grid .posts__content .list-posts .post-item.partner .thumb {
  background-color: #FAFAFA;
  aspect-ratio: initial;
}
.section-posts.alliances-posts .content .grid .posts__content .list-posts .post-item.partner .thumb img {
  aspect-ratio: 16/9;
  object-fit: contain;
  width: 80%;
  margin: auto;
  mix-blend-mode: multiply;
}
.section-posts.alliances-posts .content .grid .posts__content .list-posts .post-item.partner .post-item__content {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.section-posts.alliances-posts .content .grid .posts__content .list-posts .post-item.partner .post-item__content .title {
  font-size: 18px;
  font-weight: 700;
}
.section-posts.alliances-posts .content .grid .posts__content .list-posts .post-item.partner .post-item__content .action {
  margin-top: auto;
}
@media screen and (min-width: 1200px) {
  .section-posts.information-posts .posts__filters__inner {
    position: sticky;
    top: 7rem;
  }
}
.section-posts.information-posts .posts__filters__inner .accordion-item:not(:last-child) {
  margin-bottom: 1rem;
}
@media screen and (min-width: 1200px) {
  .section-posts.information-posts .posts__filters__inner .accordion-content {
    padding: 0 !important;
  }
}
@media screen and (min-width: 1200px) {
  .section-posts.information-posts .posts__filters__inner .accordion-content__inner {
    padding: 0 2rem 2rem !important;
  }
}
.section-posts.information-posts .posts__filters__inner .form-check:not(:last-child) {
  margin-bottom: 1rem;
}
.section-posts.information-posts .posts__filters__inner .cat-content.tags .wrap-taxonomy {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.section-posts.information-posts .posts__content__inner .list-posts .post-item.information .post-item__content--header {
  justify-content: start;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}
.section-posts.information-posts .posts__content__inner .list-posts .post-item.information .post-item__content .title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 1rem;
}
.section-posts.information-posts .posts__content__inner .list-posts .post-item.information .post-item__content .excerpt {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  margin-block: 1rem;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.highlight-block {
  padding-block: 3rem;
}
@media screen and (min-width: 1200px) {
  .highlight-block {
    padding-block: 8rem;
  }
}
.highlight-block .content .grid {
  row-gap: 2.5rem;
}
.highlight-block .content .grid__title {
  grid-column: 1/-1;
}
@media screen and (min-width: 576px) {
  .highlight-block .content .grid__title {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 768px) {
  .highlight-block .content .grid__title {
    grid-column: 1/-1;
  }
}
.highlight-block .content .grid__title .title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  margin-block: 0;
}
@media screen and (min-width: 1200px) {
  .highlight-block .content .grid__title .title {
    font-size: 40px;
  }
}
.highlight-block .content .grid__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.highlight-block .content .grid__item {
  /* grid-column: span 4; */
  position: relative;
  /*  @media screen and (min-width: 576px) {
    grid-column: 2 / span 8;
  }
  @media screen and (min-width: 768px) {
    grid-column: span 6;
  }
  @media screen and (min-width: 1200px) {
    grid-column: span 4;
  } */
}
.highlight-block .content .grid__item:not(:last-child)::after {
  content: "";
  display: block;
  margin-top: 2.5rem;
  height: 0.063rem;
  background-color: #180636;
}
@media screen and (min-width: 768px) {
  .highlight-block .content .grid__item:not(:last-child)::after {
    content: none;
  }
}
.highlight-block .content .grid__item .item--icon {
  width: 35px;
  height: 35px;
}
.highlight-block .content .grid__item .item--icon img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.highlight-block .content .grid__item .item--title {
  font-size: 18px;
  font-weight: 700;
}
.highlight-block .content .grid__item hr {
  display: none;
}
@media screen and (min-width: 768px) {
  .highlight-block .content .grid__item hr {
    display: block;
    margin-block: 1.5rem;
    height: 0.063rem;
    background-color: #180636;
    border: none;
  }
}
.highlight-block .content .grid__item .editor {
  margin-top: 1.5rem;
  font-size: 14px;
}

.partners-block {
  padding-block: 3rem;
}
@media screen and (min-width: 1200px) {
  .partners-block {
    padding-block: 8rem;
  }
}
.partners-block .content .grid {
  row-gap: 2.5rem;
}
.partners-block .content .grid__title {
  grid-column: 1/-1;
}
@media screen and (min-width: 576px) {
  .partners-block .content .grid__title {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 768px) {
  .partners-block .content .grid__title {
    grid-column: 1/-1;
  }
}
.partners-block .content .grid__title .title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  margin-block: 0;
}
@media screen and (min-width: 1200px) {
  .partners-block .content .grid__title .title {
    font-size: 40px;
  }
}
.partners-block .content .grid__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.partners-block .content .grid__title .editor {
  margin-top: 2.5rem;
}
.partners-block .content .grid__content {
  grid-column: 1/-1;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}
@media screen and (min-width: 1200px) {
  .partners-block .content .grid__content {
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;
  }
}
.partners-block .content .grid__content .item {
  position: relative;
}
@media screen and (min-width: 1200px) {
  .partners-block .content .grid__content .item {
    flex: 1;
    min-width: calc(16.6666666667% - 1rem);
    max-width: calc(16.6666666667% - 1rem);
  }
}
.partners-block .content .grid__content .item:not(:last-child)::after {
  content: "";
  display: block;
  margin-top: 2.5rem;
  height: 0.063rem;
  background-color: #180636;
}
@media screen and (min-width: 768px) {
  .partners-block .content .grid__content .item:not(:last-child)::after {
    content: none;
  }
}
.partners-block .content .grid__content .item > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.partners-block .content .grid__content .item--icon {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.partners-block .content .grid__content .item--icon img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.partners-block .content .grid__content .item--title {
  font-size: 14px;
}
@media screen and (min-width: 1200px) {
  .partners-block .content .grid__content .item--title {
    font-size: 20px;
    font-weight: bold;
  }
}
.partners-block .content .grid__content .item hr {
  display: none;
}
@media screen and (min-width: 768px) {
  .partners-block .content .grid__content .item hr {
    display: block;
    margin-block: 1.5rem;
    height: 0.063rem;
    background-color: #180636;
    border: none;
    width: 100%;
  }
}
.partners-block .content .grid__content .item .editor {
  margin-top: 1.5rem;
  font-size: 14px;
}

.uses-block {
  padding-block: 3rem;
  background-color: #FFFFFF;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .uses-block {
    padding-block: 8rem;
  }
}
.uses-block .bg-container {
  position: absolute;
  top: 45%;
  left: 0;
  width: 100%;
  height: 55%;
}
.uses-block .bg-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.uses-block .content {
  position: relative;
  z-index: 10;
}
@media screen and (min-width: 1200px) {
  .uses-block .content .grid {
    grid-auto-flow: column;
  }
}
.uses-block .content .grid__title {
  grid-column: 1/-1;
}
.uses-block .content .grid__title .title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  margin-block: 0 1.5rem;
}
@media screen and (min-width: 1200px) {
  .uses-block .content .grid__title .title {
    font-size: 40px;
  }
}
.uses-block .content .grid__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.uses-block .content .grid__item {
  grid-column: 1/-1;
  padding: 1rem;
  border-radius: 0.75rem;
  border: 0.063rem solid #DADBE0;
  background-color: #FFFFFF;
}
@media screen and (min-width: 576px) {
  .uses-block .content .grid__item {
    padding: 1.5rem;
  }
}
@media screen and (min-width: 1200px) {
  .uses-block .content .grid__item {
    padding: 2.5rem;
  }
  .uses-block .content .grid__item:not(:last-child) {
    grid-column: span 8;
  }
  .uses-block .content .grid__item:last-child {
    grid-column: 9/span 4;
    grid-row: span 2;
  }
}
@media screen and (min-width: 1200px) and (min-width: 1200px) {
  .uses-block .content .grid__item:last-child {
    background-color: #FAFAFA;
  }
}
.uses-block .content .grid__item .icon {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}
.uses-block .content .grid__item .icon img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.uses-block .content .grid__item .item--title {
  margin-block: 0 2rem;
  font-size: 14px;
  font-weight: 700;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .uses-block .content .grid__item .item--title {
    font-size: 18px;
  }
}
.uses-block .content .grid__item .item--title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.uses-block .content .grid__item .editor {
  margin-top: 2rem;
}
.uses-block .content .grid__item .action {
  margin-top: 2rem;
}
.uses-block .content .grid__item .action .btn {
  max-width: 100%;
}
@media screen and (min-width: 768px) {
  .uses-block .content .grid__item .action .btn {
    max-width: 50%;
  }
}
@media screen and (min-width: 1200px) {
  .uses-block .content .grid__item .action .btn {
    max-width: max-content;
  }
}
.uses-block .content .grid__item.multi-links {
  position: relative;
}
@media screen and (min-width: 1200px) {
  .uses-block .content .grid__item.multi-links .grid__item--content {
    max-width: 75%;
  }
}
.uses-block .content .grid__item.multi-links .action .btn-bordered .btn-icon {
  display: none;
}
@media screen and (min-width: 1200px) {
  .uses-block .content .grid__item.multi-links .action .btn-bordered .btn-icon {
    display: block;
  }
}
.uses-block .content .grid__item.multi-links .action + .action {
  margin-top: 1rem;
}
@media screen and (min-width: 1200px) {
  .uses-block .content .grid__item.multi-links .action:last-child {
    margin-top: 0;
    position: absolute;
    top: -0.063rem;
    right: -0.063rem;
    width: 25%;
    height: calc(100% + 0.125rem);
    background-color: #E8E8FD;
    border-radius: 0 0.75rem 0.75rem 0;
  }
}
@media screen and (min-width: 1200px) {
  .uses-block .content .grid__item.multi-links .action:last-child .btn-bordered {
    border: none;
    border-radius: 0 0.75rem 0.75rem 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    font-weight: 700;
  }
}
@media screen and (min-width: 1200px) {
  .uses-block .content .grid__item.multi-links .action:last-child .btn-bordered .btn-icon {
    transform: rotate(-90deg);
  }
}
.uses-block .content .grid__item.multi-links .action:last-child .btn-bordered .btn-icon svg path {
  transition: all 0.35s ease-in-out;
}
.uses-block .content .grid__item.multi-links .action:last-child .btn-bordered:hover .btn-icon svg path {
  fill: #FFFFFF;
}

.question-answers-block {
  padding-block: 3rem;
  background-color: #ECF2FE;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .question-answers-block {
    padding-block: 8rem;
  }
}
.question-answers-block .bg-container {
  display: none;
}
@media screen and (min-width: 768px) {
  .question-answers-block .bg-container {
    display: block;
    position: absolute;
    top: calc(6rem + var(--questionAnswersHeight));
    left: 0;
    width: 100%;
    height: calc(100% - (6rem + var(--questionAnswersHeight)));
  }
}
@media screen and (min-width: 1200px) {
  .question-answers-block .bg-container {
    top: calc(16rem + var(--questionAnswersHeight));
    height: calc(100% - (16rem + var(--questionAnswersHeight)));
  }
}
.question-answers-block .bg-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.question-answers-block .content {
  position: relative;
  z-index: 10;
}
.question-answers-block .content .grid {
  row-gap: 2rem;
}
.question-answers-block .question-answers__title {
  grid-column: span 4;
}
@media screen and (min-width: 576px) {
  .question-answers-block .question-answers__title {
    grid-column: 2/span 6;
  }
}
@media screen and (min-width: 768px) {
  .question-answers-block .question-answers__title {
    grid-column: span 5;
  }
}
@media screen and (min-width: 1200px) {
  .question-answers-block .question-answers__title {
    grid-column: span 4;
  }
}
.question-answers-block .question-answers__title .title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  margin-block: 0 1rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .question-answers-block .question-answers__title .title {
    font-size: 40px;
    margin-block: 0 2rem;
    margin-right: -1rem;
  }
}
.question-answers-block .question-answers__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.question-answers-block .question-answers__title .editor {
  margin-top: 1rem;
}
@media screen and (min-width: 1200px) {
  .question-answers-block .question-answers__title .editor {
    margin-top: 2rem;
  }
}
.question-answers-block .question-answers__content {
  grid-column: span 4;
}
@media screen and (min-width: 576px) {
  .question-answers-block .question-answers__content {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 576px) {
  .question-answers-block .question-answers__content {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 768px) {
  .question-answers-block .question-answers__content {
    grid-column: span 7;
  }
}
@media screen and (min-width: 1200px) {
  .question-answers-block .question-answers__content {
    grid-column: 6/span 7;
  }
}
.question-answers-block .question-answers__content .list-item {
  padding: 1rem;
  background-color: #FFFFFF;
  border: 0.063rem solid #DADBE0;
  border-radius: 0.75rem;
}
@media screen and (min-width: 1200px) {
  .question-answers-block .question-answers__content .list-item {
    padding: 1.25rem;
  }
}
.question-answers-block .question-answers__content .list-item:not(:last-child) {
  margin-bottom: 1rem;
}
@media screen and (min-width: 1200px) {
  .question-answers-block .question-answers__content .list-item {
    display: flex;
    align-items: flex-start;
    gap: 1.25rem;
  }
}
.question-answers-block .question-answers__content .list-item__icon {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.question-answers-block .question-answers__content .list-item__content .title {
  font-size: 14px;
  margin-block: 0 0.5rem;
  font-weight: 700;
}
@media screen and (min-width: 1200px) {
  .question-answers-block .question-answers__content .list-item__content .title {
    font-size: 18px;
  }
}
.question-answers-block .question-answers__content .list-item__content .editor {
  font-size: 14px;
  font-weight: 400;
  color: #2B2B2B;
}

.informations-block {
  padding-block: 3rem;
  background-color: #E8E8FD;
}
@media screen and (min-width: 1200px) {
  .informations-block {
    padding-block: 8rem;
  }
}
.informations-block .content .grid {
  row-gap: 2rem;
}
.informations-block .informations__title {
  grid-column: 1/-1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
@media screen and (min-width: 576px) {
  .informations-block .informations__title {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 768px) {
  .informations-block .informations__title {
    grid-column: 2/span 10;
  }
}
@media screen and (min-width: 1200px) {
  .informations-block .informations__title {
    grid-column: 1/-1;
  }
}
@media screen and (min-width: 1200px) {
  .informations-block .informations__title {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
.informations-block .informations__title .title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  margin-block: 0;
}
@media screen and (min-width: 1200px) {
  .informations-block .informations__title .title {
    font-size: 40px;
  }
}
.informations-block .informations__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.informations-block .informations__title .action .btn {
  max-width: 100%;
}
@media screen and (min-width: 576px) {
  .informations-block .informations__title .action .btn {
    max-width: max-content;
  }
}
.informations-block .informations__content {
  grid-column: 1/-1;
}
@media screen and (min-width: 576px) {
  .informations-block .informations__content {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 768px) {
  .informations-block .informations__content {
    grid-column: 2/span 10;
  }
}
@media screen and (min-width: 1200px) {
  .informations-block .informations__content {
    grid-column: 1/-1;
  }
}
.informations-block .informations__content .item:not(:last-child) {
  margin-bottom: 2rem;
}
@media screen and (min-width: 1200px) {
  .informations-block .informations__content .item:not(:last-child) {
    margin-bottom: 4rem;
  }
}
@media screen and (min-width: 1200px) {
  .informations-block .informations__content .item {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
    align-items: center;
  }
}
.informations-block .informations__content .item__image {
  border-radius: 0.75rem 0.75rem 0 0;
  aspect-ratio: 4/3;
  overflow: hidden;
}
@media screen and (min-width: 1200px) {
  .informations-block .informations__content .item__image {
    border-radius: 0.75rem;
    aspect-ratio: 16/11;
  }
}
.informations-block .informations__content .item__image .img-container {
  height: 100%;
}
.informations-block .informations__content .item__image .img-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.informations-block .informations__content .item__content {
  background-color: #FFFFFF;
  border-radius: 0 0 0.75rem 0.75rem;
  padding: 1rem;
}
@media screen and (min-width: 768px) {
  .informations-block .informations__content .item__content {
    padding: 2rem;
  }
}
@media screen and (min-width: 1200px) {
  .informations-block .informations__content .item__content {
    border-radius: 0.75rem;
  }
}
.informations-block .informations__content .item__content .quote {
  font-size: 14px;
  text-transform: uppercase;
  color: #FF1435;
  margin-bottom: 1.5rem;
}
@media screen and (min-width: 1200px) {
  .informations-block .informations__content .item__content .quote {
    font-size: 20px;
  }
}
.informations-block .informations__content .item__content .title {
  font-size: 14px;
  font-weight: 700;
  margin-block: 0 1rem;
}
@media screen and (min-width: 1200px) {
  .informations-block .informations__content .item__content .title {
    font-size: 32px;
  }
}
.informations-block .informations__content .item__content__inner .icon {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.informations-block .informations__content .item__content__inner.has-icon {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media screen and (min-width: 768px) {
  .informations-block .informations__content .item__content__inner.has-icon {
    flex-direction: row;
  }
}
@media screen and (min-width: 768px) {
  .informations-block .informations__content .item__content__inner.has-icon .editor {
    width: calc(100% - 35px - 1rem);
  }
}
@media screen and (min-width: 1200px) {
  .informations-block .informations__content .item.img-left .item__image {
    grid-column: 1/span 7;
    grid-row: 1/-1;
    position: relative;
    z-index: 10;
  }
  .informations-block .informations__content .item.img-left .item__content {
    grid-column: 7/span 6;
    grid-row: 1/-1;
    position: relative;
    z-index: 20;
  }
}
@media screen and (min-width: 1200px) {
  .informations-block .informations__content .item.img-right .item__image {
    grid-column: 6/span 7;
    grid-row: 1/-1;
    position: relative;
    z-index: 10;
  }
  .informations-block .informations__content .item.img-right .item__content {
    grid-column: 1/span 6;
    grid-row: 1/-1;
    position: relative;
    z-index: 20;
  }
}

.missions-block {
  padding-block: 3rem;
  background-color: #ECF2FE;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .missions-block {
    padding-block: 8rem;
  }
}
.missions-block .bg-container {
  display: none;
}
@media screen and (min-width: 768px) {
  .missions-block .bg-container {
    display: block;
    position: absolute;
    top: calc(6rem + var(--missionsHeight));
    left: 0;
    width: 100%;
    height: calc(100% - (6rem + var(--missionsHeight)));
  }
}
@media screen and (min-width: 1200px) {
  .missions-block .bg-container {
    top: calc(16rem + var(--missionsHeight));
    height: calc(100% - (16rem + var(--missionsHeight)));
  }
}
.missions-block .bg-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.missions-block .content {
  position: relative;
  z-index: 10;
}
.missions-block .content .grid {
  row-gap: 2rem;
}
.missions-block .missions__title {
  grid-column: span 4;
}
@media screen and (min-width: 576px) {
  .missions-block .missions__title {
    grid-column: 2/span 6;
  }
}
@media screen and (min-width: 768px) {
  .missions-block .missions__title {
    grid-column: span 5;
  }
}
@media screen and (min-width: 1200px) {
  .missions-block .missions__title {
    grid-column: span 4;
  }
}
.missions-block .missions__title .title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  margin-block: 0 1rem;
}
@media screen and (min-width: 1200px) {
  .missions-block .missions__title .title {
    font-size: 40px;
    margin-block: 0 2rem;
  }
}
.missions-block .missions__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.missions-block .missions__title .editor {
  font-size: 14px;
  margin-top: 1rem;
}
@media screen and (min-width: 1200px) {
  .missions-block .missions__title .editor {
    margin-top: 2rem;
  }
}
.missions-block .missions__title .action {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media screen and (min-width: 1200px) {
  .missions-block .missions__title .action {
    margin-top: 2rem;
  }
}
.missions-block .missions__title .action .btn {
  max-width: 100%;
}
@media screen and (min-width: 576px) {
  .missions-block .missions__title .action .btn {
    max-width: max-content;
  }
}
.missions-block .missions__content {
  grid-column: span 4;
}
@media screen and (min-width: 576px) {
  .missions-block .missions__content {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 576px) {
  .missions-block .missions__content {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 768px) {
  .missions-block .missions__content {
    grid-column: span 7;
  }
}
@media screen and (min-width: 1200px) {
  .missions-block .missions__content {
    grid-column: 6/span 7;
  }
}
.missions-block .missions__content .list-item {
  padding: 1rem;
  background-color: #FFFFFF;
  border: 0.063rem solid #DADBE0;
  border-radius: 0.75rem;
}
@media screen and (min-width: 1200px) {
  .missions-block .missions__content .list-item {
    padding: 2.5rem;
  }
}
.missions-block .missions__content .list-item:not(:last-child) {
  margin-bottom: 1rem;
}
@media screen and (min-width: 1200px) {
  .missions-block .missions__content .list-item {
    display: flex;
    align-items: flex-start;
    gap: 2.5rem;
  }
}
.missions-block .missions__content .list-item__content .item-title {
  font-size: 14px;
  font-weight: 700;
  margin-block: 0 1rem;
}
@media screen and (min-width: 1200px) {
  .missions-block .missions__content .list-item__content .item-title {
    font-size: 18px;
  }
}
.missions-block .missions__content .list-item__content .action {
  margin-top: 1rem;
}

.methodology-block {
  padding-block: 3rem;
  background-color: #FFFFFF;
}
@media screen and (min-width: 1200px) {
  .methodology-block {
    padding-block: 4rem;
  }
}
.methodology-block .content {
  width: 100%;
  max-width: calc(100% - 4rem);
  margin-inline: auto;
  padding: 2rem 1rem;
  border: 0.063rem solid #D6D5FF;
  border-radius: 0.75rem;
}
@media screen and (min-width: 576px) {
  .methodology-block .content {
    max-width: 70%;
    padding: 2rem;
  }
}
@media screen and (min-width: 768px) {
  .methodology-block .content {
    max-width: 60%;
    padding: 4rem;
  }
}
@media screen and (min-width: 1200px) {
  .methodology-block .content {
    max-width: calc(100% - 10rem);
  }
}
@media screen and (min-width: 1400px) {
  .methodology-block .content {
    max-width: 1220px;
  }
}
.methodology-block .content .grid {
  padding-inline: 0;
  row-gap: 2rem;
}
.methodology-block .content .grid__title {
  grid-column: 1/-1;
}
.methodology-block .content .grid__title .title {
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
  margin-block: 0 2.5rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .methodology-block .content .grid__title .title {
    font-size: 40px;
    margin-bottom: 2.75rem;
  }
}
.methodology-block .content .grid__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.methodology-block .content .grid__content {
  grid-column: 1/-1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media screen and (min-width: 1200px) {
  .methodology-block .content .grid__content {
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -1rem;
  }
}
.methodology-block .content .grid__content .link-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding-inline: 2rem;
}
@media screen and (min-width: 1200px) {
  .methodology-block .content .grid__content .link-item {
    padding-inline: 0;
    flex: 1;
    min-width: calc(20% - 1rem);
    max-width: calc(20% - 1rem);
  }
}
.methodology-block .content .grid__content .link-item__icon {
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 1200px) {
  .methodology-block .content .grid__content .link-item__icon {
    width: 100px;
    height: 100px;
  }
}
.methodology-block .content .grid__content .link-item__icon img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.methodology-block .content .grid__content .link-item__title {
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}
.methodology-block .content .grid__content .link-item:not(:last-child) {
  position: relative;
}
.methodology-block .content .grid__content .link-item:not(:last-child)::after {
  content: "";
  display: block;
  margin-top: -1rem;
  width: 10px;
  height: 10px;
  background-image: url("../img/decos/deco-arrow-methodo-mobile.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
@media screen and (min-width: 1200px) {
  .methodology-block .content .grid__content .link-item:not(:last-child)::after {
    content: none;
  }
}
.methodology-block .content .grid__content .link-item:not(:last-child) .link-item__icon {
  position: relative;
}
@media screen and (min-width: 1200px) {
  .methodology-block .content .grid__content .link-item:not(:last-child) .link-item__icon::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 1rem);
    width: 100%;
    height: 10%;
    background-image: url("../img/decos/deco-arrow-methodo.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.keywords-block {
  padding-block: 3rem;
  background-color: #FFFFFF;
}
@media screen and (min-width: 1200px) {
  .keywords-block {
    padding-block: 0;
  }
}
.keywords-block .content .grid__deco {
  display: none;
}
@media screen and (min-width: 1200px) {
  .keywords-block .content .grid__deco {
    display: block;
    grid-column: 1/span 5;
    position: relative;
  }
}
@media screen and (min-width: 1200px) {
  .keywords-block .content .grid__deco img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2rem;
    height: 100%;
    object-fit: cover;
  }
}
.keywords-block .content .grid__content {
  grid-column: 1/-1;
}
@media screen and (min-width: 576px) {
  .keywords-block .content .grid__content {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 768px) {
  .keywords-block .content .grid__content {
    grid-column: 3/span 8;
  }
}
@media screen and (min-width: 1200px) {
  .keywords-block .content .grid__content {
    grid-column: 6/span 7;
    padding-block: 8rem;
  }
}
.keywords-block .content .grid__content .title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  margin-block: 0 2rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .keywords-block .content .grid__content .title {
    font-size: 40px;
    margin-bottom: 2.5rem;
  }
}
.keywords-block .content .grid__content .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.keywords-block .content .grid__content .keyword-item {
  font-size: 14px;
  font-weight: 700;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .keywords-block .content .grid__content .keyword-item {
    font-size: 18px;
  }
}
.keywords-block .content .grid__content .keyword-item:not(:last-child)::after {
  content: "";
  display: block;
  margin-block: 1rem;
  height: 0.063rem;
  background-color: #000000;
}
@media screen and (min-width: 1200px) {
  .keywords-block .content .grid__content .keyword-item:not(:last-child)::after {
    margin-block: 1.5rem;
  }
}

.trainings-block {
  padding-block: 3rem;
}
@media screen and (min-width: 1200px) {
  .trainings-block {
    padding-block: 8rem;
  }
}
.trainings-block .content .grid {
  row-gap: 1.5rem;
}
@media screen and (min-width: 1200px) {
  .trainings-block .content .grid {
    row-gap: 1rem;
  }
}
.trainings-block .content .grid__title {
  grid-column: 1/-1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media screen and (min-width: 576px) {
  .trainings-block .content .grid__title {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 768px) {
  .trainings-block .content .grid__title {
    grid-column: 1/-1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1200px) {
  .trainings-block .content .grid__title {
    margin-bottom: 2rem;
  }
}
.trainings-block .content .grid__title .title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  margin-block: 0;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .trainings-block .content .grid__title .title {
    font-size: 40px;
  }
}
.trainings-block .content .grid__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.trainings-block .content .grid .training-item {
  grid-column: 1/-1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: 0.75rem;
  background-color: #FFFFFF;
}
@media screen and (min-width: 576px) {
  .trainings-block .content .grid .training-item {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 768px) {
  .trainings-block .content .grid .training-item {
    grid-column: span 6;
  }
}
@media screen and (min-width: 1200px) {
  .trainings-block .content .grid .training-item {
    grid-column: span 4;
  }
}
@media screen and (min-width: 1200px) {
  .trainings-block .content .grid .training-item {
    padding: 2rem;
  }
}
.trainings-block .content .grid .training-item__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trainings-block .content .grid .training-item__header__title {
  font-size: 14px;
  font-weight: 700;
  width: calc(100% - 70px);
}
@media screen and (min-width: 1200px) {
  .trainings-block .content .grid .training-item__header__title {
    font-size: 18px;
  }
}
.trainings-block .content .grid .training-item__header__icon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.trainings-block .content .grid .training-item__header__icon svg .circle {
  fill: none;
  stroke: #180636;
  transition: all 0.35s ease-in-out;
}
.trainings-block .content .grid .training-item__header__icon svg .arrow {
  fill: #180636;
  transition: all 0.35s ease-in-out;
}
.trainings-block .content .grid .training-item__content {
  width: calc(100% - 70px);
}
.trainings-block .content .grid .training-item__content .editor {
  font-size: 14px;
}
.trainings-block .content .grid .training-item:hover .training-item__header__icon svg .circle {
  fill: none;
  stroke: #3E3CFF;
}
.trainings-block .content .grid .training-item:hover .training-item__header__icon svg .arrow {
  fill: #3E3CFF;
}

.about-block {
  padding-block: 3rem;
  background-color: #E8E8FD;
}
@media screen and (min-width: 1200px) {
  .about-block {
    padding-block: 8rem;
  }
}
.about-block .content .grid__title {
  grid-column: 1/-1;
}
.about-block .content .grid__title .title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  margin-block: 0 2rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .about-block .content .grid__title .title {
    font-size: 40px;
  }
}
.about-block .content .grid__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.about-block .content .grid__content {
  grid-column: 1/-1;
}
.about-block .content .grid__content .item:not(:last-child) {
  margin-bottom: 2rem;
}
@media screen and (min-width: 1200px) {
  .about-block .content .grid__content .item:not(:last-child) {
    margin-bottom: 4rem;
  }
}
@media screen and (min-width: 1200px) {
  .about-block .content .grid__content .item {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
    align-items: center;
  }
}
.about-block .content .grid__content .item__image {
  border-radius: 0.75rem 0.75rem 0 0;
  aspect-ratio: 4/3;
  overflow: hidden;
}
@media screen and (min-width: 1200px) {
  .about-block .content .grid__content .item__image {
    border-radius: 0.75rem;
  }
}
.about-block .content .grid__content .item__image .img-container {
  height: 100%;
}
.about-block .content .grid__content .item__image .img-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.about-block .content .grid__content .item__image .img-container.has-video {
  display: flex;
}
.about-block .content .grid__content .item__content {
  background-color: #FFFFFF;
  border-radius: 0 0 0.75rem 0.75rem;
  padding: 1rem;
}
@media screen and (min-width: 768px) {
  .about-block .content .grid__content .item__content {
    padding: 2rem;
  }
}
@media screen and (min-width: 1200px) {
  .about-block .content .grid__content .item__content {
    border-radius: 0.75rem;
  }
}
.about-block .content .grid__content .item__content .title {
  font-size: 14px;
  font-weight: 700;
  margin-block: 0 1rem;
}
@media screen and (min-width: 1200px) {
  .about-block .content .grid__content .item__content .title {
    font-size: 32px;
  }
}
.about-block .content .grid__content .item__content .list-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-block: 1rem;
}
@media screen and (min-width: 576px) {
  .about-block .content .grid__content .item__content .list-item {
    flex-direction: row;
  }
}
.about-block .content .grid__content .item__content .list-item:last-child {
  margin-bottom: 0;
}
.about-block .content .grid__content .item__content .list-item__icon {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 576px) {
  .about-block .content .grid__content .item__content .list-item__content {
    width: calc(100% - 35px - 1rem);
  }
}
@media screen and (min-width: 1200px) {
  .about-block .content .grid__content .item.img-left .item__image {
    grid-column: 1/span 7;
    grid-row: 1/-1;
    position: relative;
    z-index: 10;
  }
  .about-block .content .grid__content .item.img-left .item__content {
    grid-column: 7/span 6;
    grid-row: 1/-1;
    position: relative;
    z-index: 20;
  }
}
@media screen and (min-width: 1200px) {
  .about-block .content .grid__content .item.img-right .item__image {
    grid-column: 6/span 7;
    grid-row: 1/-1;
    position: relative;
    z-index: 10;
  }
  .about-block .content .grid__content .item.img-right .item__content {
    grid-column: 1/span 6;
    grid-row: 1/-1;
    position: relative;
    z-index: 20;
  }
}

.our-values {
  padding-block: 3rem;
  background-color: #FFFFFF;
}
@media screen and (min-width: 1200px) {
  .our-values {
    padding-block: 8rem;
  }
}
.our-values .content .grid {
  row-gap: 1.5rem;
}
@media screen and (min-width: 1200px) {
  .our-values .content .grid {
    row-gap: 2rem;
  }
}
.our-values .content .grid__title {
  grid-column: 1/-1;
}
.our-values .content .grid__title .title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  margin-block: 0;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .our-values .content .grid__title .title {
    font-size: 40px;
  }
}
.our-values .content .grid__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.our-values .content .grid__intro {
  grid-column: 1/-1;
}
.our-values .content .grid__intro .editor {
  font-size: 14px;
}
@media screen and (min-width: 1200px) {
  .our-values .content .grid__intro .editor {
    font-size: 18px;
  }
}
.our-values .content .grid__informations {
  grid-column: 1/-1;
}
.our-values .content .grid__informations .item {
  padding-inline: 0;
  position: relative;
}
.our-values .content .grid__informations .item:not(:last-child) {
  margin-bottom: 45px;
}
.our-values .content .grid__informations .item:not(:last-child)::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #180636;
  position: absolute;
  bottom: -21px;
  left: 0;
}
@media screen and (min-width: 768px) {
  .our-values .content .grid__informations .item:not(:last-child)::after {
    content: none;
  }
}
.our-values .content .grid__informations .item-title {
  font-size: 14px;
  font-weight: 700;
  margin-block: 0 1rem;
  text-transform: uppercase;
}
@media screen and (min-width: 1200px) {
  .our-values .content .grid__informations .item-title {
    font-size: 20px;
  }
}
.our-values .content .grid__informations .item .editor {
  font-size: 14px;
}
@media screen and (min-width: 1200px) {
  .our-values .content .grid__informations .item .editor {
    font-size: 16px;
  }
}
.our-values .content .grid .action {
  grid-column: 1/-1;
}
@media screen and (min-width: 576px) {
  .our-values .content .grid .action {
    display: flex;
    justify-content: center;
  }
}
.our-values .content .grid .action .btn {
  max-width: 100%;
}
@media screen and (min-width: 576px) {
  .our-values .content .grid .action .btn {
    max-width: max-content;
  }
}

.our-team {
  padding-block: 3rem;
  background-color: #FFFFFF;
}
@media screen and (min-width: 1200px) {
  .our-team {
    padding-block: 8rem;
  }
}
@media screen and (min-width: 1200px) {
  .our-team .content .grid {
    row-gap: 2rem;
  }
}
.our-team .content .grid__title {
  grid-column: 1/-1;
}
.our-team .content .grid__title .title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  margin-block: 0;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .our-team .content .grid__title .title {
    font-size: 40px;
  }
}
.our-team .content .grid__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.our-team .content .grid__content {
  grid-column: 1/-1;
  display: grid;
  gap: 1rem;
}
@media screen and (min-width: 768px) {
  .our-team .content .grid__content {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1200px) {
  .our-team .content .grid__content {
    grid-template-columns: repeat(4, 1fr);
    row-gap: 2rem;
  }
}
.our-team .content .grid__content .subtitle {
  grid-column: 1/-1;
  font-size: 18px;
  font-weight: 700;
}
.our-team .content .grid__content .item {
  grid-column: 1/-1;
  border-radius: 0.75rem;
  border: 0.063rem solid #DADBE0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .our-team .content .grid__content .item {
    grid-column: span 1;
  }
}
.our-team .content .grid__content .item .thumb {
  display: none;
  position: relative;
}
@media screen and (min-width: 768px) {
  .our-team .content .grid__content .item .thumb {
    display: block;
  }
}
.our-team .content .grid__content .item .thumb img {
  aspect-ratio: 0.9;
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 100%;
}
.our-team .content .grid__content .item .thumb .linkedin-link {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.our-team .content .grid__content .item .thumb .linkedin-link svg {
  fill: #180636;
  transition: all 0.35s ease-in-out;
}
.our-team .content .grid__content .item .thumb .linkedin-link:hover svg {
  fill: #5A5974;
}
.our-team .content .grid__content .item .content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media screen and (min-width: 1200px) {
  .our-team .content .grid__content .item .content {
    padding: 1.5rem;
  }
}
.our-team .content .grid__content .item .content .name {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
@media screen and (min-width: 1200px) {
  .our-team .content .grid__content .item .content .name {
    text-transform: none;
  }
}
.our-team .content .grid__content .item .content .job {
  font-size: 14px;
}
.our-team .content .grid__content .item .content .action {
  margin-top: auto;
}
.our-team .content .grid__content .item .content .action .link {
  margin-top: 1rem;
}
@media screen and (min-width: 768px) {
  .our-team .content .grid__content.founders .item {
    grid-column: span 2;
  }
}
.our-team .content .grid__content.founders .item .thumb {
  display: block;
  position: relative;
}
@media screen and (min-width: 768px) {
  .our-team .content .grid__content.founders .item .thumb img {
    aspect-ratio: 4/3;
  }
}

.team-rh {
  padding-block: 3rem;
  background-color: #FFFFFF;
}
@media screen and (min-width: 1200px) {
  .team-rh {
    padding-block: 8rem;
  }
}
@media screen and (min-width: 1200px) {
  .team-rh .content .grid {
    align-items: center;
  }
}
.team-rh .content .grid__title {
  grid-column: 1/-1;
}
@media screen and (min-width: 768px) {
  .team-rh .content .grid__title {
    grid-column: span 6;
  }
}
@media screen and (min-width: 1200px) {
  .team-rh .content .grid__title {
    grid-column: span 4;
  }
}
.team-rh .content .grid__title .title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  margin-block: 0;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .team-rh .content .grid__title .title {
    font-size: 40px;
  }
}
.team-rh .content .grid__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.team-rh .content .grid__title .editor {
  margin-top: 2rem;
}
.team-rh .content .grid__title .action {
  margin-top: 2rem;
}
.team-rh .content .grid__title .action .btn {
  max-width: 100%;
}
@media screen and (min-width: 768px) {
  .team-rh .content .grid__title .action .btn {
    max-width: max-content;
  }
}
.team-rh .content .grid__content {
  grid-column: 1/-1;
  display: grid;
  gap: 1rem;
}
@media screen and (min-width: 768px) {
  .team-rh .content .grid__content {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1200px) {
  .team-rh .content .grid__content {
    grid-column: 7/span 6;
  }
}
.team-rh .content .grid__content .item {
  border-radius: 0.75rem;
  border: 0.063rem solid #DADBE0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .team-rh .content .grid__content .item {
    grid-column: span 1;
  }
}
.team-rh .content .grid__content .item .thumb {
  display: none;
  position: relative;
}
@media screen and (min-width: 768px) {
  .team-rh .content .grid__content .item .thumb {
    display: block;
  }
}
.team-rh .content .grid__content .item .thumb img {
  aspect-ratio: 0.9;
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 100%;
}
.team-rh .content .grid__content .item .content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
}
@media screen and (min-width: 1200px) {
  .team-rh .content .grid__content .item .content {
    padding: 1.5rem;
  }
}
.team-rh .content .grid__content .item .content .name {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
@media screen and (min-width: 1200px) {
  .team-rh .content .grid__content .item .content .name {
    text-transform: none;
  }
}
.team-rh .content .grid__content .item .content .job {
  font-size: 14px;
}
.team-rh .content .grid__content .item .content .action {
  margin-top: auto;
}
.team-rh .content .grid__content .item .content .action .link {
  margin-top: 1rem;
}

.technical-informations {
  padding-block: 3rem;
  background-color: #FFFFFF;
}
@media screen and (min-width: 1200px) {
  .technical-informations {
    padding-block: 8rem;
  }
}
.technical-informations .content .grid {
  row-gap: 1.5rem;
}
@media screen and (min-width: 1200px) {
  .technical-informations .content .grid {
    row-gap: 2rem;
  }
}
.technical-informations .content .grid__title {
  grid-column: 1/-1;
}
.technical-informations .content .grid__title .title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  margin-block: 0;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .technical-informations .content .grid__title .title {
    font-size: 40px;
  }
}
.technical-informations .content .grid__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.technical-informations .content .grid__item {
  grid-column: 1/-1;
}
@media screen and (min-width: 1200px) {
  .technical-informations .content .grid__item {
    grid-column: span 6;
  }
}
.technical-informations .content .grid__item .element-item {
  border-radius: 0.75rem;
  border: 0.063rem solid #DADBE0;
  overflow: hidden;
}
.technical-informations .content .grid__item .element-item__thumb {
  background-color: #ECF2FE;
}
.technical-informations .content .grid__item .element-item__thumb img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
}
.technical-informations .content .grid__item .element-item__content {
  padding: 2rem 1rem;
}
@media screen and (min-width: 768px) {
  .technical-informations .content .grid__item .element-item__content {
    padding-inline: 2.5rem;
  }
}
.technical-informations .content .grid__item .element-item__title {
  font-size: 25px;
  font-weight: 700;
  margin-block: 0 1.5rem;
}
@media screen and (min-width: 1200px) {
  .technical-informations .content .grid__item .element-item__title {
    font-size: 32px;
  }
}
.technical-informations .content .grid__item .element-item__subtitle {
  font-size: 14px;
  color: #FF1435;
  margin-block: 1.5rem 0;
}
@media screen and (min-width: 1200px) {
  .technical-informations .content .grid__item .element-item__subtitle {
    font-size: 20px;
  }
}
.technical-informations .content .grid__item .element-item .editor {
  margin-block: 2rem;
  font-size: 14px;
  position: relative;
}
.technical-informations .content .grid__item .element-item .editor ul {
  padding-left: 1.5rem;
}
.technical-informations .content .grid__item .element-item .editor ul li {
  position: relative;
}
.technical-informations .content .grid__item .element-item .editor ul li::marker {
  content: none;
}
.technical-informations .content .grid__item .element-item .editor ul li::before {
  content: "";
  position: absolute;
  top: 0.75rem;
  left: -1.5rem;
  width: 14px;
  height: 1px;
  background-color: #FF1435;
}
.technical-informations .content .grid__item .element-item .editor::after {
  content: "";
  display: block;
  margin-block: 2rem;
  width: 100%;
  height: 0.063rem;
  background-color: #180636;
}
.technical-informations .content .grid__item .element-item .accordion-item {
  margin-top: 2rem;
}
.technical-informations .content .grid__item .element-item .accordion-header {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.technical-informations .content .grid__item .element-item .accordion-header--title {
  font-size: 14px;
  font-weight: 700;
}
@media screen and (min-width: 1200px) {
  .technical-informations .content .grid__item .element-item .accordion-header--title {
    font-size: 18px;
  }
}
.technical-informations .content .grid__item .element-item .accordion-header--title::after {
  content: none;
}
.technical-informations .content .grid__item .element-item .accordion-header--icon {
  width: 1rem;
  height: 1rem;
}
@media screen and (min-width: 1200px) {
  .technical-informations .content .grid__item .element-item .accordion-header--icon {
    width: 2rem;
    height: 2rem;
  }
}
.technical-informations .content .grid__item .element-item .accordion-header--icon svg {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.technical-informations .content .grid__item .element-item .accordion-header--icon svg polygon {
  transform-origin: 50% 50%;
  transition: all 0.35s ease-in-out;
}
.technical-informations .content .grid__item .element-item .accordion-header.active .accordion-header--icon svg polygon {
  transform: rotate(-180deg);
}
.technical-informations .content .grid__item .element-item .accordion-content .editor::after {
  content: none;
}
.technical-informations .content .grid__item .element-item .accordion-content .editor:last-child {
  margin-bottom: 0;
}
.technical-informations .content .grid__item .element-item .accordion-content .table-item {
  display: grid;
  position: relative;
}
@media screen and (min-width: 768px) {
  .technical-informations .content .grid__item .element-item .accordion-content .table-item {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
}
.technical-informations .content .grid__item .element-item .accordion-content .table-item:not(:last-child)::after {
  content: "";
  grid-column: 1/-1;
  display: block;
  margin-block: 1rem;
  height: 0.063rem;
  width: 100%;
  background-color: #000000;
}
.technical-informations .content .grid__item .element-item .accordion-content .table-item__title {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 0.75rem;
}
@media screen and (min-width: 768px) {
  .technical-informations .content .grid__item .element-item .accordion-content .table-item__title {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1200px) {
  .technical-informations .content .grid__item .element-item .accordion-content .table-item__title {
    font-size: 20px;
  }
}
.technical-informations .content .grid__item .element-item .accordion-content .table-item__line {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  font-size: 14px;
}
@media screen and (min-width: 768px) {
  .technical-informations .content .grid__item .element-item .accordion-content .table-item__line {
    grid-column: 2/span 1;
  }
}
.technical-informations .content .grid__item .element-item .accordion-content .table-item__line.highlight {
  font-weight: 700;
}
.technical-informations .content .grid__item .element-item .action {
  margin-top: 2rem;
}
@media screen and (min-width: 1200px) {
  .technical-informations .content .grid__item .element-item .action {
    display: flex;
    justify-content: center;
  }
}
.technical-informations .content .grid__item .element-item .action .btn {
  max-width: 100%;
}
@media screen and (min-width: 1200px) {
  .technical-informations .content .grid__item .element-item .action .btn {
    max-width: max-content;
  }
}

.our-history {
  padding-block: 3rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .our-history {
    padding-block: 8rem;
  }
}
.our-history .bg-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.our-history .bg-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.our-history .content {
  position: relative;
  z-index: 10;
}
.our-history .content .grid__title {
  grid-column: 1/-1;
}
.our-history .content .grid__title .title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-block: 0;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .our-history .content .grid__title .title {
    font-size: 40px;
  }
}
.our-history .content .grid__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.our-history .content .grid__item {
  grid-column: 1/-1;
}
@media screen and (min-width: 768px) {
  .our-history .content .grid__item {
    grid-column: span 6;
  }
}
@media screen and (min-width: 1200px) {
  .our-history .content .grid__item {
    grid-column: span 5;
  }
}
@media screen and (min-width: 1200px) {
  .our-history .content .grid__item.first {
    grid-column: 2/span 5;
  }
}
@media screen and (min-width: 768px) {
  .our-history .content .grid__item:not(.first) {
    margin-top: 2rem;
  }
}
@media screen and (min-width: 1200px) {
  .our-history .content .grid__item:not(.first) {
    margin-top: 4rem;
  }
}
.our-history .content .grid__item .item {
  padding: 1rem;
  background-color: #FFFFFF;
  border-radius: 0.75rem;
}
@media screen and (min-width: 1200px) {
  .our-history .content .grid__item .item {
    padding: 2.5rem;
  }
}
.our-history .content .grid__item .item--header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.our-history .content .grid__item .item--header .start,
.our-history .content .grid__item .item--header .end {
  font-size: 20px;
  color: #FF1435;
}
@media screen and (min-width: 1200px) {
  .our-history .content .grid__item .item--header .start,
  .our-history .content .grid__item .item--header .end {
    font-size: 40px;
  }
}
.our-history .content .grid__item .item--header .deco svg {
  stroke: #FF1435;
}
.our-history .content .grid__item .item--title {
  font-size: 14px;
  font-weight: 700;
  margin-block: 1rem 2rem;
}
@media screen and (min-width: 1200px) {
  .our-history .content .grid__item .item--title {
    font-size: 32px;
    margin-block: 2.5rem;
  }
}
.our-history .content .grid__item .item .editor {
  font-size: 14px;
  position: relative;
}
.our-history .content .grid__item .item .editor ul {
  padding-left: 1.5rem;
}
.our-history .content .grid__item .item .editor ul li {
  position: relative;
}
.our-history .content .grid__item .item .editor ul li::marker {
  content: none;
}
.our-history .content .grid__item .item .editor ul li::before {
  content: "";
  position: absolute;
  top: 0.75rem;
  left: -1.5rem;
  width: 14px;
  height: 1px;
  background-color: #FF1435;
}

.our-promises {
  padding-block: 3rem;
}
@media screen and (min-width: 1200px) {
  .our-promises {
    padding-block: 8rem;
  }
}
.our-promises .content .grid__content {
  grid-column: 1/-1;
}
.our-promises .content .grid__content .title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  margin-block: 0 1rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .our-promises .content .grid__content .title {
    font-size: 40px;
    margin-bottom: 2rem;
  }
}
.our-promises .content .grid__content .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.our-promises .content .grid__content .editor {
  font-size: 14px;
}
.our-promises .content .grid__content .action {
  margin-top: 2rem;
}
.our-promises .content .grid__content .action .btn {
  max-width: 100%;
}
@media screen and (min-width: 1200px) {
  .our-promises .content .grid__content .action .btn {
    max-width: max-content;
  }
}

.image-quote {
  /* padding-block: calc($spacer-xl * 1.5); */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 60px;
  /*   aspect-ratio: 16/9; */
  position: relative;
  /*  @media screen and (min-width: 1200px) {
    padding-block: calc($spacer-xxl * 2);
    aspect-ratio: 16/6;
  } */
}
@media screen and (min-width: 768px) {
  .image-quote {
    padding-top: 95px;
    padding-bottom: 95px;
  }
}
.image-quote::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(24, 6, 54, 0.3019607843);
  z-index: 8;
}
.image-quote .bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.image-quote .bg-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.image-quote .content {
  position: relative;
  z-index: 10;
}
.image-quote .content .grid__title {
  grid-column: 1/-1;
  position: relative;
  z-index: 10;
}
@media screen and (min-width: 768px) {
  .image-quote .content .grid__title {
    grid-column: 2/span 8;
  }
}
.image-quote .content .grid__title .citation {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  font-size: 20px;
  line-height: 0.9;
  text-align: center;
  color: #FFFFFF;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .image-quote .content .grid__title .citation {
    font-size: 80px;
    line-height: 96px;
  }
}
.image-quote .content .grid__title .citation span,
.image-quote .content .grid__title .citation b,
.image-quote .content .grid__title .citation strong {
  font-family: "ivypresto-display", serif;
  font-style: italic;
}
.image-quote .content .grid__title .citation::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}

.form-block {
  padding-block: 0 3rem;
  margin-top: -1.5rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .form-block {
    padding-block: 0 8rem;
    margin-top: -4rem;
  }
}
.form-block .bg-container {
  position: absolute;
  top: 1.5rem;
  left: 0;
  width: 100%;
  height: calc(100% - 1.5rem);
}
@media screen and (min-width: 1200px) {
  .form-block .bg-container {
    top: 4rem;
    height: calc(100% - 4rem);
  }
}
.form-block .bg-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.form-block .content {
  position: relative;
  z-index: 10;
  margin-top: -1.5rem;
}
@media screen and (min-width: 1200px) {
  .form-block .content {
    margin-top: -4rem;
  }
}
.form-block .content .grid .form {
  grid-column: 1/-1;
  background-color: #FFFFFF;
  border-radius: 0.75rem;
  border: 0.063rem solid #DADBE0;
  padding: 1rem;
}
@media screen and (min-width: 1200px) {
  .form-block .content .grid .form {
    grid-column: 3/span 8;
    padding: 2.5rem;
  }
}
.form-block .content .grid--contact {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
@media screen and (min-width: 768px) {
  .form-block .content .grid--contact {
    grid-template-columns: repeat(8, 1fr);
    row-gap: 2rem;
  }
}
.form-block .content .grid--contact .full {
  grid-column: 1/-1;
}
.form-block .content .grid--contact .half {
  grid-column: 1/-1;
}
@media screen and (min-width: 768px) {
  .form-block .content .grid--contact .half {
    grid-column: span 4;
  }
}
.form-block .content .grid--contact .notice span,
.form-block .content .grid--contact .notice b,
.form-block .content .grid--contact .notice strong {
  font-weight: 400;
  color: #FF1435;
}
.form-block .content .grid--contact .form-group label {
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
@media screen and (min-width: 1200px) {
  .form-block .content .grid--contact .form-group label {
    font-size: 20px;
  }
}
.form-block .content .grid--contact .form-group label.required {
  position: relative;
}
.form-block .content .grid--contact .form-group label.required::after {
  content: "*";
  display: inline-block;
  color: #FF1435;
}
.form-block .content .grid--contact .form-group .wpcf7-not-valid {
  border-color: #FF1435;
}
.form-block .content .grid--contact .form-group .wpcf7-not-valid-tip {
  font-size: 14px;
  color: #FF1435;
}
@media screen and (min-width: 768px) {
  .form-block .content .grid--contact .action {
    display: flex;
    justify-content: center;
  }
}
.form-block .content .grid--contact .action .btn {
  max-width: 100%;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .form-block .content .grid--contact .action .btn {
    max-width: max-content;
  }
}
.form-block .content .grid--contact .form-control {
  border: 0.063rem solid #DADBE0;
  border-radius: 0.75rem;
  transition: all 0.35s ease-in-out;
  color: #180636;
  padding: 0.25rem 0.5rem;
  width: 100%;
}
.form-block .content .grid--contact .form-control:hover {
  border-color: #180636;
}
.form-block .content .grid--contact .form-control:active {
  border-color: #180636;
}
.form-block .content .grid--contact .form-control:focus {
  border-color: #180636;
  outline: 0.063rem solid #000000;
  outline-offset: 0.125rem;
}
.form-block .content .grid--contact .nice-select {
  width: 100%;
  border-radius: 0.75rem;
  border: 0.063rem solid #DADBE0;
}
.form-block .content .grid--contact .nice-select:hover {
  border-color: #180636;
}
.form-block .content .grid--contact .nice-select:active {
  border-color: #180636;
}
.form-block .content .grid--contact .nice-select:focus {
  border-color: #180636;
}
.form-block .content .grid--contact .nice-select-dropdown {
  margin-top: 0;
  box-shadow: 0 0 0 1px #180636;
  border-radius: 0 0 0.75rem 0.75rem;
  width: 100%;
  transform: scale(1) translateY(-1rem);
  transition: all 0.35s ease-in-out;
}
.form-block .content .grid--contact .nice-select.open {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-block .content .grid--contact .nice-select.open .nice-select-dropdown {
  transform: translateY(0);
}
.form-block .content .grid--contact .nice-select .option {
  color: #180636;
  opacity: 0.4;
  transition: all 0.35s ease-in-out;
}
.form-block .content .grid--contact .nice-select .option:hover {
  background-color: #ECF2FE;
  opacity: 1;
}
.form-block .content .grid--contact .nice-select .option.selected {
  font-weight: 400;
}
.form-block .content .grid--contact .nice-select .option.selected.focus {
  background-color: #ECF2FE;
  opacity: 1;
}

.wpcf7 form .wpcf7-response-output {
  border-width: 0.063rem !important;
  border-radius: 0.75rem;
  padding: 1rem !important;
  margin: 1rem 0 0 !important;
  text-align: center;
}
.wpcf7 form.invalid .wpcf7-response-output {
  border-color: #FF1435 !important;
}
.wpcf7 form.sent .wpcf7-response-output {
  border-color: #7FF9EB !important;
  color: #180636;
}

.commitment-block {
  padding-block: 3rem;
}
@media screen and (min-width: 1200px) {
  .commitment-block {
    padding-block: 8rem;
  }
}
.commitment-block .content .grid {
  row-gap: 2.5rem;
}
.commitment-block .content .grid__title {
  grid-column: 1/-1;
}
@media screen and (min-width: 576px) {
  .commitment-block .content .grid__title {
    grid-column: 2/span 8;
  }
}
@media screen and (min-width: 768px) {
  .commitment-block .content .grid__title {
    grid-column: 1/-1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.commitment-block .content .grid__title .title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  margin-block: 0;
}
@media screen and (min-width: 1200px) {
  .commitment-block .content .grid__title .title {
    font-size: 40px;
  }
}
.commitment-block .content .grid__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.commitment-block .content .grid__title .action {
  margin-top: 1rem;
}
@media screen and (min-width: 768px) {
  .commitment-block .content .grid__title .action {
    margin-top: 0;
  }
}
.commitment-block .content .grid__title .action .btn {
  max-width: 100%;
}
@media screen and (min-width: 768px) {
  .commitment-block .content .grid__title .action .btn {
    max-width: max-content;
  }
}
.commitment-block .content .grid__intro {
  grid-column: 1/-1;
}
.commitment-block .content .grid__item {
  grid-column: 1/-1;
  position: relative;
}
@media screen and (min-width: 768px) {
  .commitment-block .content .grid__item {
    grid-column: span 6;
  }
}
@media screen and (min-width: 1200px) {
  .commitment-block .content .grid__item {
    grid-column: span 3;
  }
}
.commitment-block .content .grid__item:not(:last-child)::after {
  content: "";
  display: block;
  margin-top: 2.5rem;
  height: 0.063rem;
  background-color: #180636;
}
@media screen and (min-width: 768px) {
  .commitment-block .content .grid__item:not(:last-child)::after {
    content: none;
  }
}
.commitment-block .content .grid__item .item {
  position: relative;
}
.commitment-block .content .grid__item .item--number {
  font-size: 20px;
}
@media screen and (min-width: 1200px) {
  .commitment-block .content .grid__item .item--number {
    font-size: 40px;
  }
}
.commitment-block .content .grid__item .item--title {
  font-size: 14px;
}
@media screen and (min-width: 1200px) {
  .commitment-block .content .grid__item .item--title {
    font-size: 18px;
  }
}
.commitment-block .content .grid__item .item hr {
  display: none;
}
@media screen and (min-width: 768px) {
  .commitment-block .content .grid__item .item hr {
    display: block;
    margin-block: 1.5rem;
    height: 0.063rem;
    background-color: #180636;
    border: none;
  }
}
.commitment-block .content .grid__item .item .editor {
  margin-top: 1.5rem;
  font-size: 14px;
}
@media screen and (min-width: 1200px) {
  .commitment-block .content .grid__item .item .editor {
    font-size: 18px;
  }
}

.title-description-media {
  padding-block: 3rem;
}
@media screen and (min-width: 1200px) {
  .title-description-media {
    padding-block: 8rem;
  }
}
.title-description-media .content .grid__title {
  grid-column: 1/-1;
  position: relative;
}
@media screen and (min-width: 768px) {
  .title-description-media .content .grid__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.title-description-media .content .grid__title .title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  margin-block: 0;
}
@media screen and (min-width: 1200px) {
  .title-description-media .content .grid__title .title {
    font-size: 40px;
  }
}
.title-description-media .content .grid__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.title-description-media .content .grid__title .date {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
@media screen and (min-width: 1200px) {
  .title-description-media .content .grid__title .date {
    font-size: 18px;
  }
}
.title-description-media .content .grid hr {
  grid-column: 1/-1;
  margin-block: 0 1rem;
  color: #180636;
}
.title-description-media .content .grid__media {
  grid-column: 1/-1;
}
@media screen and (min-width: 768px) {
  .title-description-media .content .grid__media {
    grid-column: 1/span 6;
    grid-row: 3/span 1;
  }
}
.title-description-media .content .grid__media .img-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.title-description-media .content .grid__content {
  grid-column: 1/-1;
}
@media screen and (min-width: 768px) {
  .title-description-media .content .grid__content {
    grid-column: 8/span 5;
    grid-row: 3/span 1;
  }
}
.title-description-media .content .grid__content .editor h2 {
  font-size: 18px;
  font-weight: 700;
  color: #FF1435;
}
@media screen and (min-width: 1200px) {
  .title-description-media .content .grid__content .editor h2 {
    font-size: 28px;
  }
}
.title-description-media .content .grid__content .editor h3 {
  font-size: 18px;
  font-weight: 700;
}
@media screen and (min-width: 1200px) {
  .title-description-media .content .grid__content .editor h3 {
    font-size: 20px;
  }
}
.title-description-media .content .grid__content .editor h4,
.title-description-media .content .grid__content .editor h5,
.title-description-media .content .grid__content .editor h6 {
  font-weight: 700;
}
@media screen and (min-width: 768px) {
  .title-description-media.img-right .content .grid__media {
    grid-column: 7/span 6;
    grid-row: 3/span 1;
  }
}
@media screen and (min-width: 768px) {
  .title-description-media.img-right .content .grid__content {
    grid-column: 1/span 5;
    grid-row: 3/span 1;
  }
}
@media screen and (min-width: 768px) {
  .title-description-media.img-full .content .grid__media {
    grid-column: 1/-1;
    grid-row: 3/span 1;
  }
}
.title-description-media.img-full .content .grid__content {
  grid-column: 1/-1;
  grid-row: 4/span 1;
}

.title-media-description {
  padding-block: 3rem;
}
@media screen and (min-width: 1200px) {
  .title-media-description {
    padding-block: 8rem;
  }
}
.title-media-description .content .grid__title {
  grid-column: 1/-1;
  position: relative;
}
@media screen and (min-width: 768px) {
  .title-media-description .content .grid__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.title-media-description .content .grid__title .title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  margin-block: 0;
}
@media screen and (min-width: 1200px) {
  .title-media-description .content .grid__title .title {
    font-size: 40px;
  }
}
.title-media-description .content .grid__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.title-media-description .content .grid__title .date {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
@media screen and (min-width: 1200px) {
  .title-media-description .content .grid__title .date {
    font-size: 18px;
  }
}
.title-media-description .content .grid hr {
  grid-column: 1/-1;
  margin-block: 0 1rem;
  color: #180636;
}
.title-media-description .content .grid__media {
  grid-column: 1/-1;
}
.title-media-description .content .grid__content {
  grid-column: 1/-1;
}
.title-media-description .content .grid__content .editor {
  /* h3 {
                font-size: 18px;
                font-weight: $fw-700;
                @media screen and (min-width: 1200px) {
                    font-size: 20px;
                }
            } */
}
.title-media-description .content .grid__content .editor h2,
.title-media-description .content .grid__content .editor h3 {
  font-size: 18px;
  font-weight: 700;
  color: #FF1435;
}
@media screen and (min-width: 1200px) {
  .title-media-description .content .grid__content .editor h2,
  .title-media-description .content .grid__content .editor h3 {
    font-size: 28px;
  }
}
.title-media-description .content .grid__content .editor h4,
.title-media-description .content .grid__content .editor h5,
.title-media-description .content .grid__content .editor h6 {
  font-weight: 700;
}

.members {
  margin-block: 3rem;
}
@media screen and (min-width: 1200px) {
  .members {
    margin-block: 8rem;
  }
}
.members .content .grid__title {
  grid-column: 1/-1;
  position: relative;
}
@media screen and (min-width: 768px) {
  .members .content .grid__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.members .content .grid__title .title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  margin-block: 0;
}
@media screen and (min-width: 1200px) {
  .members .content .grid__title .title {
    font-size: 40px;
  }
}
.members .content .grid__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.members .content .grid hr {
  grid-column: 1/-1;
  margin-block: 0 1rem;
  color: #180636;
}
.members .content .grid__media {
  grid-column: 1/-1;
}
@media screen and (min-width: 768px) {
  .members .content .grid__media {
    grid-column: 7/span 6;
    grid-row: 3/span 1;
  }
}
.members .content .grid__media .img-container img {
  object-fit: cover;
  width: 100%;
}
.members .content .grid__content {
  grid-column: 1/-1;
}
@media screen and (min-width: 768px) {
  .members .content .grid__content {
    grid-column: 1/span 5;
    grid-row: 3/span 1;
  }
}
.members .content .grid__content .editor {
  font-size: 14px;
}
.members .content .grid__content .action {
  margin-top: 1rem;
}
@media screen and (min-width: 1200px) {
  .members .content .grid__content .action {
    margin-top: 2rem;
  }
}
.members .content .grid__content .action .btn {
  max-width: 100%;
}
@media screen and (min-width: 1200px) {
  .members .content .grid__content .action .btn {
    max-width: max-content;
  }
}

.work {
  padding-block: 3rem;
}
@media screen and (min-width: 1200px) {
  .work {
    padding-block: 8rem;
  }
}
.work .content .grid {
  row-gap: 2rem;
}
@media screen and (min-width: 1200px) {
  .work .content .grid {
    row-gap: 4rem;
  }
}
.work .content .grid__title {
  grid-column: 1/-1;
}
.work .content .grid__title .title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  margin-block: 0;
}
@media screen and (min-width: 1200px) {
  .work .content .grid__title .title {
    font-size: 40px;
  }
}
.work .content .grid__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.work .content .grid__item {
  grid-column: 1/-1;
}
.work .content .grid__item .item {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}
@media screen and (min-width: 1200px) {
  .work .content .grid__item .item {
    grid-template-columns: repeat(2, 1fr);
  }
}
.work .content .grid__item .item--content .item__title {
  font-size: 14px;
  font-weight: 700;
}
@media screen and (min-width: 1200px) {
  .work .content .grid__item .item--content .item__title {
    font-size: 18px;
  }
}
.work .content .grid__item .item--content .editor {
  margin-top: 1rem;
}
@media screen and (min-width: 1200px) {
  .work .content .grid__item .item.player-left .item--video {
    grid-column: 1/span 1;
    grid-row: 1/span 1;
  }
}
@media screen and (min-width: 1200px) {
  .work .content .grid__item .item.player-left .item--content {
    grid-column: 2/span 1;
    grid-row: 1/span 1;
  }
}
@media screen and (min-width: 1200px) {
  .work .content .grid__item .item.player-right .item--video {
    grid-column: 2/span 1;
    grid-row: 1/span 1;
  }
}
@media screen and (min-width: 1200px) {
  .work .content .grid__item .item.player-right .item--content {
    grid-column: 1/span 1;
    grid-row: 1/span 1;
  }
}

.subsidiaries-block {
  padding-block: 3rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .subsidiaries-block {
    padding-block: 8rem;
  }
}
.subsidiaries-block::after {
  content: "";
  position: absolute;
  top: -3rem;
  left: 0;
  width: 100%;
  height: calc(100% + 6rem);
  background-color: #FFFFFF;
  z-index: -1;
}
@media screen and (min-width: 1200px) {
  .subsidiaries-block::after {
    top: -8rem;
    height: calc(100% + 16rem);
  }
}
.subsidiaries-block .map {
  display: none;
}
@media screen and (min-width: 576px) {
  .subsidiaries-block .map {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    max-width: 1440px;
  }
}
@media screen and (min-width: 576px) {
  .subsidiaries-block .map img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}
.subsidiaries-block .content .grid {
  position: relative;
  z-index: 10;
}
.subsidiaries-block .content .grid .partners__content {
  grid-column: span 4;
  padding: 1.5rem;
  border-radius: 0.75rem;
  background-color: #ECF2FE;
}
@media screen and (min-width: 576px) {
  .subsidiaries-block .content .grid .partners__content {
    grid-column: span 6;
  }
}
@media screen and (min-width: 768px) {
  .subsidiaries-block .content .grid .partners__content {
    grid-column: span 7;
  }
}
@media screen and (min-width: 1200px) {
  .subsidiaries-block .content .grid .partners__content {
    grid-column: span 5;
    padding: 2.5rem 4rem 2.5rem 2.5rem;
  }
}
.subsidiaries-block .content .grid .partners__content .title {
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 2rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .subsidiaries-block .content .grid .partners__content .title {
    font-size: 40px;
  }
}
.subsidiaries-block .content .grid .partners__content .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.subsidiaries-block .content .grid .partners__content .editor {
  font-size: 14px;
  font-weight: 400;
  margin-block: 2rem;
}
.subsidiaries-block .content .grid .partners__content .addresses--list .accordion-item {
  position: relative;
}
.subsidiaries-block .content .grid .partners__content .addresses--list .accordion-item:not(:last-child)::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.063rem;
  background-color: #3E3CFF;
}
.subsidiaries-block .content .grid .partners__content .addresses--list .accordion-item .accordion-header {
  padding: 1rem;
}
.subsidiaries-block .content .grid .partners__content .addresses--list .accordion-item .accordion-header--title {
  font-size: 14px;
  font-weight: 700;
}
.subsidiaries-block .content .grid .partners__content .addresses--list .accordion-item .accordion-content__inner {
  padding: 0 2rem 1rem;
}
.subsidiaries-block .content .grid .partners__content .addresses--list .item-title {
  font-size: 14px;
  font-weight: 700;
  padding: 1rem;
}
.subsidiaries-block .content .grid .partners__content .addresses--list .item-content__inner {
  padding: 0 2rem 1rem;
}

.gallery {
  padding-block: 3rem;
}
@media screen and (min-width: 1200px) {
  .gallery {
    padding-block: 8rem;
  }
}
.gallery__content .swiper {
  padding-inline: 1.5rem;
}
@media screen and (min-width: 1200px) {
  .gallery__content .swiper {
    padding-inline: 4rem;
  }
}
.gallery__content .swiper-slide {
  aspect-ratio: 16/8;
}
@media screen and (min-width: 1200px) {
  .gallery__content .swiper-slide {
    width: 75%;
  }
}
.gallery__content .swiper-slide img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 16/8;
}
.gallery__content .swiper__navigation {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 2rem;
}
@media screen and (min-width: 768px) {
  .gallery__content .swiper__navigation {
    justify-content: center;
  }
}
.gallery__content .swiper-pagination {
  position: initial;
  transform: none;
  width: max-content;
}
.gallery__content .swiper-pagination-bullet {
  background-color: #C1C1C1;
  opacity: 1;
}
.gallery__content .swiper-pagination-bullet-active {
  background-color: #3E3CFF;
}
.gallery__content .swiper-navigation {
  display: flex;
  align-items: center;
}
.gallery__content .swiper-prev, .gallery__content .swiper-next {
  position: initial;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.gallery__content .swiper-prev svg .circle, .gallery__content .swiper-next svg .circle {
  fill: none;
  stroke: #180636;
  transition: all 0.35s ease-in-out;
}
.gallery__content .swiper-prev svg .arrow, .gallery__content .swiper-next svg .arrow {
  fill: #180636;
  transition: all 0.35s ease-in-out;
}
.gallery__content .swiper-prev:hover svg .circle, .gallery__content .swiper-next:hover svg .circle {
  fill: none;
  stroke: #3E3CFF;
}
.gallery__content .swiper-prev:hover svg .arrow, .gallery__content .swiper-next:hover svg .arrow {
  fill: #3E3CFF;
}

.media {
  padding-block: 3rem;
}
@media screen and (min-width: 1200px) {
  .media {
    padding-block: 8rem;
  }
}
.media .content .grid__media {
  grid-column: 1/-1;
  width: 80%;
  margin: 0 auto;
}
.media .content .grid__media .img-container img {
  object-fit: cover;
  width: 100%;
}
.media .content .grid__media iframe {
  width: 100%;
}

.title-editor-full-width {
  padding-block: 3rem;
}
@media screen and (min-width: 1200px) {
  .title-editor-full-width {
    padding-block: 8rem;
  }
}
.title-editor-full-width.blue-dark-bg {
  background-color: #180636;
}
.title-editor-full-width.blue-gray-bg {
  background-color: #1C1534;
}
.title-editor-full-width .grid--content {
  grid-column: 1/-1;
}
.title-editor-full-width .grid--content .title {
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
  margin-block: 0 2.5rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .title-editor-full-width .grid--content .title {
    font-size: 40px;
    margin-bottom: 2.75rem;
  }
}
.title-editor-full-width .grid--content .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.title-editor-full-width .grid--content .editor strong,
.title-editor-full-width .grid--content .editor b {
  color: #FF1435;
  font-weight: 400;
}
.title-editor-full-width .grid--content .action {
  margin-top: 2rem;
}

.full-width-editor {
  padding-block: 3rem;
}
@media screen and (min-width: 1200px) {
  .full-width-editor {
    padding-block: 8rem;
  }
}
.full-width-editor.blue-dark-bg {
  background-color: #180636;
  color: white;
}
.full-width-editor.blue-gray-bg {
  background-color: #1C1534;
  color: white;
}
.full-width-editor .grid--content {
  grid-column: 1/-1;
}
.full-width-editor .grid--content .title {
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
  margin-block: 0 2.5rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .full-width-editor .grid--content .title {
    font-size: 40px;
    margin-bottom: 2.75rem;
  }
}
.full-width-editor .grid--content .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}

.dual-col-title-text-img {
  padding-block: 3rem;
}
@media screen and (min-width: 1200px) {
  .dual-col-title-text-img {
    padding-block: 8rem;
  }
}
.dual-col-title-text-img .grid__title {
  grid-column: 1/-1;
}
.dual-col-title-text-img .grid__title .title {
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
  margin-block: 0 2.5rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .dual-col-title-text-img .grid__title .title {
    font-size: 40px;
    margin-bottom: 2.75rem;
  }
}
.dual-col-title-text-img .grid__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.dual-col-title-text-img .grid__text {
  grid-column: 1/-1;
}
@media screen and (min-width: 1200px) {
  .dual-col-title-text-img .grid__text {
    grid-column: span 6;
    grid-row: 2/span 1;
  }
}
.dual-col-title-text-img .grid__deco {
  grid-column: 1/-1;
}
@media screen and (min-width: 1200px) {
  .dual-col-title-text-img .grid__deco {
    grid-column: span 6;
    grid-row: 2/span 1;
  }
}
@media screen and (min-width: 1200px) {
  .dual-col-title-text-img.img-right .grid__text {
    grid-column: 1/span 6;
  }
}
@media screen and (min-width: 1200px) {
  .dual-col-title-text-img.img-right .grid__deco {
    grid-column: 7/span 6;
  }
}
@media screen and (min-width: 1200px) {
  .dual-col-title-text-img.img-left .grid__text {
    grid-column: 7/span 6;
  }
}
@media screen and (min-width: 1200px) {
  .dual-col-title-text-img.img-left .grid__deco {
    grid-column: 1/span 6;
  }
}

.title-dual-col-wysiwyg {
  padding-block: 3rem;
}
@media screen and (min-width: 1200px) {
  .title-dual-col-wysiwyg {
    padding-block: 8rem;
  }
}
.title-dual-col-wysiwyg.blue-dark-bg {
  background-color: #180636;
}
.title-dual-col-wysiwyg.blue-gray-bg {
  background-color: #1C1534;
}
.title-dual-col-wysiwyg .grid__title {
  grid-column: 1/-1;
}
.title-dual-col-wysiwyg .grid__title .title {
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
  margin-block: 0 2.5rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .title-dual-col-wysiwyg .grid__title .title {
    font-size: 40px;
    margin-bottom: 2.75rem;
  }
}
.title-dual-col-wysiwyg .grid__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.title-dual-col-wysiwyg .grid__left {
  grid-column: 1/-1;
}
@media screen and (min-width: 1200px) {
  .title-dual-col-wysiwyg .grid__left {
    grid-column: 1/span 6;
    padding-right: 2rem;
  }
}
.title-dual-col-wysiwyg .grid__left.has-border {
  border: 0.063rem solid #FFFFFF;
  border-radius: 1.5rem;
  padding: 1rem;
  height: max-content;
}
@media screen and (min-width: 1200px) {
  .title-dual-col-wysiwyg .grid__left.has-border {
    padding: 2rem;
  }
}
.title-dual-col-wysiwyg .grid__right {
  grid-column: 1/-1;
}
@media screen and (min-width: 1200px) {
  .title-dual-col-wysiwyg .grid__right {
    grid-column: 7/span 6;
    padding-left: 2rem;
  }
}
.title-dual-col-wysiwyg .grid__right.has-border {
  border: 0.063rem solid #FFFFFF;
  border-radius: 1.5rem;
  padding: 1rem;
  height: max-content;
}
@media screen and (min-width: 1200px) {
  .title-dual-col-wysiwyg .grid__right.has-border {
    padding: 2rem;
  }
}

.numbers-legend {
  padding-block: 3rem;
}
@media screen and (min-width: 1200px) {
  .numbers-legend {
    padding-block: 8rem;
  }
}
.numbers-legend.blue-dark-bg {
  background-color: #180636;
}
.numbers-legend.blue-gray-bg {
  background-color: #1C1534;
}
.numbers-legend .grid__content {
  grid-column: 1/-1;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-around;
  padding: 2rem;
  border: 0.063rem solid #FFFFFF;
  border-radius: 2.25rem;
  background-color: rgb(28, 21, 52);
}
@media screen and (min-width: 1200px) {
  .numbers-legend .grid__content {
    padding-block: 4rem;
  }
}
.numbers-legend .grid__content .item {
  text-align: center;
}
@media screen and (min-width: 768px) {
  .numbers-legend .grid__content .item {
    width: 50%;
  }
}
@media screen and (min-width: 1200px) {
  .numbers-legend .grid__content .item {
    width: 30%;
  }
}
@media screen and (min-width: 1400px) {
  .numbers-legend .grid__content .item {
    padding-inline: 1.5rem;
  }
}
.numbers-legend .grid__content .item__number {
  font-size: 36px;
  font-weight: 600;
}
@media screen and (min-width: 1200px) {
  .numbers-legend .grid__content .item__number {
    font-size: 62px;
  }
}
.numbers-legend .grid__content .item__legend {
  font-size: 16px;
  font-weight: 400;
}
@media screen and (min-width: 1200px) {
  .numbers-legend .grid__content .item__legend {
    font-size: 22px;
  }
}

.double-colonne-image-texte-list-block {
  padding-block: 3rem;
}
@media screen and (min-width: 1200px) {
  .double-colonne-image-texte-list-block {
    padding-block: 8rem;
  }
}
.double-colonne-image-texte-list-block .bloc-img {
  display: none;
}
@media screen and (min-width: 1000px) {
  .double-colonne-image-texte-list-block .bloc-img {
    display: grid;
    place-items: center;
  }
}
.double-colonne-image-texte-list-block .grid__title {
  grid-column: 1/-1;
}
.double-colonne-image-texte-list-block .grid__title .title {
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
  margin-block: 0 2.5rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .double-colonne-image-texte-list-block .grid__title .title {
    font-size: 40px;
    margin-bottom: 2.75rem;
  }
}
.double-colonne-image-texte-list-block .grid__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}

.triple-colonne-style-pipeline-block {
  padding-block: 3rem;
}
@media screen and (min-width: 1200px) {
  .triple-colonne-style-pipeline-block {
    padding-block: 8rem;
  }
}
.triple-colonne-style-pipeline-block .grid__title {
  grid-column: 1/-1;
}
.triple-colonne-style-pipeline-block .grid__title .title {
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
  margin-block: 0 2.5rem;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .triple-colonne-style-pipeline-block .grid__title .title {
    font-size: 40px;
    margin-bottom: 2.75rem;
  }
}
.triple-colonne-style-pipeline-block .grid__title .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.triple-colonne-style-pipeline-block .pipeline-bloc .pipeline-bloc__head {
  position: relative;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  height: 100px;
}
@media screen and (min-width: 576px) {
  .triple-colonne-style-pipeline-block .pipeline-bloc .pipeline-bloc__head {
    height: 160px;
    border-top-right-radius: 88px;
    border-bottom-right-radius: 88px;
  }
}
.triple-colonne-style-pipeline-block .pipeline-bloc .pipeline-bloc__head p {
  margin-left: 12%;
}
.triple-colonne-style-pipeline-block .pipeline-bloc .pipeline-bloc__head::before {
  content: "";
  position: absolute;
  width: 75px;
  height: 101px;
  background: #fafafa;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  left: -2px;
}
@media screen and (min-width: 576px) {
  .triple-colonne-style-pipeline-block .pipeline-bloc .pipeline-bloc__head::before {
    width: 98px;
    height: 160px;
    border-top-right-radius: 96px;
    border-bottom-right-radius: 96px;
  }
}
.triple-colonne-style-pipeline-block .pipeline-bloc:nth-child(odd) .pipeline-bloc__head {
  background-color: #4846f4;
  color: white;
}
.triple-colonne-style-pipeline-block .pipeline-bloc:nth-child(even) .pipeline-bloc__head {
  background-color: #dfdff2;
}
.triple-colonne-style-pipeline-block .wysiwyg {
  font-size: 14px;
  padding-left: 45px;
  padding-right: 45px;
}
@media screen and (max-width: 576px) {
  .triple-colonne-style-pipeline-block .wysiwyg {
    padding-left: 0;
    padding-right: 0;
  }
}
.triple-colonne-style-pipeline-block .wysiwyg ul {
  list-style-type: none;
  padding-left: 20px;
}
.triple-colonne-style-pipeline-block .wysiwyg li {
  position: relative;
}
.triple-colonne-style-pipeline-block .wysiwyg li:before {
  content: "";
  width: 12px;
  height: 1px;
  position: absolute;
  background-color: #ff1435;
  top: 12px;
  left: -20px;
}
.triple-colonne-style-pipeline-block .wysiwyg li + li {
  margin-top: 16px;
}

.section-panorama-3d .title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  margin-block: 0 1rem;
}
@media screen and (min-width: 1200px) {
  .section-panorama-3d .title {
    font-size: 40px;
    margin-block: 0 2rem;
  }
}
.section-panorama-3d .title::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}

.swiper-panorama-3d .swiper-slide {
  transition: all;
  pointer-events: none;
}
.swiper-panorama-3d .swiper-slide:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: black;
  pointer-events: none;
  opacity: 0.5;
  transition: all;
}
.swiper-panorama-3d .swiper-slide .play-icon {
  display: none;
}
.swiper-panorama-3d .swiper-slide.swiper-slide-active {
  transform: rotateY(0) translateZ(0) scaleX(1.3572) scaleY(1.3585) !important;
  pointer-events: all;
}
.swiper-panorama-3d .swiper-slide.swiper-slide-active img {
  border-radius: 15px;
  filter: drop-shadow(black 3px 3px 4px);
}
.swiper-panorama-3d .swiper-slide.swiper-slide-active .play-icon {
  display: block;
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.swiper-panorama-3d .swiper-slide.swiper-slide-active .play-icon svg {
  width: 85px;
}
@media screen and (max-width: 500px) {
  .swiper-panorama-3d .swiper-slide.swiper-slide-active .play-icon svg {
    width: 65px;
  }
}
.swiper-panorama-3d .swiper-slide.swiper-slide-active:before {
  opacity: 0;
}
.swiper-panorama-3d .swiper-slide.swiper-slide-active .legende {
  display: -webkit-box;
  position: absolute;
  bottom: -35.5555555556px;
  width: 100%;
  text-align: center;
  font-size: 12px;
}

.swiper-panorama {
  overflow: visible;
}

.swiper-panorama-3d {
  padding: 80px 0;
  width: 100%;
  position: relative;
}
@media screen and (min-width: 768px) {
  .swiper-panorama-3d {
    padding: 150px 0;
  }
}

.swiper-panorama-3d .swiper {
  width: 100%;
  height: auto;
}

.swiper-panorama-3d .slide-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.popup-video {
  position: fixed;
  z-index: 99999;
  padding: 0 16px;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: none;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6705882353);
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  transition: all 0.5s ease;
}
.popup-video.active {
  pointer-events: all;
  opacity: 1;
  visibility: visible;
}
.popup-video iframe {
  max-width: 768px;
  width: 100%;
}
.popup-video .close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
}

.css-footermenu__link {
  @apply text-white hover:text-gw-light-blue duration-200 text-[16px] md:text-[14px] font-sansserif  tracking-wide;
}

.js-accordion:not(.is-opened) .js-accordion-content {
  height: 0;
}
.js-accordion.is-opened .js-accordion-content {
  height: max-content;
}
.js-accordion.is-opened .css-icon {
  @apply -rotate-180;
}
.js-accordion .js-accordion-content {
  overflow: hidden;
}
@screen md {
  .js-accordion .js-accordion-content {
    height: max-content !important;
  }
}

.css-menu__group .css-menu__link {
  @apply overflow-hidden py-1 font-sansserif tracking-wide;
}
.css-menu__group .css-menu__link::after {
  content: "";
  position: absolute;
  @apply h-px -bottom-0 -left-1 right-1 -translate-x-full bg-blue duration-200;
}
.css-menu__group:hover .css-menu__link::after {
  @apply translate-x-0;
}

.disable-hover {
  z-index: -10 !important;
  pointer-events: none !important;
}

/* === POST-ITEM === */
.post-item {
  border-radius: 0.75rem;
  overflow: hidden;
  /* === POST-ITEM-VIDEO === */
  /* === POST-ITEM-RESSOURCES === */
  /* === POST-ITEM-PRESS === */
  /* === POST-ITEM-TESTIMONIALS === */
}
.post-item .thumb {
  aspect-ratio: 4/3;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .post-item .thumb {
    aspect-ratio: 1;
  }
}
.post-item .thumb img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.post-item .thumb a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.post-item__content {
  padding: 1.5rem;
  background-color: #ECF2FE;
  height: 100%;
}
.post-item__content--header {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
}
.post-item__content--header .cat {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
}
.post-item__content--header .cat::after {
  content: "_";
  display: inline-block;
  color: #FF1435;
}
.post-item__content--header .sub-cat {
  padding: 0.25rem 0.5rem;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  color: #3E3CFF;
  border: 0.063rem solid #3E3CFF;
  border-radius: 0.5rem;
}
.post-item__content--header .tags-list {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}
.post-item__content--header .tag {
  width: max-content;
  font-size: 14px;
  padding: 0.25rem 0.75rem;
  border: 0.125rem solid #3E3CFF;
  border-radius: 0.75rem;
  color: #3E3CFF;
}
.post-item__content--inner .title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 1rem;
}
.post-item__content--inner .excerpt {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  margin-block: 1rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.post-item__content hr {
  width: 100%;
  height: 0.063rem;
  background-color: #180636;
  border-top: none;
  margin-block: 1rem;
}
.post-item.video .thumb {
  position: relative;
}
.post-item.video .thumb::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}
.post-item.video .thumb .player-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3.75rem;
  height: 3.75rem;
  z-index: 10;
}
.post-item.video .thumb .player-icon svg {
  fill: #FFFFFF;
}
.post-item.ressources {
  /* === POST-ITEM-RESSOURCES-FEATURED-POST === */
}
.post-item.ressources:not(:last-of-type) {
  margin-bottom: 1rem;
}
@media screen and (min-width: 1200px) {
  .post-item.ressources:not(:last-of-type) {
    margin-bottom: 0;
  }
}
.post-item.ressources:not(.featured-post) {
  border: 0.063rem solid #DADBE0;
}
.post-item.ressources:not(.featured-post) .thumb {
  display: none;
}
@media screen and (min-width: 1200px) {
  .post-item.ressources:not(.featured-post) .thumb {
    display: block;
    aspect-ratio: 16/10;
  }
}
.post-item.ressources .post-item__content {
  background-color: #FFFFFF;
  height: auto;
}
.post-item.ressources .post-item__content--header {
  margin-bottom: 1rem;
}
.post-item.ressources.featured-post {
  overflow: visible;
}
@media screen and (min-width: 1200px) {
  .post-item.ressources.featured-post {
    grid-column: 1/-1 !important;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
    align-items: center;
  }
}
.post-item.ressources.featured-post .thumb {
  border-top-right-radius: 0.75rem;
  border-top-left-radius: 0.75rem;
  overflow: hidden;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .post-item.ressources.featured-post .thumb {
    grid-column: 1/span 7;
    grid-row: 1/-1;
    border-radius: 0.75rem;
    aspect-ratio: 16/10;
    z-index: 10;
  }
  .post-item.ressources.featured-post .thumb img {
    border-radius: 0.75rem;
  }
}
.post-item.ressources.featured-post .thumb--target {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, 50%);
  aspect-ratio: 1;
  width: 75%;
  background-image: url("../img/decos/deco-post-item-featured-target.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
@media screen and (min-width: 1200px) {
  .post-item.ressources.featured-post .thumb--target {
    display: none;
  }
}
.post-item.ressources.featured-post .post-item__content {
  padding: 0;
  background: transparent;
}
@media screen and (min-width: 1200px) {
  .post-item.ressources.featured-post .post-item__content {
    grid-column: 7/span 6;
    grid-row: 1/-1;
    position: relative;
    z-index: 20;
  }
}
.post-item.ressources.featured-post .post-item__content__inner {
  padding: 1rem;
  background-color: #FFFFFF;
  border-radius: 0 0 0.75rem 0.75rem;
  position: relative;
  z-index: 20;
}
@media screen and (min-width: 1200px) {
  .post-item.ressources.featured-post .post-item__content__inner {
    border-radius: 0 0.75rem 0.75rem 0.75rem;
  }
}
.post-item.ressources.featured-post .post-item__content--target {
  display: none;
}
@media screen and (min-width: 1200px) {
  .post-item.ressources.featured-post .post-item__content--target {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    aspect-ratio: 1;
    width: 80%;
    background-image: url("../img/decos/deco-post-item-featured-target.svg");
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 10;
  }
}
.post-item.ressources.featured-post .post-item__content--header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.5rem;
  margin-bottom: 1rem;
}
.post-item.ressources.featured-post .post-item__content--header .featured-title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  color: #FF1435;
}
.post-item.press {
  /* === POST-ITEM-PRESS-FEATURED-POST === */
}
.post-item.press:not(:last-of-type) {
  margin-bottom: 1rem;
}
@media screen and (min-width: 1200px) {
  .post-item.press {
    margin-bottom: 0;
  }
}
.post-item.press:not(.featured-post) {
  border: 0.063rem solid #DADBE0;
}
.post-item.press:not(.featured-post) .thumb {
  display: none;
}
@media screen and (min-width: 1200px) {
  .post-item.press:not(.featured-post) .thumb {
    display: block;
    aspect-ratio: 16/10;
  }
}
@media screen and (min-width: 1200px) {
  .post-item.press:not(.featured-post) .thumb img {
    aspect-ratio: 16/10;
  }
}
.post-item.press .post-item__content {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
}
.post-item.press .post-item__content--header {
  margin-bottom: 1rem;
}
.post-item.press .post-item__content--inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.post-item.press .post-item__content--inner .action {
  margin-top: auto;
}
.post-item.press.featured-post {
  overflow: visible;
}
@media screen and (min-width: 1200px) {
  .post-item.press.featured-post {
    grid-column: 1/-1 !important;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
    align-items: center;
  }
}
.post-item.press.featured-post .thumb {
  border-top-right-radius: 0.75rem;
  border-top-left-radius: 0.75rem;
  overflow: hidden;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .post-item.press.featured-post .thumb {
    grid-column: 6/span 7;
    grid-row: 1/-1;
    border-radius: 0.75rem;
    aspect-ratio: 16/10;
    z-index: 10;
  }
  .post-item.press.featured-post .thumb img {
    border-radius: 0.75rem;
  }
}
.post-item.press.featured-post .thumb--target {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, 50%);
  aspect-ratio: 1;
  width: 75%;
  background-image: url("../img/decos/deco-post-item-featured-target.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
@media screen and (min-width: 1200px) {
  .post-item.press.featured-post .thumb--target {
    display: none;
  }
}
.post-item.press.featured-post .post-item__content {
  display: block;
  padding: 0;
  background: transparent;
  height: auto;
}
@media screen and (min-width: 1200px) {
  .post-item.press.featured-post .post-item__content {
    grid-column: 1/span 6;
    grid-row: 1/-1;
    position: relative;
    z-index: 20;
  }
}
.post-item.press.featured-post .post-item__content__inner {
  padding: 1rem;
  background-color: #FFFFFF;
  border-radius: 0 0 0.75rem 0.75rem;
  position: relative;
  z-index: 20;
}
@media screen and (min-width: 1200px) {
  .post-item.press.featured-post .post-item__content__inner {
    border-radius: 0.75rem 0 0.75rem 0.75rem;
  }
}
.post-item.press.featured-post .post-item__content--target {
  display: none;
}
@media screen and (min-width: 1200px) {
  .post-item.press.featured-post .post-item__content--target {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    aspect-ratio: 1;
    width: 80%;
    background-image: url("../img/decos/deco-post-item-featured-target.svg");
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 10;
  }
}
.post-item.press.featured-post .post-item__content--header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.5rem;
  margin-bottom: 1rem;
}
.post-item.press.featured-post .post-item__content--header .featured-title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  color: #FF1435;
}
.post-item.testimonials {
  border-radius: 0;
}
.post-item.testimonials .title {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
}
@media screen and (min-width: 1200px) {
  .post-item.testimonials .title {
    font-size: 20px;
  }
}
.post-item.testimonials hr {
  margin-block: 1rem;
  background-color: #180636;
}
.post-item.testimonials .editor {
  font-size: 14px;
  margin-block: 1rem;
}
@media screen and (min-width: 1200px) {
  .post-item.testimonials .editor {
    font-size: 18px;
  }
}
.post-item.testimonials .signature {
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  position: relative;
}
.post-item.testimonials .signature::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 1px;
  background-color: #180636;
}

.tool-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 2rem;
}
@media screen and (min-width: 768px) {
  .tool-pagination {
    justify-content: flex-start;
  }
}
.tool-pagination .prev,
.tool-pagination .next {
  height: 30px;
  width: 30px;
  cursor: pointer;
}
.tool-pagination .prev svg .circle,
.tool-pagination .next svg .circle {
  fill: none;
  stroke: #180636;
  transition: all 0.35s ease-in-out;
}
.tool-pagination .prev svg .arrow,
.tool-pagination .next svg .arrow {
  fill: #180636;
  transition: all 0.35s ease-in-out;
}
.tool-pagination .prev:hover svg .circle,
.tool-pagination .next:hover svg .circle {
  fill: none;
  stroke: #3E3CFF;
}
.tool-pagination .prev:hover svg .arrow,
.tool-pagination .next:hover svg .arrow {
  fill: #3E3CFF;
}
.tool-pagination .prev.disabled,
.tool-pagination .next.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.tool-pagination .pages {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
.tool-pagination .pages .page-number {
  font-size: 14px;
}
.tool-pagination .pages .current {
  font-weight: 700;
  color: #3E3CFF;
}
.tool-pagination .pages a {
  color: #C1C1C1;
}
.tool-pagination .pages a:hover {
  color: #3E3CFF;
}