.triple-colonne-style-pipeline-block {
  padding-block: calc($spacer-xl * 1.5);
  @media screen and (min-width: 1200px) {
    padding-block: calc($spacer-xxl * 2);
  }

  .grid {
    &__title {
      grid-column: 1 / -1;
      .title {
        font-size: 30px;
        font-weight: $fw-400;
        text-transform: uppercase;
        margin-block: 0 calc($spacer-xl * 1.25);
        position: relative;
        @media screen and (min-width: 1200px) {
          font-size: 40px;
          margin-bottom: calc($spacer-xl * 1.375);
        }
        &::after {
          content: "_";
          display: inline-block;
          color: $red;
        }
      }
    }
  }

  .pipeline-bloc {
    .pipeline-bloc__head {
      position: relative;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      height: 100px;

      @media screen and (min-width: 576px) {
        height: 160px;
        border-top-right-radius: 88px;
        border-bottom-right-radius: 88px;
      }

      p {
        margin-left: 12%;
      }

      &::before {
        content: "";
        position: absolute;
        width: 75px;
        height: 101px;
        background: #fafafa;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        left: -2px;

        @media screen and (min-width: 576px) {
          width: 98px;
          height: 160px;
          border-top-right-radius: 96px;
          border-bottom-right-radius: 96px;
        }
      }
    }

    &:nth-child(odd) {
      .pipeline-bloc__head {
        background-color: #4846f4;
        color: white;
      }
    }

    &:nth-child(even) {
      .pipeline-bloc__head {
        background-color: #dfdff2;
      }
    }
  }

  .wysiwyg {
    font-size: 14px;
    padding-left: 45px;
    padding-right: 45px;

    @media screen and (max-width: 576px) {
      padding-left: 0;
      padding-right: 0;
    }

    ul {
      list-style-type: none;
      padding-left: 20px;
    }

    li {
      position: relative;

      &:before {
        content: "";
        width: 12px;
        height: 1px;
        position: absolute;
        background-color: #ff1435;
        top: 12px;
        left: -20px;
      }
    }

    li + li {
      margin-top: 16px;
    }
  }
}
