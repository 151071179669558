.quote-block {
  padding-block: calc($spacer-xl * 1.5);
  background-color: $blue-dark-secondary;
  color: $white;
  position: relative;
  @media screen and (min-width: 1200px) {
    padding-block: $spacer-xxl;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    background-image: url("../img/decos/deco-gatewatcher-mire-claim-mobile.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media screen and (min-width: 576px) {
      background-image: url("../img/decos/deco-gatewatcher-mire-claim-desktop.svg");
    }
  }
  .content {
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 10;
    @media screen and (min-width: 576px) {
      aspect-ratio: 16/9;
    }
    @media screen and (min-width: 1200px) {
      aspect-ratio: 16/4;
    }
    .grid {
      height: 100%;
      align-items: center;
      position: relative;
      z-index: 20;
      &::before {
        content: "";
        position: absolute;
        top: $spacer-xl;
        left: $spacer-xl;
        width: 15%;
        aspect-ratio: 1;
        background-image: url("../img/decos/deco-gatewatcher-quote-left.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        @media screen and (min-width: 576px) {
          width: 10%;
        }
        @media screen and (min-width: 1200px) {
          top: 0;
          left: .75rem;
          width: 8.33333333%;
        }
        @media screen and (min-width: 1400px) {
          left: 0;
        }
        @media screen and (max-width: 400px) {
          top: -2rem;
        }
      }
      &::after {
        content: "";
        position: absolute;
        right: $spacer-xl;
        bottom: $spacer-xl;
        width: 15%;
        aspect-ratio: 1;
        background-image: url("../img/decos/deco-gatewatcher-quote-right.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        @media screen and (min-width: 576px) {
          width: 10%;
        }
        @media screen and (min-width: 1200px) {
          right: .75rem;
          bottom: 0;
          width: 8.33333333%;
        }
        @media screen and (min-width: 1400px) {
          right: 0;
        }
        @media screen and (max-width: 400px) {
          bottom: -2rem;
        }
      }
      .quote__content {
        grid-column: span 4;
        @media screen and (min-width: 576px) {
          grid-column: 2 / span 8;
        }
        @media screen and (min-width: 768px) {
          grid-column: 2 / span 10;
        }
        .text {
          font-family: $font-family-serif;
          font-size: 25px;
          font-style: italic;
          text-align: center;
          @media screen and (min-width: 1200px) {
            font-size: 50px;
            line-height: 60px;
          }
        }
        .author {
          text-align: center;
          margin-top: 50px;
          > * {
            font-family: $font-family-sans-serif;
            display: block;
            font-size: 16px;
            line-height: 22px;
            font-weight: 300;

            @media screen and (min-width: 1200px) {
              font-size: 24px;
              line-height: 29px;
            }

            &:first-child {
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
