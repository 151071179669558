.trainings-block {
    padding-block: calc($spacer-xl * 1.5);
    @media screen and (min-width: 1200px) {
        padding-block: calc($spacer-xxl * 2);
    }
    .content {
        .grid {
            row-gap: calc($spacer * 1.5);
            @media screen and (min-width: 1200px) {
                row-gap: $spacer;
            }
            &__title {
                grid-column: 1 / -1;
                display: flex;
                flex-direction: column;
                gap: $spacer;
                @media screen and (min-width: 576px) {
                    grid-column: 2 / span 8;
                }
                @media screen and (min-width: 768px) {
                    grid-column: 1 / -1;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                }
                @media screen and (min-width: 1200px) {
                    margin-bottom: $spacer-xl;
                }
                .title {
                    font-size: 20px;
                    font-weight: $fw-400;
                    text-transform: uppercase;
                    margin-block: 0;
                    position: relative;
                    @media screen and (min-width: 1200px) {
                        font-size: 40px;
                    }
                    &::after {
                        content: "_";
                        display: inline-block;
                        color: $red;
                    }
                }
            }
            .training-item {
                grid-column: 1 / -1;
                @media screen and (min-width: 576px) {
                    grid-column: 2 / span 8;
                }
                @media screen and (min-width: 768px) {
                    grid-column: span 6;
                }
                @media screen and (min-width: 1200px) {
                    grid-column: span 4;
                }
                display: flex;
                flex-direction: column;
                gap: $spacer;
                padding: $spacer;
                border-radius: $radius;
                background-color: $white;
                @media screen and (min-width: 1200px) {
                    padding: $spacer-xl;
                }
                &__header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    &__title {
                        font-size: 14px;
                        font-weight: $fw-700;
                        width: calc(100% - 70px);
                        @media screen and (min-width: 1200px) {
                            font-size: 18px;
                        }
                    }
                    &__icon {
                        width: 30px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        svg {
                            .circle {
                                fill: none;
                                stroke: $text;
                                transition: $transition;
                            }
                            .arrow {
                                fill: $text;
                                transition: $transition;
                            }
                        }
                    }
                }
                &__content {
                    width: calc(100% - 70px);
                    .editor {
                        font-size: 14px;
                    }
                }
                &:hover {
                    .training-item {
                        &__header {
                            &__icon {
                                svg {
                                    .circle {
                                        fill: none;
                                        stroke: $blue;
                                    }
                                    .arrow {
                                        fill: $blue;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}