.question-answers-block {
  padding-block: calc($spacer-xl * 1.5);
  background-color: $blue-light;
  position: relative;
  @media screen and (min-width: 1200px) {
    padding-block: calc($spacer-xxl * 2);
  }
  .bg-container {
    display: none;
    @media screen and (min-width: 768px) {
      display: block;
      position: absolute;
      top: calc(calc($spacer-xl * 3) + $questionAnswersTitle-height);
      left: 0;
      width: 100%;
      height: calc(
        100% - calc(calc($spacer-xl * 3) + $questionAnswersTitle-height)
      );
    }
    @media screen and (min-width: 1200px) {
      top: calc(calc($spacer-xxl * 4) + $questionAnswersTitle-height);
      height: calc(
        100% - calc(calc($spacer-xxl * 4) + $questionAnswersTitle-height)
      );
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .content {
    position: relative;
    z-index: 10;
    .grid {
      row-gap: $spacer-xl;
    }
  }
  .question-answers {
    &__title {
      grid-column: span 4;
      @media screen and (min-width: 576px) {
        grid-column: 2 / span 6;
      }
      @media screen and (min-width: 768px) {
        grid-column: span 5;
      }
      @media screen and (min-width: 1200px) {
        grid-column: span 4;
      }
      .title {
        font-size: 20px;
        font-weight: $fw-400;
        text-transform: uppercase;
        margin-block: 0 $spacer;
        position: relative;
        @media screen and (min-width: 1200px) {
          font-size: 40px;
          margin-block: 0 $spacer-xl;
          margin-right: calc($spacer * -1);
        }
        &::after {
          content: "_";
          display: inline-block;
          color: $red;
        }
      }
      .editor {
        margin-top: $spacer;
        @media screen and (min-width: 1200px) {
          margin-top: $spacer-xl;
        }
      }
    }
    &__content {
      grid-column: span 4;
      @media screen and (min-width: 576px) {
        grid-column: 2 / span 8;
      }
      @media screen and (min-width: 576px) {
        grid-column: 2 / span 8;
      }
      @media screen and (min-width: 768px) {
        grid-column: span 7;
      }
      @media screen and (min-width: 1200px) {
        grid-column: 6 / span 7;
      }
      .list-item {
        padding: $spacer;
        background-color: $white;
        border: 0.063rem solid $gray;
        border-radius: $radius;
        @media screen and (min-width: 1200px) {
          padding: calc($spacer * 1.25);
        }
        &:not(:last-child) {
          margin-bottom: $spacer;
        }
        @media screen and (min-width: 1200px) {
          display: flex;
          align-items: flex-start;
          gap: calc($spacer * 1.25);
        }
        &__icon {
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: $spacer;
        }
        &__content {
          .title {
            font-size: 14px;
            margin-block: 0 0.5rem;
            font-weight: 700;
            @media screen and (min-width: 1200px) {
              font-size: 18px;
            }
          }
          .editor {
            font-size: 14px;
            font-weight: $fw-400;
            color: $gray-dark-hover;
          }
        }
      }
    }
  }
}
