.obvious-version {
    background-color: $text;

    .main-breadcrumbs {
        span {
            span {
                color: $gray-hover;
                &:not(:last-child) {
                    opacity: .5;
                    &::after {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath d='M13.1714 12.0007L8.22168 7.05093L9.63589 5.63672L15.9999 12.0007L9.63589 18.3646L8.22168 16.9504L13.1714 12.0007Z' fill='rgba(193,193,193,1)'%3E%3C/path%3E%3C/svg%3E");
                    }
                }
            }
        }
    }

    .block-hero {
        &--2,
        &--3 {
            background-color: $text;
            .content {
                .grid {
                    &__content {
                        .uppertitle {
                            color: $white;
                        }
                        .title {
                            color: $white;
                        }
                        .editor {
                            color: $white;
                            a {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }


    .title-media-description,
    .title-description-media {
        background-color: $text;
        color: $white;
        .content {
            .grid {
                &__title {
                    .title {
                        color: $white;
                    }
                }
                hr {
                    color: $white;
                }
                &__content {
                    .editor {
                        color: $white;
                        a {
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    .gallery {
        background-color: $text;
        &__content {
            .swiper {
                &-pagination {
                    &-bullet {
                        background-color: $gray-hover;
                        &-active {
                            background-color: $blue;
                        }
                    }
                }
                &-prev,
                &-next {
                    svg {
                        .circle {
                            stroke: $white;
                        }
                        .arrow {
                            fill: $white;
                        }
                    }
                    &:hover {
                        svg {
                            .circle {
                                stroke: $blue;
                            }
                            .arrow {
                                fill: $blue;
                            }
                        }
                    }
                }
            }
        }
    }

    .media {
        background-color: $text;
    }

    .title-editor-full-width {
        background-color: rgba(28, 21, 52, 1);
        .title {
            color: $white;
        }
        .editor {
            color: $white;
            a {
                color: $white;
            }
        }
    }

    .dual-col-title-text-img {
        background-color: $text;
        .title {
            color: $white;
        }
        .editor {
            color: $white;
            a {
                color: $white;
            }
        }
    }

    .title-dual-col-wysiwyg {
        background-color: $text;
        .title {
            color: $white;
        }
        .editor {
            color: $white;
            a {
                color: $white;
            }
        }
    }

    .numbers-legend {
        .item {
            color: $white;
        }
    }
    .full-width-editor {
        .blue-dark-bg {
            color: $white;
        }
    }
}