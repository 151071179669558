.methodology-block {
    padding-block: calc($spacer-xl * 1.5);
    background-color: $white;
    @media screen and (min-width: 1200px) {
        padding-block: $spacer-xxl;
    }
    .content {
        width: 100%;
        max-width: calc(100% - $spacer-xxl);
        margin-inline: auto;
        padding: $spacer-xl $spacer;
        border: .063rem solid $blue-light-hover;
        border-radius: $radius;
        @media screen and (min-width: 576px) {
            max-width: 70%;
            padding: $spacer-xl;
        }
        @media screen and (min-width: 768px) {
            max-width: 60%;
            padding: $spacer-xxl;
        }
        @media screen and (min-width: 1200px) {
            max-width: calc(100% - calc($spacer-xxl * 2.5));
        }
        @media screen and (min-width: 1400px) {
            max-width: 1220px;
        }
        .grid {
            padding-inline: 0;
            row-gap: $spacer-xl;
            &__title {
                grid-column: 1 / -1;
                .title {
                    font-size: 30px;
                    font-weight: $fw-400;
                    text-transform: uppercase;
                    margin-block: 0 calc($spacer-xl * 1.25);
                    position: relative;
                    @media screen and (min-width: 1200px) {
                        font-size: 40px;
                        margin-bottom: calc($spacer-xl * 1.375);
                    }
                    &::after {
                        content: '_';
                        display: inline-block;
                        color: $red;
                    }
                }
            }
            &__content {
                grid-column: 1 / -1;
                display: flex;
                flex-direction: column;
                gap: $spacer;
                @media screen and (min-width: 1200px) {
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin-right: calc($spacer * -1);
                }
                .link-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: $spacer-xl;
                    padding-inline: $spacer-xl;
                    @media screen and (min-width: 1200px) {
                        padding-inline: 0;
                        flex: 1;
                        min-width: calc(20% - $spacer);
                        max-width: calc(20% - $spacer);
                    }
                    &__icon {
                        width: 75px;
                        height: 75px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @media screen and (min-width: 1200px) {
                            width: 100px;
                            height: 100px;
                        }
                        img {
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }
                    }
                    &__title {
                        font-size: 14px;
                        font-weight: $fw-700;
                        text-align: center;
                    }
                    &:not(:last-child) {
                        position: relative;
                        &::after {
                            content: '';
                            display: block;
                            margin-top: calc($spacer * -1);
                            width: 10px;
                            height: 10px;
                            background-image: url('../img/decos/deco-arrow-methodo-mobile.svg');
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-position: center;
                            @media screen and (min-width: 1200px) {
                                content: none;
                            }
                        }
                        .link-item {
                            &__icon {
                                position: relative;
                                &::after {
                                    @media screen and (min-width: 1200px) {
                                        content: '';
                                        position: absolute;
                                        top: 50%;
                                        transform: translateY(-50%);
                                        left: calc(100% + $spacer);
                                        width: 100%;
                                        height: 10%;
                                        background-image: url('../img/decos/deco-arrow-methodo.svg');
                                        background-repeat: no-repeat;
                                        background-size: contain;
                                        background-position: center;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}