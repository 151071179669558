.technical-informations {
    padding-block: calc($spacer-xl * 1.5);
    background-color: $white;
    @media screen and (min-width: 1200px) {
        padding-block: calc($spacer-xxl * 2);
    }
    .content {
        .grid {
            row-gap: calc($spacer * 1.5);
            @media screen and (min-width: 1200px) {
                row-gap: $spacer-xl;
            }
            &__title {
                grid-column: 1 / -1;
                .title {
                    font-size: 20px;
                    font-weight: $fw-400;
                    text-transform: uppercase;
                    margin-block: 0;
                    position: relative;
                    @media screen and (min-width: 1200px) {
                        font-size: 40px;
                    }
                    &::after {
                        content: '_';
                        display: inline-block;
                        color: $red;
                    }
                }
            }
            &__item {
                grid-column: 1 / -1;
                @media screen and (min-width: 1200px) {
                    grid-column: span 6;
                }
                .element-item {
                    border-radius: $radius;
                    border: .063rem solid $gray;
                    overflow: hidden;
                    &__thumb {
                        background-color: $blue-light;
                        img {
                            object-fit: contain;
                            width: 100%;
                            height: 100%;
                            aspect-ratio: 16/9;
                        }
                    }
                    &__content {
                        padding: $spacer-xl $spacer;
                        @media screen and (min-width: 768px) {
                            padding-inline: calc($spacer-xl * 1.25);
                        }
                    }
                    &__title {
                        font-size: 25px;
                        font-weight: $fw-700;
                        margin-block: 0 calc($spacer * 1.5);
                        @media screen and (min-width: 1200px) {
                            font-size: 32px;
                        }
                    }
                    &__subtitle {
                        font-size: 14px;
                        color: $red;
                        margin-block: calc($spacer * 1.5) 0;
                        @media screen and (min-width: 1200px) {
                            font-size: 20px;
                        }
                    }
                    .editor {
                        margin-block: $spacer-xl;
                        font-size: 14px;
                        position: relative;
                        ul {
                            padding-left: calc($spacer * 1.5);
                            li {
                                position: relative;
                                &::marker {
                                    content: none;
                                }
                                &::before {
                                    content: '';
                                    position: absolute;
                                    top: calc($spacer * .75);
                                    left: calc($spacer * -1.5);
                                    width: 14px;
                                    height: 1px;
                                    background-color: $red;
                                }
                            }
                        }
                        &::after {
                            content: '';
                            display: block;
                            margin-block: $spacer-xl;
                            width: 100%;
                            height: .063rem;
                            background-color: $text;
                        }
                    }
                    .accordion {
                        &-item {
                            margin-top: $spacer-xl;
                        }
                        &-header {
                            padding: 0;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                            &--title {
                                font-size: 14px;
                                font-weight: $fw-700;
                                @media screen and (min-width: 1200px) {
                                    font-size: 18px;
                                }
                                &::after {
                                    content: none;
                                }
                            }
                            &--icon {
                                width: $spacer;
                                height: $spacer;
                                @media screen and (min-width: 1200px) {
                                    width: $spacer-xl;
                                    height: $spacer-xl;
                                }
                                svg {
                                    object-fit: contain;
                                    width: 100%;
                                    height: 100%;
                                    polygon {
                                        transform-origin: 50% 50%;
                                        transition: $transition;
                                    }
                                }
                            }
                            &.active {
                                .accordion-header--icon {
                                    svg {
                                        polygon {
                                           transform: rotate(-180deg);
                                        }
                                    }
                                }
                            }
                        }
                        &-content {
                            .editor {
                                &::after {
                                    content: none;
                                }
                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                            .table-item {
                                display: grid;
                                @media screen and (min-width: 768px) {
                                    grid-template-columns: repeat(2, 1fr);
                                    align-items: center;
                                }
                                position: relative;
                                &:not(:last-child) {
                                    &::after {
                                        content: '';
                                        grid-column: 1 / -1;
                                        display: block;
                                        margin-block: $spacer;
                                        height: .063rem;
                                        width: 100%;
                                        background-color: $black;
                                    }
                                }
                                &__title {
                                    font-size: 14px;
                                    text-transform: uppercase;
                                    margin-bottom: calc($spacer * .75);
                                    @media screen and (min-width: 768px) {
                                        margin-bottom: 0;
                                    }
                                    @media screen and (min-width: 1200px) {
                                        font-size: 20px;
                                    }
                                }
                                &__line {
                                    display: grid;
                                    grid-template-columns: repeat(2, 1fr);
                                    gap: $spacer;
                                    font-size: 14px;
                                    @media screen and (min-width: 768px) {
                                        grid-column: 2 / span 1;
                                    }
                                    &.highlight {
                                        font-weight: $fw-700;
                                    }
                                }
                            }
                        }
                    }
                    .action {
                        margin-top: $spacer-xl;
                        @media screen and (min-width: 1200px) {
                            display: flex;
                            justify-content: center;
                        }
                        .btn {
                            max-width: 100%;
                            @media screen and (min-width: 1200px) {
                                max-width: max-content;
                            }
                        }
                    }
                }
            }
        }
    }
}