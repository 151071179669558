.title-description-media {
    padding-block: calc($spacer-xl * 1.5);
    @media screen and (min-width: 1200px) {
        padding-block: calc($spacer-xxl * 2);
    }
    .content {
        .grid {
            &__title {
                grid-column: 1 / -1;
                position: relative;
                @media screen and (min-width: 768px) {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
                .title {
                    font-size: 20px;
                    font-weight: 400;
                    text-transform: uppercase;
                    position: relative;
                    margin-block: 0;
                    @media screen and (min-width: 1200px) {
                        font-size: 40px;
                    }
                    &::after {
                        content: "_";
                        display: inline-block;
                        color: $red;
                    }
                }
                .date {
                    font-size: 14px;
                    font-weight: $fw-700;
                    text-transform: uppercase;
                    @media screen and (min-width: 1200px) {
                        font-size: 18px;
                    }
                }
            }
            hr {
                grid-column: 1 / -1;
                margin-block: 0 $spacer;
                color: $text;
            }
            &__media {
                grid-column: 1 / -1;
                @media screen and (min-width: 768px) {
                    grid-column: 1 / span 6;
                    grid-row: 3 / span 1;
                }
                .img-container {
                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            &__content {
                grid-column: 1 / -1;
                @media screen and (min-width: 768px) {
                    grid-column: 8 / span 5;
                    grid-row: 3 / span 1;
                }
                .editor {
                    h2 {
                        font-size: 18px;
                        font-weight: $fw-700;
                        color: $red;
                        @media screen and (min-width: 1200px) {
                            font-size: 28px;
                        }
                    }
                    h3 {
                        font-size: 18px;
                        font-weight: $fw-700;
                        @media screen and (min-width: 1200px) {
                            font-size: 20px;
                        }
                    }
                    h4,
                    h5,
                    h6 {
                        font-weight: $fw-700;
                    }
                }
            }
        }
    }
    &.img-right {
        .content {
            .grid {
                &__media {
                    @media screen and (min-width: 768px) {
                        grid-column: 7 / span 6;
                        grid-row: 3 / span 1;
                    }
                }
                &__content {
                    @media screen and (min-width: 768px) {
                        grid-column: 1 / span 5;
                        grid-row: 3 / span 1;
                    }
                }
            }
        }
    }
    &.img-full {
        .content {
            .grid {
                &__media {
                    @media screen and (min-width: 768px) {
                        grid-column: 1 / -1;
                        grid-row: 3 / span 1;
                    }
                }
                &__content {
                    grid-column: 1 / -1;
                    grid-row: 4 / span 1;
                }
            }
        }
    }
}