$spacer: 1rem;
$spacer-xl: 2rem;
$spacer-xxl: 4rem;

$radius: .75rem;

$font-family-sans-serif: 'Spline Sans', sans-serif;
$font-family-serif: 'ivypresto-display', serif;

$fw-100: 100;  /* -- Thin -- */
$fw-300: 300;  /* -- Light -- */
$fw-400: 400;  /* -- Regular -- */
$fw-500: 500;  /* -- Medium -- */
$fw-600: 600;  /* -- SemiBold -- */
$fw-700: 700;  /* -- Bold -- */

$fs-italic: italic;


$text: #180636;
$text-hover: #5A5974;

$red: #FF1435;
$red-hover: #C6292A;
$yellow: #F5DF16;

$blue: #3E3CFF;
$blue-light: #ECF2FE;
$blue-light-hover: #D6D5FF;
$blue-light-secondary: #E8E8FD;
$blue-dark: #3A3247;
$blue-dark-secondary : #22296C;
$blue-darker: #180636;

$blue-gray: #1C1534;

$gw-light-blue: #7FF9EB;

$white: #FFFFFF;
$light: #FAFAFA;
$gray: #DADBE0;
$gray-hover: #C1C1C1;
$gray-dark: #595B65;
$gray-dark-hover: #2B2B2B;
$black: #000000;


$transition: all .350s ease-in-out;


$levelBlockTitle-height: var(--levelBlockHeight);
$studyCaseTitle-height: var(--studyCaseHeight);
$numberListTitle-height: var(--numberListHeight);
$solutionHighlightTitle-height: var(--solutionHighlightHeight);
$missionsTitle-height: var(--missionsHeight);
$questionAnswersTitle-height: var(--questionAnswersHeight);
