.form-block {
    padding-block: 0 calc($spacer-xl * 1.5);
    margin-top: calc($spacer-xl * -.75);
    position: relative;
    @media screen and (min-width: 1200px) {
        padding-block: 0 calc($spacer-xxl * 2);
        margin-top: calc($spacer-xxl * -1);
    }
    .bg-container {
        position: absolute;
        top: calc($spacer-xl * .75);
        left: 0;
        width: 100%;
        height: calc(100% - calc($spacer-xl * .75));
        @media screen and (min-width: 1200px) {
            top: $spacer-xxl;
            height: calc(100% - $spacer-xxl);
        }
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    .content {
        position: relative;
        z-index: 10;
        margin-top: calc($spacer-xl * -.75);
        @media screen and (min-width: 1200px) {
            margin-top: calc($spacer-xxl * -1);
        }
        .grid {
            .form {
                grid-column: 1 / -1;
                background-color: $white;
                border-radius: $radius;
                border: .063rem solid $gray;
                padding: $spacer;
                @media screen and (min-width: 1200px) {
                    grid-column: 3 / span 8;
                    padding: calc($spacer-xl * 1.25);
                }
            }
            &--contact {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: $spacer;
                @media screen and (min-width: 768px) {
                    grid-template-columns: repeat(8, 1fr);
                    row-gap: $spacer-xl;
                }
                .full {
                    grid-column: 1 / -1;
                }
                .half {
                    grid-column: 1 / -1;
                    @media screen and (min-width: 768px) {
                        grid-column: span 4;
                    }
                }
                .notice {
                    span,
                    b,
                    strong, {
                        font-weight: $fw-400;
                        color: $red;
                    }
                }
                .form-group {
                    label {
                        display: inline-block;
                        font-size: 14px;
                        text-transform: uppercase;
                        margin-bottom: $spacer;
                        @media screen and (min-width: 1200px) {
                            font-size: 20px;
                        }
                        &.required {
                            position: relative;
                            &::after {
                                content: '*';
                                display: inline-block;
                                color: $red;
                            }
                        }
                    }
                    .wpcf7 {
                        &-not-valid {
                            border-color: $red;
                            &-tip {
                                font-size: 14px;
                                color: $red;
                            }
                        }
                    }
                }
                .action {
                    @media screen and (min-width: 768px) {
                        display: flex;
                        justify-content: center;
                    }
                    .btn {
                        max-width: 100%;
                        width: 100%;
                        @media screen and (min-width: 768px) {
                            max-width: max-content;
                        }
                    }
                }
                .form-control {
                    border: .063rem solid $gray;
                    border-radius: $radius;
                    transition: $transition;
                    color: $text;
                    padding: calc($spacer * .25) calc($spacer * .5);
                    width: 100%;
                    &:hover {
                        border-color: $text;
                    }
                    &:active {
                        border-color: $text;
                    }
                    &:focus {
                        border-color: $text;
                        outline: 0.063rem solid $black;
                        outline-offset: 0.125rem;
                    }
                }
                .nice-select {
                    width: 100%;
                    border-radius: $radius;
                    border: .063rem solid $gray;
                    &:hover {
                        border-color: $text;
                    }
                    &:active {
                        border-color: $text;
                    }
                    &:focus {
                        border-color: $text;
                        //outline: 0.063rem solid $black;
                        //outline-offset: 0.125rem;
                    }
                    &-dropdown {
                        margin-top: 0;
                        box-shadow: 0 0 0 1px $text;
                        border-radius: 0 0 $radius $radius;
                        width: 100%;
                        transform: scale(1) translateY(calc($spacer * -1));
                        transition: $transition;
                    }
                    &.open {
                        border-bottom-right-radius: 0;
                        border-bottom-left-radius: 0;
                        .nice-select-dropdown {
                            transform: translateY(0);
                        }
                    }

                    .option {
                        color: $text;
                        opacity: .4;
                        transition: $transition;
                        &:hover {
                            background-color: $blue-light;
                            opacity: 1;
                        }
                        &.selected {
                            font-weight: $fw-400;
                            &.focus {
                                background-color: $blue-light;
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}


.wpcf7 {
    form {
        .wpcf7 {
            &-response-output {
                border-width: .063rem!important;
                border-radius: $radius;
                padding: $spacer!important;
                margin: $spacer 0 0!important;
                text-align: center;
            }
        }
        &.invalid {
            .wpcf7 {
                &-response-output {
                    border-color: $red!important;
                }
            }
        }
        &.sent {
            .wpcf7 {
                &-response-output {
                    border-color: $gw-light-blue!important;
                    color: $text;
                }
            }
        }
    }
}