/* === POST-ITEM === */
.post-item {
  border-radius: $radius;
  overflow: hidden;
  .thumb {
    aspect-ratio: 4/3;
    position: relative;
    @media screen and (min-width: 1200px) {
      aspect-ratio: 1;
    }
    img {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
    }
  }
  &__content {
    padding: calc($spacer * 1.5);
    background-color: $blue-light;
    height: 100%;
    &--header {
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: flex-start;
      gap: calc($spacer * 0.5);
      .cat {
        font-size: 14px;
        font-weight: $fw-400;
        text-transform: uppercase;
        position: relative;
        &::after {
          content: "_";
          display: inline-block;
          color: $red;
        }
      }
      .sub-cat {
        padding: calc($spacer * 0.25) calc($spacer * 0.5);
        font-size: 14px;
        font-weight: $fw-400;
        text-transform: uppercase;
        color: $blue;
        border: 0.063rem solid $blue;
        border-radius: calc($radius / 1.5);
      }
      .tags-list {
        display: flex;
        align-items: center;
        gap: calc($spacer * 0.25);
        flex-wrap: wrap;
        margin-bottom: 0.5rem;
      }
      .tag {
        width: max-content;
        font-size: 14px;
        padding: calc($spacer * 0.25) calc($spacer * 0.75);
        border: 0.125rem solid $blue;
        border-radius: $radius;
        color: $blue;
      }
    }
    &--inner {
      .title {
        font-size: 18px;
        font-weight: $fw-700;
        margin-bottom: $spacer;
      }
      .excerpt {
        font-size: 14px;
        font-weight: $fw-400;
        line-height: 1.3;
        margin-block: $spacer;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .action {
      }
    }

    hr {
      width: 100%;
      height: 0.063rem;
      background-color: $text;
      border-top: none;
      margin-block: $spacer;
    }
  }

  /* === POST-ITEM-VIDEO === */
  &.video .thumb {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.25);
    }
    .player-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 3.75rem;
      height: 3.75rem;
      z-index: 10;
      svg {
        fill: $white;
      }
    }
  }

  /* === POST-ITEM-RESSOURCES === */
  &.ressources {
    &:not(:last-of-type) {
      margin-bottom: $spacer;
      @media screen and (min-width: 1200px) {
        margin-bottom: 0;
      }
    }
    &:not(.featured-post) {
      border: 0.063rem solid $gray;
      .thumb {
        display: none;
        @media screen and (min-width: 1200px) {
          display: block;
          aspect-ratio: 16/10;
        }
      }
    }
    .post-item {
      &__content {
        background-color: $white;
        height: auto;
        &--header {
          margin-bottom: $spacer;
        }
        &--inner {
        }
      }
    }

    /* === POST-ITEM-RESSOURCES-FEATURED-POST === */
    &.featured-post {
      overflow: visible;
      @media screen and (min-width: 1200px) {
        grid-column: 1 / -1 !important;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: $spacer;
        align-items: center;
      }
      .thumb {
        border-top-right-radius: $radius;
        border-top-left-radius: $radius;
        overflow: hidden;
        position: relative;
        @media screen and (min-width: 1200px) {
          grid-column: 1 / span 7;
          grid-row: 1 / -1;
          border-radius: $radius;
          aspect-ratio: 16/10;
          z-index: 10;
          img {
            border-radius: $radius;
          }
        }
        &--target {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(50%, 50%);
          aspect-ratio: 1;
          width: 75%;
          background-image: url("../img/decos/deco-post-item-featured-target.svg");
          background-size: contain;
          background-repeat: no-repeat;
          @media screen and (min-width: 1200px) {
            display: none;
          }
        }
      }
      .post-item {
        &__content {
          padding: 0;
          background: transparent;
          @media screen and (min-width: 1200px) {
            grid-column: 7 / span 6;
            grid-row: 1 / -1;
            position: relative;
            z-index: 20;
          }
          &__inner {
            padding: $spacer;
            background-color: $white;
            border-radius: 0 0 $radius $radius;
            position: relative;
            z-index: 20;
            @media screen and (min-width: 1200px) {
              border-radius: 0 $radius $radius $radius;
            }
          }
          &--target {
            display: none;
            @media screen and (min-width: 1200px) {
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              transform: translate(-50%, -50%);
              aspect-ratio: 1;
              width: 80%;
              background-image: url("../img/decos/deco-post-item-featured-target.svg");
              background-size: contain;
              background-repeat: no-repeat;
              z-index: 10;
            }
          }
          &--header {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: calc($spacer * 1.5);
            margin-bottom: $spacer;
            .featured-title {
              font-size: 20px;
              font-weight: $fw-400;
              text-transform: uppercase;
              color: $red;
            }
          }
        }
      }
    }
  }

  /* === POST-ITEM-PRESS === */
  &.press {
    &:not(:last-of-type) {
      margin-bottom: $spacer;
    }
    @media screen and (min-width: 1200px) {
      margin-bottom: 0;
    }
    &:not(.featured-post) {
      border: 0.063rem solid $gray;
      .thumb {
        display: none;
        @media screen and (min-width: 1200px) {
          display: block;
          aspect-ratio: 16/10;
        }
        img {
          @media screen and (min-width: 1200px) {
            aspect-ratio: 16/10;
          }
        }
      }
    }
    .post-item {
      &__content {
        background-color: $white;
        display: flex;
        flex-direction: column;
        &--header {
          margin-bottom: $spacer;
        }
        &--inner {
          display: flex;
          flex-direction: column;
          height: 100%;
          .action {
            margin-top: auto;
          }
        }
      }
    }

    /* === POST-ITEM-PRESS-FEATURED-POST === */
    &.featured-post {
      overflow: visible;
      @media screen and (min-width: 1200px) {
        grid-column: 1 / -1 !important;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: $spacer;
        align-items: center;
      }
      .thumb {
        border-top-right-radius: $radius;
        border-top-left-radius: $radius;
        overflow: hidden;
        position: relative;
        @media screen and (min-width: 1200px) {
          grid-column: 6 / span 7;
          grid-row: 1 / -1;
          border-radius: $radius;
          aspect-ratio: 16/10;
          z-index: 10;
          img {
            border-radius: $radius;
          }
        }
        &--target {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(50%, 50%);
          aspect-ratio: 1;
          width: 75%;
          background-image: url("../img/decos/deco-post-item-featured-target.svg");
          background-size: contain;
          background-repeat: no-repeat;
          @media screen and (min-width: 1200px) {
            display: none;
          }
        }
      }
      .post-item {
        &__content {
          display: block;
          padding: 0;
          background: transparent;
          height: auto;
          @media screen and (min-width: 1200px) {
            grid-column: 1 / span 6;
            grid-row: 1 / -1;
            position: relative;
            z-index: 20;
          }
          &__inner {
            padding: $spacer;
            background-color: $white;
            border-radius: 0 0 $radius $radius;
            position: relative;
            z-index: 20;
            @media screen and (min-width: 1200px) {
              border-radius: $radius 0 $radius $radius;
            }
          }
          &--target {
            display: none;
            @media screen and (min-width: 1200px) {
              display: block;
              position: absolute;
              top: 0;
              right: 0;
              transform: translate(50%, -50%);
              aspect-ratio: 1;
              width: 80%;
              background-image: url("../img/decos/deco-post-item-featured-target.svg");
              background-size: contain;
              background-repeat: no-repeat;
              z-index: 10;
            }
          }
          &--header {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: calc($spacer * 1.5);
            margin-bottom: $spacer;
            .featured-title {
              font-size: 20px;
              font-weight: $fw-400;
              text-transform: uppercase;
              color: $red;
            }
          }
        }
      }
    }
  }

  /* === POST-ITEM-TESTIMONIALS === */
  &.testimonials {
    border-radius: 0;
    .title {
      font-size: 14px;
      font-weight: $fw-400;
      text-transform: uppercase;
      @media screen and (min-width: 1200px) {
        font-size: 20px;
      }
    }
    hr {
      margin-block: $spacer;
      background-color: $text;
    }
    .editor {
      font-size: 14px;
      margin-block: $spacer;
      @media screen and (min-width: 1200px) {
        font-size: 18px;
      }
    }
    .signature {
      font-size: 12px;
      display: flex;
      align-items: center;
      gap: calc($spacer * 0.25);
      position: relative;
      &::before {
        content: "";
        display: inline-block;
        width: 10px;
        height: 1px;
        background-color: $text;
      }
    }
  }
}
