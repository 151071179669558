.commitment-block {
    padding-block: calc($spacer-xl * 1.5);
    @media screen and (min-width: 1200px) {
        padding-block: calc($spacer-xxl * 2)
    }
    .content {
        .grid {
            row-gap: calc($spacer-xl * 1.25);
            &__title {
                grid-column: 1 / -1;
                @media screen and (min-width: 576px) {
                    grid-column: 2 / span 8;
                }
                @media screen and (min-width: 768px) {
                    grid-column: 1 / -1;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
                .title {
                    font-size: 20px;
                    font-weight: 400;
                    text-transform: uppercase;
                    position: relative;
                    margin-block: 0;
                    @media screen and (min-width: 1200px) {
                        font-size: 40px;
                    }
                    &::after {
                        content: "_";
                        display: inline-block;
                        color: $red;
                    }
                }
                .action {
                    margin-top: $spacer;
                    @media screen and (min-width: 768px) {
                        margin-top: 0;
                    }
                    .btn {
                        max-width: 100%;
                        @media screen and (min-width: 768px) {
                            max-width: max-content;
                        }
                    }
                }
            }
            &__intro {
                grid-column: 1 / -1;
            }
            &__item {
                grid-column: 1 / -1;
                position: relative;
                @media screen and (min-width: 768px) {
                    grid-column: span 6;
                }
                @media screen and (min-width: 1200px) {
                    grid-column: span 3;
                }
                &:not(:last-child) {
                    &::after {
                        content: '';
                        display: block;
                        margin-top: calc($spacer-xl * 1.25);
                        height: .063rem;
                        background-color: $text;
                        @media screen and (min-width: 768px) {
                            content: none;
                        }
                    }
                }
                .item {
                    position: relative;
                    &--number {
                        font-size: 20px;
                        @media screen and (min-width: 1200px) {
                            font-size: 40px;
                        }
                    }
                    &--title {
                        font-size: 14px;
                        @media screen and (min-width: 1200px) {
                            font-size: 18px;
                        }
                    }
                    hr {
                        display: none;
                        @media screen and (min-width: 768px) {
                            display: block;
                            margin-block: calc($spacer * 1.5);
                            height: .063rem;
                            background-color: $text;
                            border: none;
                        }
                    }
                    .editor {
                        margin-top: calc($spacer * 1.5);
                        font-size: 14px;
                        @media screen and (min-width: 1200px) {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}