.work {
    padding-block: calc($spacer-xl * 1.5);
    @media screen and (min-width: 1200px) {
        padding-block: calc($spacer-xxl * 2);
    }
    .content {
        .grid {
            row-gap: $spacer-xl;
            @media screen and (min-width: 1200px) {
                row-gap: $spacer-xxl;
            }
            &__title {
                grid-column: 1 / -1;
                .title {
                    font-size: 20px;
                    font-weight: 400;
                    text-transform: uppercase;
                    position: relative;
                    margin-block: 0;
                    @media screen and (min-width: 1200px) {
                        font-size: 40px;
                    }
                    &::after {
                        content: "_";
                        display: inline-block;
                        color: $red;
                    }
                }
            }
            &__item {
                grid-column: 1 / -1;
                .item {
                    display: grid;
                    grid-template-columns: 1fr;
                    gap: $spacer;
                    @media screen and (min-width: 1200px) {
                        grid-template-columns: repeat(2, 1fr);
                    }
                    &--video {}
                    &--content {
                        .item__title {
                            font-size: 14px;
                            font-weight: $fw-700;
                            @media screen and (min-width: 1200px) {
                                font-size: 18px;
                            }
                        }
                        .editor {
                            margin-top: $spacer;
                        }
                    }
                    &.player-left {
                        .item {
                            &--video {
                                @media screen and (min-width: 1200px) {
                                    grid-column: 1 / span 1;
                                    grid-row: 1 / span 1;
                                }
                            }
                            &--content {
                                @media screen and (min-width: 1200px) {
                                    grid-column: 2 / span 1;
                                    grid-row: 1 / span 1;
                                }
                            }
                        }
                    }
                    &.player-right {
                        .item {
                            &--video {
                                @media screen and (min-width: 1200px) {
                                    grid-column: 2 / span 1;
                                    grid-row: 1 / span 1;
                                }
                            }
                            &--content {
                                @media screen and (min-width: 1200px) {
                                    grid-column: 1 / span 1;
                                    grid-row: 1 / span 1;
                                }
                            }
                        }

                    }
                }
            }
        }
    }
}