.dual-col-title-text-img {
    padding-block: calc($spacer-xl * 1.5);
    @media screen and (min-width: 1200px) {
        padding-block: calc($spacer-xxl * 2);
    }
    .grid {
        &__title {
            grid-column: 1 / -1;
            .title {
                font-size: 30px;
                font-weight: $fw-400;
                text-transform: uppercase;
                margin-block: 0 calc($spacer-xl * 1.25);
                position: relative;
                @media screen and (min-width: 1200px) {
                    font-size: 40px;
                    margin-bottom: calc($spacer-xl * 1.375);
                }
                &::after {
                    content: "_";
                    display: inline-block;
                    color: $red;
                }
            }
        }
        &__text {
            grid-column: 1 / -1;
            @media screen and (min-width: 1200px) {
                grid-column: span 6;
                grid-row: 2 / span 1;
            }
        }
        &__deco {
            grid-column: 1 / -1;
            @media screen and (min-width: 1200px) {
                grid-column: span 6;
                grid-row: 2 / span 1;
            }
        }
    }
    &.img-right {
        .grid {
            &__text {
                @media screen and (min-width: 1200px) {
                    grid-column: 1 / span 6;
                }
            }
            &__deco {
                @media screen and (min-width: 1200px) {
                    grid-column: 7 / span 6;
                }
            }
        }
    }
    &.img-left {
        .grid {
            &__text {
                @media screen and (min-width: 1200px) {
                    grid-column: 7 / span 6;
                }
            }
            &__deco {
                @media screen and (min-width: 1200px) {
                    grid-column: 1 / span 6;
                }
            }
        }
    }
}