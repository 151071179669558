.level-block {
    padding-block: calc($spacer-xl * 1.5);
    background-color: $blue-light;
    position: relative;
    @media screen and (min-width: 1200px) {
        padding-block: calc($spacer-xxl * 2);
    }
    .bg-container {
        display: none;
        @media screen and (min-width: 768px) {
            display: block;
            position: absolute;
            top: calc(calc($spacer-xl * 3) + $levelBlockTitle-height);
            left: 0;
            width: 100%;
            height: calc(100% - calc(calc($spacer-xl * 3) + $levelBlockTitle-height));
        }
        @media screen and (min-width: 1200px) {
            top: calc(calc($spacer-xxl * 4) + $levelBlockTitle-height);
            height: calc(100% - calc(calc($spacer-xxl * 4) + $levelBlockTitle-height));
        }
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    .content {
        position: relative;
        z-index: 10;
        .grid {
            row-gap: $spacer-xl;
        }
    }
    .level {
        &__title {
            grid-column: span 4;
            @media screen and (min-width: 576px) {
                grid-column: 2 / span 6;
            }
            @media screen and (min-width: 768px) {
                grid-column: span 5;
            }
            @media screen and (min-width: 1200px) {
                grid-column: span 4;
            }
            .title {
                font-size: 20px;
                font-weight: $fw-400;
                text-transform: uppercase;
                position: relative;
                margin-block: 0 $spacer;
                @media screen and (min-width: 1200px) {
                    font-size: 40px;
                    margin-block: 0 $spacer-xl;
                }
                &::after {
                    content: '_';
                    display: inline-block;
                    color: $red;
                }
            }
            .editor {
                font-size: 14px;
                margin-top: $spacer;
                @media screen and (min-width: 1200px) {
                    margin-top: $spacer-xl;
                }
            }
            .action {
                margin-top: $spacer;
                @media screen and (min-width: 1200px) {
                    margin-top: $spacer-xl;
                }
                .btn {
                    max-width: 100%;
                    @media screen and (min-width: 576px) {
                        max-width: max-content;
                    }
                }
            }
        }
        &__content {
            grid-column: span 4;
            @media screen and (min-width: 576px) {
                grid-column: 2 / span 8;
            }
            @media screen and (min-width: 576px) {
                grid-column: 2 / span 8;
            }
            @media screen and (min-width: 768px) {
                grid-column: span 7;
            }
            @media screen and (min-width: 1200px) {
                grid-column: 6 / span 7;
            }
            .list-item {
                background-color: $white;
                border: .063rem solid $gray;
                border-radius: $radius;
                &:not(:last-child) {
                    margin-bottom: $spacer;
                }
                &__content {
                    .item-title {
                        font-size: 14px;
                        font-weight: $fw-700;
                        margin-block: 0;
                        @media screen and (min-width: 1200px) {
                            font-size: 18px;
                        }
                    }
                    .editor {
                        padding: 0 $spacer $spacer;
                        @media screen and (min-width: 1200px) {
                            padding: 0 calc($spacer-xl * 1.25) calc($spacer-xl * 1.25);
                        }
                    }
                    .action {
                        margin-top: $spacer;
                    }
                }
            }
        }
    }
}