.block-hero {
  &--1 {
    background-color: $blue-light;
    .content {
      position: relative;
      .deco {
        &--mobile {
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          width: 100%;
          aspect-ratio: 16/9;
          @media screen and (min-width: 1200px) {
            display: none;
          }
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            aspect-ratio: 16/9;
          }
        }
        &--desktop {
          display: none;
          @media screen and (min-width: 1200px) {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
              object-position: right;
            }
          }
        }
      }
      .spacer {
        aspect-ratio: 16/7;
        grid-column: span 4;
        @media screen and (min-width: 576px) {
          grid-column: span 12;
        }
        @media screen and (min-width: 1200px) {
          display: none;
        }
      }
      .grid {
        position: relative;
        .breadcrumb {
          grid-column: 1 / span 8;
        }
        &__content {
          grid-column: span 4;
          padding-block: calc($spacer-xl * 2.5) calc($spacer * 7.125);
          @media screen and (min-width: 576px) {
            grid-column: span 7;
          }
          @media screen and (min-width: 768px) {
            grid-column: span 8;
            padding-block: $spacer-xxl calc($spacer-xxl + calc($spacer * 4.125));
          }
          @media screen and (min-width: 1400px) {
            grid-column: span 7;
            padding-block: calc($spacer-xxl * 2)
              calc($spacer-xxl + calc($spacer * 5.75));
          }
          .uppertitle {
            font-size: 14px;
            text-transform: uppercase;
            margin-bottom: calc($spacer * 1.5);
            @media screen and (min-width: 1200px) {
              font-size: 20px;
              margin-bottom: $spacer-xl;
            }
          }
          .title {
            font-size: 36px;
            line-height: 0.9;
            margin-block: 0 calc($spacer * 2);
            position: relative;
            @media screen and (min-width: 768px) {
              font-size: 72px;
            }
            @media screen and (min-width: 1200px) {
              font-size: 100px;
              margin-bottom: calc($spacer-xl * 1.5);
            }
            //&::after {
            //  content: "\00a0_";
            //  display: inline-block;
            //  color: $red;
            //  margin-left: -6px;
            //  @media screen and (min-width: 1200px) {
            //    margin-left: -1rem;
            //  }
            //}
            .font {
              &-sans-serif {
                font-weight: $fw-400;
                color: $red;
              }
              &-serif {
                font-weight: $fw-400;
                font-style: $fs-italic;
              }
            }
            > * {
              &:last-child {
                position: relative;
                &::after {
                  content: "_";
                  display: inline;
                  font-family: $font-family-sans-serif;
                  font-style: normal;
                  font-weight: 400;
                  color: $red;
                  //margin-left: -6px;
                  //@media screen and (min-width: 1200px) {
                  //  margin-left: -10px;
                  //}
                }
              }
            }
          }
          .action {
            margin-top: $spacer-xl;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: $spacer;
            @media screen and (min-width: 1200px) {
              margin-top: calc($spacer-xl * 1.5);
              flex-direction: row;
            }
            .btn {
              max-width: 100%;
            }
          }
          .wysiwyg-hero {
            font-size: 14px;
            @media screen and (min-width: 1200px) {
              font-size: 20px;
            }
            :is(h1, h2, h3, h4, h5, h6) {
              font-size: 36px;
              line-height: 0.9;
              margin-block: 0 calc($spacer * 2);
              position: relative;
              font-weight: $fw-400;
              color: $red;
              @media screen and (min-width: 768px) {
                font-size: 72px;
              }
              @media screen and (min-width: 1200px) {
                font-size: 100px;
                margin-bottom: calc($spacer-xl * 1.5);
              }
              &::after {
                content: "_";
                display: inline-block;
                color: $red;
                margin-left: calc($spacer * -1);
              }
              em {
                @extend .font-serif;
                font-weight: $fw-400;
                font-style: $fs-italic;
                color: $black;
              }
            }
          }
        }
      }
    }

    &__ressources {
      margin-block: calc($spacer * -4.125) calc($spacer-xl * 1.5);
      position: relative;
      z-index: 10;
      @media screen and (min-width: 1200px) {
        margin-block: calc($spacer * -5.75) calc($spacer-xxl * 2);
      }
      .content {
        .grid {
          gap: 0;
          .post-item {
            grid-column: 1/-1;
            border-radius: $radius;
            border: 0.063rem solid $gray;
            border-bottom: none;
            background-color: $white;
            overflow: hidden;
            @media screen and (min-width: 576px) {
              border-right: none;
              border-bottom: 0.063rem solid $gray;
            }
            @media screen and (min-width: 768px) {
              grid-column: 2 / span 10;
            }
            @media screen and (min-width: 1000px) {
              grid-column: 2 / span 10;
              display: flex;
              flex-direction: row;
              align-items: center;
              border: none;
            }
            .thumb {
              display: none;
              @media screen and (min-width: 1000px) {
                display: block;
                aspect-ratio: 16/9;
                height: 100%;
                max-width: 40%;
                img {
                  object-fit: cover;
                  width: 100%;
                  height: 100%;
                }
              }
            }
            .content {
              padding: $spacer;
              @media screen and (min-width: 1200px) {
                padding-inline: $spacer-xl;
              }
              &__header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .post-type {
                  font-size: 14px;
                  text-transform: uppercase;
                  font-weight: $fw-400;
                  position: relative;
                  &::after {
                    content: "_";
                    display: inline-block;
                    color: $red;
                  }
                }
                .cat {
                  padding: calc($spacer * 0.25) calc($spacer * 0.5);
                  font-size: 12px;
                  font-weight: $fw-400;
                  text-transform: uppercase;
                  color: $blue;
                  border: 0.063rem solid $blue;
                  border-radius: calc($radius / 1.5);
                }
              }
              .title {
                font-size: 18px;
                font-weight: $fw-700;
                line-height: 1;
                margin-block: calc($spacer * 1.5);
                @media screen and (min-width: 1200px) {
                  font-size: 24px;
                }
              }
              .excerpt {
                margin-block: calc($spacer * 1.5);
                display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                overflow: hidden;
              }
            }
            &:hover {
              .link {
                &-deco {
                  &::before {
                    margin-left: 2.5rem;
                    width: 0;
                  }
                  &::after {
                    transform: rotate(180deg);
                  }
                }
              }
            }
          }
          .cta-download {
            grid-column: span 4;
            background-color: $blue-light-secondary;
            border-bottom-right-radius: $radius;
            border-bottom-left-radius: $radius;
            border: 0.063rem solid $gray;
            border-left: none;
            border-top: none;
            transition: $transition;
            @media screen and (min-width: 576px) {
              grid-column: span 3;
              border-top: 0.063rem solid $gray;
              border-top-right-radius: $radius;
              border-bottom-left-radius: 0;
              display: flex;
              align-items: flex-end;
            }
            @media screen and (min-width: 768px) {
              grid-column: span 4;
            }
            @media screen and (min-width: 1200px) {
              grid-column: span 2;
              border: none;
              position: relative;
            }
            &:hover {
              background-color: $blue-light-hover;
            }
            &__link {
              display: flex;
              align-items: center;
              gap: calc($spacer * 1.5);
              padding: calc($spacer * 1.5) $spacer;
              @media screen and (min-width: 576px) {
                flex-direction: column-reverse;
                align-items: flex-start;
              }
              @media screen and (min-width: 1200px) {
                position: absolute;
                top: 0;
                left: 0;
                width: calc(100% - calc($spacer * 4));
                height: calc(100% - calc($spacer * 2));
                padding: $spacer calc($spacer * 2);
              }
            }
            &__icon {
              width: 30px;
            }
            &__title {
              font-size: 14px;
              font-weight: $fw-700;
              width: calc(100% - 30px - calc($spacer * 1.5));
              @media screen and (min-width: 576px) {
                font-size: 16px;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  &--2 {
    background-color: $blue-light;
    overflow: hidden;
    position: relative;
    .content {
      position: relative;
      z-index: 10;
      &.has-deco-mobile {
        margin-top: calc($spacer-xxl * 2);
        @media screen and (min-width: 768px) {
          margin-top: 0;
        }
      }
      .grid {
        position: relative;
        &__content {
          grid-column: span 4;
          padding-top: 3rem;
          @media screen and (min-width: 576px) {
            grid-column: 2 / span 8;
          }
          @media screen and (min-width: 768px) {
            grid-column: span 6;
            grid-row: 1 / -1;
            padding-block: $spacer-xxl;
          }
          @media screen and (min-width: 1200px) {
            grid-column: span 7;
            grid-row: 1 / -1;
            padding-block: calc($spacer-xxl * 2);
          }
          .uppertitle {
            font-size: 14px;
            text-transform: uppercase;
            margin-bottom: calc($spacer * 1.5);
            @media screen and (min-width: 1200px) {
              font-size: 20px;
              margin-bottom: $spacer-xl;
            }
          }
          .title {
            font-size: 36px;
            line-height: 0.9;
            margin-block: 0 calc($spacer * 2);
            position: relative;
            @media screen and (min-width: 1200px) {
              font-size: 64px;
              margin-bottom: calc($spacer-xl * 1.5);
              padding-right: $spacer-xxl;
            }
            //&::after {
            //  content: "\00a0_";
            //  display: inline-block;
            //  color: $red;
            //  margin-left: -6px;
            //  @media screen and (min-width: 1200px) {
            //    margin-left: -10px;
            //  }
            //}
            .font {
              &-serif {
                font-weight: $fw-400;
                font-style: $fs-italic;
              }
              &-sans-serif {
                font-weight: $fw-400;
                line-height: 1;
              }
            }
            > * {
              &:last-child {
                position: relative;
                &::after {
                  content: "_";
                  display: inline;
                  font-family: $font-family-sans-serif;
                  font-style: normal;
                  font-weight: 400;
                  color: $red;
                  //margin-left: -6px;
                  //@media screen and (min-width: 1200px) {
                  //  margin-left: -10px;
                  //}
                }
              }
            }
          }
          .wysiwyg-hero {
            font-size: 14px;
            @media screen and (min-width: 1200px) {
              font-size: 18px;
            }
            :is(h1, h2, h3, h4, h5, h6) {
              font-size: 36px;
              line-height: 0.9;
              margin-block: 0 calc($spacer * 2);
              position: relative;
              font-weight: $fw-400;
              color: $red;
              @media screen and (min-width: 1200px) {
                font-size: 64px;
                margin-bottom: calc($spacer-xl * 1.5);
                padding-right: $spacer-xxl;
              }
              &::after {
                content: "_";
                display: inline-block;
                font-weight: 400;
                color: $red;
              }
              em {
                @extend .font-serif;
                font-weight: $fw-400;
                font-style: $fs-italic;
                color: $black;
              }
            }
          }
          .action {
            margin-top: $spacer-xl;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: $spacer;
            @media screen and (min-width: 1200px) {
              margin-top: calc($spacer-xl * 1.5);
              flex-direction: row;
            }
            .btn {
              max-width: 100%;
            }
          }
        }
        &__links {
          grid-column: span 4;
          padding-bottom: $spacer-xl;
          @media screen and (min-width: 576px) {
            grid-column: 2 / span 8;
          }
          @media screen and (min-width: 768px) {
            grid-column: 8 / span 5;
            grid-row: 1 / -1;
            align-self: center;
            padding-bottom: 0;
            position: relative;
            z-index: 10;
          }
          @media screen and (min-width: 1200px) {
            grid-column: 9 / span 4;
          }
          &__inner {
            .link-item {
              background-color: $white;
              border-radius: $radius;
              overflow: hidden;
              &:not(:last-child) {
                margin-bottom: $spacer;
              }
              .link-item__content {
                position: relative;
                align-self: center;
                .link {
                  max-width: 100%;
                  padding: $spacer;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  gap: $spacer;
                  &-title {
                    width: calc(100% - calc($spacer * 2) - 1.875rem);
                    font-size: 14px;
                    font-weight: $fw-700;
                    @media screen and (min-width: 1200px) {
                      font-size: 18px;
                    }
                  }
                  &-icon {
                    width: 1.875rem;
                    height: 1.875rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg {
                      .circle {
                        fill: none;
                        stroke: $text;
                        transition: all 0.35s ease-in-out;
                      }
                      .arrow {
                        fill: $text;
                        transition: all 0.35s ease-in-out;
                      }
                    }
                  }
                  &:hover {
                    .link-icon {
                      svg {
                        .circle {
                          stroke: $blue;
                        }
                        .arrow {
                          fill: $blue;
                        }
                      }
                    }
                  }
                }
              }
              &.has-img {
                display: flex;
                flex-wrap: wrap;

                .thumb {
                  display: none;
                  @media screen and (min-width: 1200px) {
                    display: block;
                    flex: 1;
                    aspect-ratio: 16 / 10;
                    overflow: hidden;
                  }
                  picture, img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }
                .link-item__content {
                  flex: 1.25;
                  padding: $spacer;
                  @media screen and (min-width: 1200px) {
                    padding-right: $spacer-xl;
                  }
                  .link {
                    width: 100%;
                    padding: 0;
                  }
                }
              }

              &:not(.has-img) {
                background-color: $blue-light-secondary;
                .link-item__content {
                  .link {
                    padding: $spacer-xl;
                  }
                }
              }
            }
          }
        }
      }
    }
    .deco {
      &--mobile {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @media screen and (min-width: 768px) {
          display: none;
        }
        img {
          object-fit: cover;
          width: 100%;
        }
      }
      &__desktop {
        display: none;
        @media screen and (min-width: 768px) {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &--3 {
    background-color: $blue-light;
    overflow: hidden;
    position: relative;
    .content {
      position: relative;
      z-index: 10;
      &.has-deco-mobile {
        margin-top: 7rem;
        @media screen and (min-width: 768px) {
          margin-top: 0;
        }
      }
      .grid {
        position: relative;
        &__content {
          grid-column: span 4;
          padding-block: calc($spacer-xxl * 3) $spacer-xl;
          padding-top: 3rem;
          @media screen and (min-width: 576px) {
            grid-column: 2 / span 8;
          }
          @media screen and (min-width: 768px) {
            grid-column: span 6;
            grid-row: 1 / -1;
            padding-block: $spacer-xxl;
          }
          @media screen and (min-width: 1200px) {
            grid-column: 1 / span 7;
            grid-row: 1 / -1;
            padding-block: calc($spacer-xxl * 2);
          }
          .uppertitle {
            font-size: 14px;
            text-transform: uppercase;
            margin-bottom: calc($spacer * 1.5);
            @media screen and (min-width: 1200px) {
              font-size: 20px;
              margin-bottom: $spacer-xl;
            }
          }
          .title {
            font-size: 36px;
            line-height: 0.9;
            margin-block: 0 calc($spacer * 2);
            position: relative;
            @media screen and (min-width: 1200px) {
              font-size: 64px;
              margin-bottom: calc($spacer-xl * 1.5);
              padding-right: $spacer-xxl;
            }
            //&::after {
            //  content: "\00a0_";
            //  display: inline-block;
            //  color: $red;
            //  margin-left: -6px;
            //  @media screen and (min-width: 1200px) {
            //    margin-left: -10px;
            //  }
            //}
            .font {
              &-serif {
                font-weight: $fw-400;
                font-style: $fs-italic;
              }
              &-sans-serif {
                font-weight: $fw-400;
                line-height: 1;
              }
            }
            > * {
              &:last-child {
                position: relative;
                &::after {
                  content: "_";
                  display: inline;
                  font-family: $font-family-sans-serif;
                  font-style: normal;
                  font-weight: 400;
                  color: $red;
                  //margin-left: -6px;
                  //@media screen and (min-width: 1200px) {
                  //  margin-left: -10px;
                  //}
                }
              }
            }
          }
          .wysiwyg-hero {
            font-size: 14px;
            @media screen and (min-width: 1200px) {
              font-size: 18px;
            }
            :is(h1, h2, h3, h4, h5, h6) {
              font-size: 36px;
              line-height: 0.9;
              margin-block: 0 calc($spacer * 2);
              position: relative;
              @media screen and (min-width: 1200px) {
                font-size: 64px;
                margin-bottom: calc($spacer-xl * 1.5);
                padding-right: $spacer-xxl;
              }
              &::after {
                content: "_";
                display: inline-block;
                color: $red;
              }
              em {
                @extend .font-serif;
                font-weight: $fw-400;
                font-style: $fs-italic;
                color: $black;
              }
            }
          }
          .action {
            margin-top: $spacer-xl;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: $spacer;
            @media screen and (min-width: 1200px) {
              margin-top: calc($spacer-xl * 1.5);
              flex-direction: row;
            }
            .btn {
              max-width: 100%;
            }
          }
        }
      }
    }
    .deco {
      &--mobile {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @media screen and (min-width: 768px) {
          display: none;
        }
        img {
          object-fit: cover;
          width: 100%;
        }
      }
      &--desktop {
        display: none;
        @media screen and (min-width: 768px) {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &--4 {
    background-color: $blue-light;
    overflow: hidden;
    .content {
      position: relative;
      .deco {
        &--mobile {
          @media screen and (min-width: 768px) {
            display: none;
          }
          img {
            object-fit: cover;
            aspect-ratio: 16/8;
            width: 100%;
            height: 100%;
          }
        }
      }
      .grid {
        position: relative;
        &__content {
          grid-column: span 4;
          padding-block: calc($spacer-xl * 2.5) $spacer;
          @media screen and (min-width: 576px) {
            grid-column: 2 / span 8;
          }
          @media screen and (min-width: 768px) {
            grid-column: span 6;
            grid-row: 1 / -1;
            padding-block: $spacer-xxl;
          }
          @media screen and (min-width: 1200px) {
            grid-column: 1 / span 7;
            grid-row: 1 / -1;
            padding-block: calc($spacer-xxl * 2);
          }
          @media screen and (min-width: 1400px) {
            grid-column: 1 / span 8;
          }
          .uppertitle {
            font-size: 14px;
            text-transform: uppercase;
            margin-bottom: calc($spacer * 1.5);
            @media screen and (min-width: 1200px) {
              font-size: 20px;
              margin-bottom: $spacer-xl;
            }
          }
          .title {
            font-size: 36px;
            line-height: 0.9;
            margin-block: 0 calc($spacer * 2);
            position: relative;
            @media screen and (min-width: 1200px) {
              font-size: 64px;
              margin-bottom: calc($spacer-xl * 1.5);
              padding-right: $spacer-xxl;
            }
            //&::after {
            //  content: "\00a0_";
            //  display: inline-block;
            //  color: $red;
            //  margin-left: -6px;
            //  @media screen and (min-width: 1200px) {
            //    margin-left: -10px;
            //  }
            //}
            .font {
              &-serif {
                font-weight: $fw-400;
                font-style: $fs-italic;
              }
              &-sans-serif {
                font-weight: $fw-400;
                line-height: 1;
              }
            }
            > * {
              &:last-child {
                position: relative;
                &::after {
                  content: "_";
                  display: inline;
                  font-family: $font-family-sans-serif;
                  font-style: normal;
                  font-weight: 400;
                  color: $red;
                  //margin-left: -6px;
                  //@media screen and (min-width: 1200px) {
                  //  margin-left: -10px;
                  //}
                }
              }
            }
          }
          .action {
            margin-top: $spacer-xl;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: $spacer;
            @media screen and (min-width: 1200px) {
              margin-top: calc($spacer-xl * 1.5);
              flex-direction: row;
            }
            .btn {
              max-width: 100%;
            }
          }
        }
        &__deco__desktop {
          display: none;
          @media screen and (min-width: 768px) {
            display: block;
            position: relative;
            grid-column: 9 / span 4;
            grid-row: 1 / -1;
          }
          .deco-mire {
            @media screen and (min-width: 768px) {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              aspect-ratio: 1;
              background-image: url("../img/decos/deco-gatewatcher-mire-hero-4.png");
              background-size: cover;
              background-position: top;
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
  }

  &--5 {
    background-color: $blue-light;
    padding-block: calc($spacer-xl * 1.5);
    overflow: hidden;
    position: relative;
    @media screen and (min-width: 768px) {
      padding-block: calc($spacer-xxl * 2);
    }
    .content {
      .grid {
        &__content {
          grid-column: span 4;
          @media screen and (min-width: 576px) {
            grid-column: span 8;
          }
          .uppertitle {
            font-size: 14px;
            text-transform: uppercase;
            margin-bottom: calc($spacer * 1.5);
            @media screen and (min-width: 1200px) {
              font-size: 20px;
              margin-bottom: $spacer-xl;
            }
          }
          .title {
            font-size: 36px;
            font-weight: $fw-400;
            line-height: 0.9;
            margin-block: 0 $spacer-xl;
            position: relative;
            @media screen and (min-width: 1200px) {
              font-size: 64px;
              margin-block: 0 calc($spacer-xl * 1.5);
            }
            //&::after {
            //  content: "\00a0_";
            //  display: inline-block;
            //  color: $red;
            //  margin-left: -6px;
            //  @media screen and (min-width: 1200px) {
            //    margin-left: -10px;
            //  }
            //}
            .font {
              &-sans-serif {
                font-weight: 400;
                line-height: 1;
              }
              &-serif {
                font-weight: $fw-400;
                font-style: italic;
              }
            }
            > * {
              &:last-child {
                position: relative;
                &::after {
                  content: "_";
                  display: inline;
                  font-family: $font-family-sans-serif;
                  font-style: normal;
                  font-weight: 400;
                  color: $red;
                  //margin-left: -6px;
                  //@media screen and (min-width: 1200px) {
                  //  margin-left: -10px;
                  //}
                }
              }
            }
          }
          .wysiwyg-hero {
            font-size: 14px;
            @media screen and (min-width: 1200px) {
              font-size: 18px;
            }
            :is(h1, h2, h3, h4, h5, h6) {
              font-size: 36px;
              font-weight: $fw-400;
              line-height: 0.9;
              margin-block: 0 $spacer-xl;
              position: relative;
              @media screen and (min-width: 1200px) {
                font-size: 64px;
                margin-block: 0 calc($spacer-xl * 1.5);
              }
              &::after {
                content: "_";
                display: inline-block;
                color: $red;
              }
              em {
                @extend .font-serif;
                font-weight: $fw-400;
                font-style: $fs-italic;
                color: $black;
              }
            }
          }
          .editor {
          }
          .action {
            margin-top: $spacer-xl;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: $spacer;
            @media screen and (min-width: 1200px) {
              margin-top: calc($spacer-xl * 1.5);
              flex-direction: row;
            }
            .btn {
              max-width: 100%;
            }
          }
        }
      }
    }
    .deco {
      &-mobile {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @media screen and (min-width: 1200px) {
          display: none;
        }
        img {
          object-fit: cover;
          width: 100%;
        }
      }
      &-desktop {
        display: none;
        @media screen and (min-width: 1200px) {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
