.informations-block {
    padding-block: calc($spacer-xl * 1.5);
    background-color: $blue-light-secondary;
    @media screen and (min-width: 1200px) {
        padding-block: calc($spacer-xxl * 2);
    }
    .content {
        .grid {
            row-gap: $spacer-xl;
        }
    }
    .informations {
        &__title {
            grid-column: 1 / -1;
            @media screen and (min-width: 576px) {
                grid-column: 2 / span 8;
            }
            @media screen and (min-width: 768px) {
                grid-column: 2 / span 10;
            }
            @media screen and (min-width: 1200px) {
                grid-column: 1 / -1;
            }
            display: flex;
            flex-direction: column;
            gap: $spacer-xl;
            @media screen and (min-width: 1200px) {
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
            .title {
                font-size: 20px;
                font-weight: $fw-400;
                text-transform: uppercase;
                position: relative;
                margin-block: 0;
                @media screen and (min-width: 1200px) {
                    font-size: 40px;
                }
                &::after {
                    content: '_';
                    display: inline-block;
                    color: $red;
                }
            }
            .action {
                .btn {
                    max-width: 100%;
                    @media screen and (min-width: 576px) {
                        max-width: max-content;
                    }
                }
            }
        }
        &__content {
            grid-column: 1 / -1;
            @media screen and (min-width: 576px) {
                grid-column: 2 / span 8;
            }
            @media screen and (min-width: 768px) {
                grid-column: 2 / span 10;
            }
            @media screen and (min-width: 1200px) {
                grid-column: 1 / -1;
            }
            .item {
                &:not(:last-child) {
                    margin-bottom: $spacer-xl;
                    @media screen and (min-width: 1200px) {
                        margin-bottom: $spacer-xxl;
                    }
                }
                @media screen and (min-width: 1200px) {
                    display: grid;
                    grid-template-columns: repeat(12, 1fr);
                    gap: $spacer;
                    align-items: center;
                }
                &__image {
                    border-radius: $radius $radius 0 0;
                    aspect-ratio: 4/3;
                    overflow: hidden;
                    @media screen and (min-width: 1200px) {
                        border-radius: $radius;
                        aspect-ratio: 16/11;
                    }
                    .img-container {
                        height: 100%;
                        img {
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                &__content {
                    background-color: $white;
                    border-radius: 0 0 $radius $radius;
                    padding: $spacer;
                    @media screen and (min-width: 768px) {
                        padding: $spacer-xl;
                    }
                    @media screen and (min-width: 1200px) {
                        border-radius: $radius;
                    }
                    .quote {
                        font-size: 14px;
                        text-transform: uppercase;
                        color: $red;
                        margin-bottom: calc($spacer * 1.5);
                        @media screen and (min-width: 1200px) {
                            font-size: 20px;
                        }
                    }
                    .title {
                        font-size: 14px;
                        font-weight: $fw-700;
                        margin-block: 0 $spacer;
                        @media screen and (min-width: 1200px) {
                            font-size: 32px;
                        }
                    }
                    &__inner {
                        .icon {
                            width: 35px;
                            height: 35px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        .editor {}
                        &.has-icon {
                            display: flex;
                            flex-direction: column;
                            gap: $spacer;
                            @media screen and (min-width: 768px) {
                                flex-direction: row;
                            }
                            .editor {
                                @media screen and (min-width: 768px) {
                                    width: calc(100% - 35px - $spacer);
                                }
                            }
                        }
                    }
                }
                &.img-left {
                    @media screen and (min-width: 1200px) {
                        .item {
                            &__image {
                                grid-column: 1 / span 7;
                                grid-row: 1 / -1;
                                position: relative;
                                z-index: 10;
                            }
                            &__content {
                                grid-column: 7 / span 6;
                                grid-row: 1 / -1;
                                position: relative;
                                z-index: 20;
                            }
                        }
                    }
                }
                &.img-right {
                    @media screen and (min-width: 1200px) {
                        .item {
                            &__image {
                                grid-column: 6 / span 7;
                                grid-row: 1 / -1;
                                position: relative;
                                z-index: 10;
                            }
                            &__content {
                                grid-column: 1 / span 6;
                                grid-row: 1 / -1;
                                position: relative;
                                z-index: 20;
                            }
                        }
                    }
                }
            }
        }
    }
}